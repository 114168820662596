/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
.fa,
.fas,
.far,
.fal,
.fad,
.fab {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  line-height: 1;
}

.fa-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.fa-xs {
  font-size: 0.75em;
}

.fa-sm {
  font-size: 0.875em;
}

.fa-1x {
  font-size: 1em;
}

.fa-2x {
  font-size: 2em;
}

.fa-3x {
  font-size: 3em;
}

.fa-4x {
  font-size: 4em;
}

.fa-5x {
  font-size: 5em;
}

.fa-6x {
  font-size: 6em;
}

.fa-7x {
  font-size: 7em;
}

.fa-8x {
  font-size: 8em;
}

.fa-9x {
  font-size: 9em;
}

.fa-10x {
  font-size: 10em;
}

.fa-fw {
  text-align: center;
  width: 1.25em;
}

.fa-ul {
  list-style-type: none;
  margin-left: 2.5em;
  padding-left: 0;
}
.fa-ul > li {
  position: relative;
}

.fa-li {
  left: -2em;
  position: absolute;
  text-align: center;
  width: 2em;
  line-height: inherit;
}

.fa-border {
  border: solid 0.08em #eee;
  border-radius: 0.1em;
  padding: 0.2em 0.25em 0.15em;
}

.fa-pull-left {
  float: left;
}

.fa-pull-right {
  float: right;
}

.fa.fa-pull-left,
.fas.fa-pull-left,
.far.fa-pull-left,
.fal.fa-pull-left,
.fab.fa-pull-left {
  margin-right: 0.3em;
}
.fa.fa-pull-right,
.fas.fa-pull-right,
.far.fa-pull-right,
.fal.fa-pull-right,
.fab.fa-pull-right {
  margin-left: 0.3em;
}

.fa-spin {
  animation: fa-spin 2s infinite linear;
}

.fa-pulse {
  animation: fa-spin 1s infinite steps(8);
}

@keyframes fa-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fa-rotate-90 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=1)";
  transform: rotate(90deg);
}

.fa-rotate-180 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2)";
  transform: rotate(180deg);
}

.fa-rotate-270 {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=3)";
  transform: rotate(270deg);
}

.fa-flip-horizontal {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1)";
  transform: scale(-1, 1);
}

.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(1, -1);
}

.fa-flip-both, .fa-flip-horizontal.fa-flip-vertical {
  -ms-filter: "progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1)";
  transform: scale(-1, -1);
}

:root .fa-rotate-90,
:root .fa-rotate-180,
:root .fa-rotate-270,
:root .fa-flip-horizontal,
:root .fa-flip-vertical,
:root .fa-flip-both {
  filter: none;
}

.fa-stack {
  display: inline-block;
  height: 2em;
  line-height: 2em;
  position: relative;
  vertical-align: middle;
  width: 2.5em;
}

.fa-stack-1x,
.fa-stack-2x {
  left: 0;
  position: absolute;
  text-align: center;
  width: 100%;
}

.fa-stack-1x {
  line-height: inherit;
}

.fa-stack-2x {
  font-size: 2em;
}

.fa-inverse {
  color: #fff;
}

/* Font Awesome uses the Unicode Private Use Area (PUA) to ensure screen
readers do not read off random characters that represent icons */
.fa-500px:before {
  content: "\f26e";
}

.fa-abacus:before {
  content: "\f640";
}

.fa-accessible-icon:before {
  content: "\f368";
}

.fa-accusoft:before {
  content: "\f369";
}

.fa-acorn:before {
  content: "\f6ae";
}

.fa-acquisitions-incorporated:before {
  content: "\f6af";
}

.fa-ad:before {
  content: "\f641";
}

.fa-address-book:before {
  content: "\f2b9";
}

.fa-address-card:before {
  content: "\f2bb";
}

.fa-adjust:before {
  content: "\f042";
}

.fa-adn:before {
  content: "\f170";
}

.fa-adobe:before {
  content: "\f778";
}

.fa-adversal:before {
  content: "\f36a";
}

.fa-affiliatetheme:before {
  content: "\f36b";
}

.fa-air-freshener:before {
  content: "\f5d0";
}

.fa-airbnb:before {
  content: "\f834";
}

.fa-alarm-clock:before {
  content: "\f34e";
}

.fa-alarm-exclamation:before {
  content: "\f843";
}

.fa-alarm-plus:before {
  content: "\f844";
}

.fa-alarm-snooze:before {
  content: "\f845";
}

.fa-algolia:before {
  content: "\f36c";
}

.fa-alicorn:before {
  content: "\f6b0";
}

.fa-align-center:before {
  content: "\f037";
}

.fa-align-justify:before {
  content: "\f039";
}

.fa-align-left:before {
  content: "\f036";
}

.fa-align-right:before {
  content: "\f038";
}

.fa-align-slash:before {
  content: "\f846";
}

.fa-alipay:before {
  content: "\f642";
}

.fa-allergies:before {
  content: "\f461";
}

.fa-amazon:before {
  content: "\f270";
}

.fa-amazon-pay:before {
  content: "\f42c";
}

.fa-ambulance:before {
  content: "\f0f9";
}

.fa-american-sign-language-interpreting:before {
  content: "\f2a3";
}

.fa-amilia:before {
  content: "\f36d";
}

.fa-analytics:before {
  content: "\f643";
}

.fa-anchor:before {
  content: "\f13d";
}

.fa-android:before {
  content: "\f17b";
}

.fa-angel:before {
  content: "\f779";
}

.fa-angellist:before {
  content: "\f209";
}

.fa-angle-double-down:before {
  content: "\f103";
}

.fa-angle-double-left:before {
  content: "\f100";
}

.fa-angle-double-right:before {
  content: "\f101";
}

.fa-angle-double-up:before {
  content: "\f102";
}

.fa-angle-down:before {
  content: "\f107";
}

.fa-angle-left:before {
  content: "\f104";
}

.fa-angle-right:before {
  content: "\f105";
}

.fa-angle-up:before {
  content: "\f106";
}

.fa-angry:before {
  content: "\f556";
}

.fa-angrycreative:before {
  content: "\f36e";
}

.fa-angular:before {
  content: "\f420";
}

.fa-ankh:before {
  content: "\f644";
}

.fa-app-store:before {
  content: "\f36f";
}

.fa-app-store-ios:before {
  content: "\f370";
}

.fa-apper:before {
  content: "\f371";
}

.fa-apple:before {
  content: "\f179";
}

.fa-apple-alt:before {
  content: "\f5d1";
}

.fa-apple-crate:before {
  content: "\f6b1";
}

.fa-apple-pay:before {
  content: "\f415";
}

.fa-archive:before {
  content: "\f187";
}

.fa-archway:before {
  content: "\f557";
}

.fa-arrow-alt-circle-down:before {
  content: "\f358";
}

.fa-arrow-alt-circle-left:before {
  content: "\f359";
}

.fa-arrow-alt-circle-right:before {
  content: "\f35a";
}

.fa-arrow-alt-circle-up:before {
  content: "\f35b";
}

.fa-arrow-alt-down:before {
  content: "\f354";
}

.fa-arrow-alt-from-bottom:before {
  content: "\f346";
}

.fa-arrow-alt-from-left:before {
  content: "\f347";
}

.fa-arrow-alt-from-right:before {
  content: "\f348";
}

.fa-arrow-alt-from-top:before {
  content: "\f349";
}

.fa-arrow-alt-left:before {
  content: "\f355";
}

.fa-arrow-alt-right:before {
  content: "\f356";
}

.fa-arrow-alt-square-down:before {
  content: "\f350";
}

.fa-arrow-alt-square-left:before {
  content: "\f351";
}

.fa-arrow-alt-square-right:before {
  content: "\f352";
}

.fa-arrow-alt-square-up:before {
  content: "\f353";
}

.fa-arrow-alt-to-bottom:before {
  content: "\f34a";
}

.fa-arrow-alt-to-left:before {
  content: "\f34b";
}

.fa-arrow-alt-to-right:before {
  content: "\f34c";
}

.fa-arrow-alt-to-top:before {
  content: "\f34d";
}

.fa-arrow-alt-up:before {
  content: "\f357";
}

.fa-arrow-circle-down:before {
  content: "\f0ab";
}

.fa-arrow-circle-left:before {
  content: "\f0a8";
}

.fa-arrow-circle-right:before {
  content: "\f0a9";
}

.fa-arrow-circle-up:before {
  content: "\f0aa";
}

.fa-arrow-down:before {
  content: "\f063";
}

.fa-arrow-from-bottom:before {
  content: "\f342";
}

.fa-arrow-from-left:before {
  content: "\f343";
}

.fa-arrow-from-right:before {
  content: "\f344";
}

.fa-arrow-from-top:before {
  content: "\f345";
}

.fa-arrow-left:before {
  content: "\f060";
}

.fa-arrow-right:before {
  content: "\f061";
}

.fa-arrow-square-down:before {
  content: "\f339";
}

.fa-arrow-square-left:before {
  content: "\f33a";
}

.fa-arrow-square-right:before {
  content: "\f33b";
}

.fa-arrow-square-up:before {
  content: "\f33c";
}

.fa-arrow-to-bottom:before {
  content: "\f33d";
}

.fa-arrow-to-left:before {
  content: "\f33e";
}

.fa-arrow-to-right:before {
  content: "\f340";
}

.fa-arrow-to-top:before {
  content: "\f341";
}

.fa-arrow-up:before {
  content: "\f062";
}

.fa-arrows:before {
  content: "\f047";
}

.fa-arrows-alt:before {
  content: "\f0b2";
}

.fa-arrows-alt-h:before {
  content: "\f337";
}

.fa-arrows-alt-v:before {
  content: "\f338";
}

.fa-arrows-h:before {
  content: "\f07e";
}

.fa-arrows-v:before {
  content: "\f07d";
}

.fa-artstation:before {
  content: "\f77a";
}

.fa-assistive-listening-systems:before {
  content: "\f2a2";
}

.fa-asterisk:before {
  content: "\f069";
}

.fa-asymmetrik:before {
  content: "\f372";
}

.fa-at:before {
  content: "\f1fa";
}

.fa-atlas:before {
  content: "\f558";
}

.fa-atlassian:before {
  content: "\f77b";
}

.fa-atom:before {
  content: "\f5d2";
}

.fa-atom-alt:before {
  content: "\f5d3";
}

.fa-audible:before {
  content: "\f373";
}

.fa-audio-description:before {
  content: "\f29e";
}

.fa-autoprefixer:before {
  content: "\f41c";
}

.fa-avianex:before {
  content: "\f374";
}

.fa-aviato:before {
  content: "\f421";
}

.fa-award:before {
  content: "\f559";
}

.fa-aws:before {
  content: "\f375";
}

.fa-axe:before {
  content: "\f6b2";
}

.fa-axe-battle:before {
  content: "\f6b3";
}

.fa-baby:before {
  content: "\f77c";
}

.fa-baby-carriage:before {
  content: "\f77d";
}

.fa-backpack:before {
  content: "\f5d4";
}

.fa-backspace:before {
  content: "\f55a";
}

.fa-backward:before {
  content: "\f04a";
}

.fa-bacon:before {
  content: "\f7e5";
}

.fa-badge:before {
  content: "\f335";
}

.fa-badge-check:before {
  content: "\f336";
}

.fa-badge-dollar:before {
  content: "\f645";
}

.fa-badge-percent:before {
  content: "\f646";
}

.fa-badger-honey:before {
  content: "\f6b4";
}

.fa-bags-shopping:before {
  content: "\f847";
}

.fa-balance-scale:before {
  content: "\f24e";
}

.fa-balance-scale-left:before {
  content: "\f515";
}

.fa-balance-scale-right:before {
  content: "\f516";
}

.fa-ball-pile:before {
  content: "\f77e";
}

.fa-ballot:before {
  content: "\f732";
}

.fa-ballot-check:before {
  content: "\f733";
}

.fa-ban:before {
  content: "\f05e";
}

.fa-band-aid:before {
  content: "\f462";
}

.fa-bandcamp:before {
  content: "\f2d5";
}

.fa-barcode:before {
  content: "\f02a";
}

.fa-barcode-alt:before {
  content: "\f463";
}

.fa-barcode-read:before {
  content: "\f464";
}

.fa-barcode-scan:before {
  content: "\f465";
}

.fa-bars:before {
  content: "\f0c9";
}

.fa-baseball:before {
  content: "\f432";
}

.fa-baseball-ball:before {
  content: "\f433";
}

.fa-basketball-ball:before {
  content: "\f434";
}

.fa-basketball-hoop:before {
  content: "\f435";
}

.fa-bat:before {
  content: "\f6b5";
}

.fa-bath:before {
  content: "\f2cd";
}

.fa-battery-bolt:before {
  content: "\f376";
}

.fa-battery-empty:before {
  content: "\f244";
}

.fa-battery-full:before {
  content: "\f240";
}

.fa-battery-half:before {
  content: "\f242";
}

.fa-battery-quarter:before {
  content: "\f243";
}

.fa-battery-slash:before {
  content: "\f377";
}

.fa-battery-three-quarters:before {
  content: "\f241";
}

.fa-battle-net:before {
  content: "\f835";
}

.fa-bed:before {
  content: "\f236";
}

.fa-beer:before {
  content: "\f0fc";
}

.fa-behance:before {
  content: "\f1b4";
}

.fa-behance-square:before {
  content: "\f1b5";
}

.fa-bell:before {
  content: "\f0f3";
}

.fa-bell-exclamation:before {
  content: "\f848";
}

.fa-bell-plus:before {
  content: "\f849";
}

.fa-bell-school:before {
  content: "\f5d5";
}

.fa-bell-school-slash:before {
  content: "\f5d6";
}

.fa-bell-slash:before {
  content: "\f1f6";
}

.fa-bells:before {
  content: "\f77f";
}

.fa-bezier-curve:before {
  content: "\f55b";
}

.fa-bible:before {
  content: "\f647";
}

.fa-bicycle:before {
  content: "\f206";
}

.fa-biking:before {
  content: "\f84a";
}

.fa-biking-mountain:before {
  content: "\f84b";
}

.fa-bimobject:before {
  content: "\f378";
}

.fa-binoculars:before {
  content: "\f1e5";
}

.fa-biohazard:before {
  content: "\f780";
}

.fa-birthday-cake:before {
  content: "\f1fd";
}

.fa-bitbucket:before {
  content: "\f171";
}

.fa-bitcoin:before {
  content: "\f379";
}

.fa-bity:before {
  content: "\f37a";
}

.fa-black-tie:before {
  content: "\f27e";
}

.fa-blackberry:before {
  content: "\f37b";
}

.fa-blanket:before {
  content: "\f498";
}

.fa-blender:before {
  content: "\f517";
}

.fa-blender-phone:before {
  content: "\f6b6";
}

.fa-blind:before {
  content: "\f29d";
}

.fa-blog:before {
  content: "\f781";
}

.fa-blogger:before {
  content: "\f37c";
}

.fa-blogger-b:before {
  content: "\f37d";
}

.fa-bluetooth:before {
  content: "\f293";
}

.fa-bluetooth-b:before {
  content: "\f294";
}

.fa-bold:before {
  content: "\f032";
}

.fa-bolt:before {
  content: "\f0e7";
}

.fa-bomb:before {
  content: "\f1e2";
}

.fa-bone:before {
  content: "\f5d7";
}

.fa-bone-break:before {
  content: "\f5d8";
}

.fa-bong:before {
  content: "\f55c";
}

.fa-book:before {
  content: "\f02d";
}

.fa-book-alt:before {
  content: "\f5d9";
}

.fa-book-dead:before {
  content: "\f6b7";
}

.fa-book-heart:before {
  content: "\f499";
}

.fa-book-medical:before {
  content: "\f7e6";
}

.fa-book-open:before {
  content: "\f518";
}

.fa-book-reader:before {
  content: "\f5da";
}

.fa-book-spells:before {
  content: "\f6b8";
}

.fa-book-user:before {
  content: "\f7e7";
}

.fa-bookmark:before {
  content: "\f02e";
}

.fa-books:before {
  content: "\f5db";
}

.fa-books-medical:before {
  content: "\f7e8";
}

.fa-boot:before {
  content: "\f782";
}

.fa-booth-curtain:before {
  content: "\f734";
}

.fa-bootstrap:before {
  content: "\f836";
}

.fa-border-all:before {
  content: "\f84c";
}

.fa-border-bottom:before {
  content: "\f84d";
}

.fa-border-inner:before {
  content: "\f84e";
}

.fa-border-left:before {
  content: "\f84f";
}

.fa-border-none:before {
  content: "\f850";
}

.fa-border-outer:before {
  content: "\f851";
}

.fa-border-right:before {
  content: "\f852";
}

.fa-border-style:before {
  content: "\f853";
}

.fa-border-style-alt:before {
  content: "\f854";
}

.fa-border-top:before {
  content: "\f855";
}

.fa-bow-arrow:before {
  content: "\f6b9";
}

.fa-bowling-ball:before {
  content: "\f436";
}

.fa-bowling-pins:before {
  content: "\f437";
}

.fa-box:before {
  content: "\f466";
}

.fa-box-alt:before {
  content: "\f49a";
}

.fa-box-ballot:before {
  content: "\f735";
}

.fa-box-check:before {
  content: "\f467";
}

.fa-box-fragile:before {
  content: "\f49b";
}

.fa-box-full:before {
  content: "\f49c";
}

.fa-box-heart:before {
  content: "\f49d";
}

.fa-box-open:before {
  content: "\f49e";
}

.fa-box-up:before {
  content: "\f49f";
}

.fa-box-usd:before {
  content: "\f4a0";
}

.fa-boxes:before {
  content: "\f468";
}

.fa-boxes-alt:before {
  content: "\f4a1";
}

.fa-boxing-glove:before {
  content: "\f438";
}

.fa-brackets:before {
  content: "\f7e9";
}

.fa-brackets-curly:before {
  content: "\f7ea";
}

.fa-braille:before {
  content: "\f2a1";
}

.fa-brain:before {
  content: "\f5dc";
}

.fa-bread-loaf:before {
  content: "\f7eb";
}

.fa-bread-slice:before {
  content: "\f7ec";
}

.fa-briefcase:before {
  content: "\f0b1";
}

.fa-briefcase-medical:before {
  content: "\f469";
}

.fa-bring-forward:before {
  content: "\f856";
}

.fa-bring-front:before {
  content: "\f857";
}

.fa-broadcast-tower:before {
  content: "\f519";
}

.fa-broom:before {
  content: "\f51a";
}

.fa-browser:before {
  content: "\f37e";
}

.fa-brush:before {
  content: "\f55d";
}

.fa-btc:before {
  content: "\f15a";
}

.fa-buffer:before {
  content: "\f837";
}

.fa-bug:before {
  content: "\f188";
}

.fa-building:before {
  content: "\f1ad";
}

.fa-bullhorn:before {
  content: "\f0a1";
}

.fa-bullseye:before {
  content: "\f140";
}

.fa-bullseye-arrow:before {
  content: "\f648";
}

.fa-bullseye-pointer:before {
  content: "\f649";
}

.fa-burger-soda:before {
  content: "\f858";
}

.fa-burn:before {
  content: "\f46a";
}

.fa-buromobelexperte:before {
  content: "\f37f";
}

.fa-burrito:before {
  content: "\f7ed";
}

.fa-bus:before {
  content: "\f207";
}

.fa-bus-alt:before {
  content: "\f55e";
}

.fa-bus-school:before {
  content: "\f5dd";
}

.fa-business-time:before {
  content: "\f64a";
}

.fa-buysellads:before {
  content: "\f20d";
}

.fa-cabinet-filing:before {
  content: "\f64b";
}

.fa-calculator:before {
  content: "\f1ec";
}

.fa-calculator-alt:before {
  content: "\f64c";
}

.fa-calendar:before {
  content: "\f133";
}

.fa-calendar-alt:before {
  content: "\f073";
}

.fa-calendar-check:before {
  content: "\f274";
}

.fa-calendar-day:before {
  content: "\f783";
}

.fa-calendar-edit:before {
  content: "\f333";
}

.fa-calendar-exclamation:before {
  content: "\f334";
}

.fa-calendar-minus:before {
  content: "\f272";
}

.fa-calendar-plus:before {
  content: "\f271";
}

.fa-calendar-star:before {
  content: "\f736";
}

.fa-calendar-times:before {
  content: "\f273";
}

.fa-calendar-week:before {
  content: "\f784";
}

.fa-camera:before {
  content: "\f030";
}

.fa-camera-alt:before {
  content: "\f332";
}

.fa-camera-retro:before {
  content: "\f083";
}

.fa-campfire:before {
  content: "\f6ba";
}

.fa-campground:before {
  content: "\f6bb";
}

.fa-canadian-maple-leaf:before {
  content: "\f785";
}

.fa-candle-holder:before {
  content: "\f6bc";
}

.fa-candy-cane:before {
  content: "\f786";
}

.fa-candy-corn:before {
  content: "\f6bd";
}

.fa-cannabis:before {
  content: "\f55f";
}

.fa-capsules:before {
  content: "\f46b";
}

.fa-car:before {
  content: "\f1b9";
}

.fa-car-alt:before {
  content: "\f5de";
}

.fa-car-battery:before {
  content: "\f5df";
}

.fa-car-building:before {
  content: "\f859";
}

.fa-car-bump:before {
  content: "\f5e0";
}

.fa-car-bus:before {
  content: "\f85a";
}

.fa-car-crash:before {
  content: "\f5e1";
}

.fa-car-garage:before {
  content: "\f5e2";
}

.fa-car-mechanic:before {
  content: "\f5e3";
}

.fa-car-side:before {
  content: "\f5e4";
}

.fa-car-tilt:before {
  content: "\f5e5";
}

.fa-car-wash:before {
  content: "\f5e6";
}

.fa-caret-circle-down:before {
  content: "\f32d";
}

.fa-caret-circle-left:before {
  content: "\f32e";
}

.fa-caret-circle-right:before {
  content: "\f330";
}

.fa-caret-circle-up:before {
  content: "\f331";
}

.fa-caret-down:before {
  content: "\f0d7";
}

.fa-caret-left:before {
  content: "\f0d9";
}

.fa-caret-right:before {
  content: "\f0da";
}

.fa-caret-square-down:before {
  content: "\f150";
}

.fa-caret-square-left:before {
  content: "\f191";
}

.fa-caret-square-right:before {
  content: "\f152";
}

.fa-caret-square-up:before {
  content: "\f151";
}

.fa-caret-up:before {
  content: "\f0d8";
}

.fa-carrot:before {
  content: "\f787";
}

.fa-cars:before {
  content: "\f85b";
}

.fa-cart-arrow-down:before {
  content: "\f218";
}

.fa-cart-plus:before {
  content: "\f217";
}

.fa-cash-register:before {
  content: "\f788";
}

.fa-cat:before {
  content: "\f6be";
}

.fa-cauldron:before {
  content: "\f6bf";
}

.fa-cc-amazon-pay:before {
  content: "\f42d";
}

.fa-cc-amex:before {
  content: "\f1f3";
}

.fa-cc-apple-pay:before {
  content: "\f416";
}

.fa-cc-diners-club:before {
  content: "\f24c";
}

.fa-cc-discover:before {
  content: "\f1f2";
}

.fa-cc-jcb:before {
  content: "\f24b";
}

.fa-cc-mastercard:before {
  content: "\f1f1";
}

.fa-cc-paypal:before {
  content: "\f1f4";
}

.fa-cc-stripe:before {
  content: "\f1f5";
}

.fa-cc-visa:before {
  content: "\f1f0";
}

.fa-centercode:before {
  content: "\f380";
}

.fa-centos:before {
  content: "\f789";
}

.fa-certificate:before {
  content: "\f0a3";
}

.fa-chair:before {
  content: "\f6c0";
}

.fa-chair-office:before {
  content: "\f6c1";
}

.fa-chalkboard:before {
  content: "\f51b";
}

.fa-chalkboard-teacher:before {
  content: "\f51c";
}

.fa-charging-station:before {
  content: "\f5e7";
}

.fa-chart-area:before {
  content: "\f1fe";
}

.fa-chart-bar:before {
  content: "\f080";
}

.fa-chart-line:before {
  content: "\f201";
}

.fa-chart-line-down:before {
  content: "\f64d";
}

.fa-chart-network:before {
  content: "\f78a";
}

.fa-chart-pie:before {
  content: "\f200";
}

.fa-chart-pie-alt:before {
  content: "\f64e";
}

.fa-chart-scatter:before {
  content: "\f7ee";
}

.fa-check:before {
  content: "\f00c";
}

.fa-check-circle:before {
  content: "\f058";
}

.fa-check-double:before {
  content: "\f560";
}

.fa-check-square:before {
  content: "\f14a";
}

.fa-cheese:before {
  content: "\f7ef";
}

.fa-cheese-swiss:before {
  content: "\f7f0";
}

.fa-cheeseburger:before {
  content: "\f7f1";
}

.fa-chess:before {
  content: "\f439";
}

.fa-chess-bishop:before {
  content: "\f43a";
}

.fa-chess-bishop-alt:before {
  content: "\f43b";
}

.fa-chess-board:before {
  content: "\f43c";
}

.fa-chess-clock:before {
  content: "\f43d";
}

.fa-chess-clock-alt:before {
  content: "\f43e";
}

.fa-chess-king:before {
  content: "\f43f";
}

.fa-chess-king-alt:before {
  content: "\f440";
}

.fa-chess-knight:before {
  content: "\f441";
}

.fa-chess-knight-alt:before {
  content: "\f442";
}

.fa-chess-pawn:before {
  content: "\f443";
}

.fa-chess-pawn-alt:before {
  content: "\f444";
}

.fa-chess-queen:before {
  content: "\f445";
}

.fa-chess-queen-alt:before {
  content: "\f446";
}

.fa-chess-rook:before {
  content: "\f447";
}

.fa-chess-rook-alt:before {
  content: "\f448";
}

.fa-chevron-circle-down:before {
  content: "\f13a";
}

.fa-chevron-circle-left:before {
  content: "\f137";
}

.fa-chevron-circle-right:before {
  content: "\f138";
}

.fa-chevron-circle-up:before {
  content: "\f139";
}

.fa-chevron-double-down:before {
  content: "\f322";
}

.fa-chevron-double-left:before {
  content: "\f323";
}

.fa-chevron-double-right:before {
  content: "\f324";
}

.fa-chevron-double-up:before {
  content: "\f325";
}

.fa-chevron-down:before {
  content: "\f078";
}

.fa-chevron-left:before {
  content: "\f053";
}

.fa-chevron-right:before {
  content: "\f054";
}

.fa-chevron-square-down:before {
  content: "\f329";
}

.fa-chevron-square-left:before {
  content: "\f32a";
}

.fa-chevron-square-right:before {
  content: "\f32b";
}

.fa-chevron-square-up:before {
  content: "\f32c";
}

.fa-chevron-up:before {
  content: "\f077";
}

.fa-child:before {
  content: "\f1ae";
}

.fa-chimney:before {
  content: "\f78b";
}

.fa-chrome:before {
  content: "\f268";
}

.fa-chromecast:before {
  content: "\f838";
}

.fa-church:before {
  content: "\f51d";
}

.fa-circle:before {
  content: "\f111";
}

.fa-circle-notch:before {
  content: "\f1ce";
}

.fa-city:before {
  content: "\f64f";
}

.fa-claw-marks:before {
  content: "\f6c2";
}

.fa-clinic-medical:before {
  content: "\f7f2";
}

.fa-clipboard:before {
  content: "\f328";
}

.fa-clipboard-check:before {
  content: "\f46c";
}

.fa-clipboard-list:before {
  content: "\f46d";
}

.fa-clipboard-list-check:before {
  content: "\f737";
}

.fa-clipboard-prescription:before {
  content: "\f5e8";
}

.fa-clipboard-user:before {
  content: "\f7f3";
}

.fa-clock:before {
  content: "\f017";
}

.fa-clone:before {
  content: "\f24d";
}

.fa-closed-captioning:before {
  content: "\f20a";
}

.fa-cloud:before {
  content: "\f0c2";
}

.fa-cloud-download:before {
  content: "\f0ed";
}

.fa-cloud-download-alt:before {
  content: "\f381";
}

.fa-cloud-drizzle:before {
  content: "\f738";
}

.fa-cloud-hail:before {
  content: "\f739";
}

.fa-cloud-hail-mixed:before {
  content: "\f73a";
}

.fa-cloud-meatball:before {
  content: "\f73b";
}

.fa-cloud-moon:before {
  content: "\f6c3";
}

.fa-cloud-moon-rain:before {
  content: "\f73c";
}

.fa-cloud-rain:before {
  content: "\f73d";
}

.fa-cloud-rainbow:before {
  content: "\f73e";
}

.fa-cloud-showers:before {
  content: "\f73f";
}

.fa-cloud-showers-heavy:before {
  content: "\f740";
}

.fa-cloud-sleet:before {
  content: "\f741";
}

.fa-cloud-snow:before {
  content: "\f742";
}

.fa-cloud-sun:before {
  content: "\f6c4";
}

.fa-cloud-sun-rain:before {
  content: "\f743";
}

.fa-cloud-upload:before {
  content: "\f0ee";
}

.fa-cloud-upload-alt:before {
  content: "\f382";
}

.fa-clouds:before {
  content: "\f744";
}

.fa-clouds-moon:before {
  content: "\f745";
}

.fa-clouds-sun:before {
  content: "\f746";
}

.fa-cloudscale:before {
  content: "\f383";
}

.fa-cloudsmith:before {
  content: "\f384";
}

.fa-cloudversify:before {
  content: "\f385";
}

.fa-club:before {
  content: "\f327";
}

.fa-cocktail:before {
  content: "\f561";
}

.fa-code:before {
  content: "\f121";
}

.fa-code-branch:before {
  content: "\f126";
}

.fa-code-commit:before {
  content: "\f386";
}

.fa-code-merge:before {
  content: "\f387";
}

.fa-codepen:before {
  content: "\f1cb";
}

.fa-codiepie:before {
  content: "\f284";
}

.fa-coffee:before {
  content: "\f0f4";
}

.fa-coffee-togo:before {
  content: "\f6c5";
}

.fa-coffin:before {
  content: "\f6c6";
}

.fa-cog:before {
  content: "\f013";
}

.fa-cogs:before {
  content: "\f085";
}

.fa-coin:before {
  content: "\f85c";
}

.fa-coins:before {
  content: "\f51e";
}

.fa-columns:before {
  content: "\f0db";
}

.fa-comment:before {
  content: "\f075";
}

.fa-comment-alt:before {
  content: "\f27a";
}

.fa-comment-alt-check:before {
  content: "\f4a2";
}

.fa-comment-alt-dollar:before {
  content: "\f650";
}

.fa-comment-alt-dots:before {
  content: "\f4a3";
}

.fa-comment-alt-edit:before {
  content: "\f4a4";
}

.fa-comment-alt-exclamation:before {
  content: "\f4a5";
}

.fa-comment-alt-lines:before {
  content: "\f4a6";
}

.fa-comment-alt-medical:before {
  content: "\f7f4";
}

.fa-comment-alt-minus:before {
  content: "\f4a7";
}

.fa-comment-alt-plus:before {
  content: "\f4a8";
}

.fa-comment-alt-slash:before {
  content: "\f4a9";
}

.fa-comment-alt-smile:before {
  content: "\f4aa";
}

.fa-comment-alt-times:before {
  content: "\f4ab";
}

.fa-comment-check:before {
  content: "\f4ac";
}

.fa-comment-dollar:before {
  content: "\f651";
}

.fa-comment-dots:before {
  content: "\f4ad";
}

.fa-comment-edit:before {
  content: "\f4ae";
}

.fa-comment-exclamation:before {
  content: "\f4af";
}

.fa-comment-lines:before {
  content: "\f4b0";
}

.fa-comment-medical:before {
  content: "\f7f5";
}

.fa-comment-minus:before {
  content: "\f4b1";
}

.fa-comment-plus:before {
  content: "\f4b2";
}

.fa-comment-slash:before {
  content: "\f4b3";
}

.fa-comment-smile:before {
  content: "\f4b4";
}

.fa-comment-times:before {
  content: "\f4b5";
}

.fa-comments:before {
  content: "\f086";
}

.fa-comments-alt:before {
  content: "\f4b6";
}

.fa-comments-alt-dollar:before {
  content: "\f652";
}

.fa-comments-dollar:before {
  content: "\f653";
}

.fa-compact-disc:before {
  content: "\f51f";
}

.fa-compass:before {
  content: "\f14e";
}

.fa-compass-slash:before {
  content: "\f5e9";
}

.fa-compress:before {
  content: "\f066";
}

.fa-compress-alt:before {
  content: "\f422";
}

.fa-compress-arrows-alt:before {
  content: "\f78c";
}

.fa-compress-wide:before {
  content: "\f326";
}

.fa-concierge-bell:before {
  content: "\f562";
}

.fa-confluence:before {
  content: "\f78d";
}

.fa-connectdevelop:before {
  content: "\f20e";
}

.fa-construction:before {
  content: "\f85d";
}

.fa-container-storage:before {
  content: "\f4b7";
}

.fa-contao:before {
  content: "\f26d";
}

.fa-conveyor-belt:before {
  content: "\f46e";
}

.fa-conveyor-belt-alt:before {
  content: "\f46f";
}

.fa-cookie:before {
  content: "\f563";
}

.fa-cookie-bite:before {
  content: "\f564";
}

.fa-copy:before {
  content: "\f0c5";
}

.fa-copyright:before {
  content: "\f1f9";
}

.fa-corn:before {
  content: "\f6c7";
}

.fa-couch:before {
  content: "\f4b8";
}

.fa-cow:before {
  content: "\f6c8";
}

.fa-cpanel:before {
  content: "\f388";
}

.fa-creative-commons:before {
  content: "\f25e";
}

.fa-creative-commons-by:before {
  content: "\f4e7";
}

.fa-creative-commons-nc:before {
  content: "\f4e8";
}

.fa-creative-commons-nc-eu:before {
  content: "\f4e9";
}

.fa-creative-commons-nc-jp:before {
  content: "\f4ea";
}

.fa-creative-commons-nd:before {
  content: "\f4eb";
}

.fa-creative-commons-pd:before {
  content: "\f4ec";
}

.fa-creative-commons-pd-alt:before {
  content: "\f4ed";
}

.fa-creative-commons-remix:before {
  content: "\f4ee";
}

.fa-creative-commons-sa:before {
  content: "\f4ef";
}

.fa-creative-commons-sampling:before {
  content: "\f4f0";
}

.fa-creative-commons-sampling-plus:before {
  content: "\f4f1";
}

.fa-creative-commons-share:before {
  content: "\f4f2";
}

.fa-creative-commons-zero:before {
  content: "\f4f3";
}

.fa-credit-card:before {
  content: "\f09d";
}

.fa-credit-card-blank:before {
  content: "\f389";
}

.fa-credit-card-front:before {
  content: "\f38a";
}

.fa-cricket:before {
  content: "\f449";
}

.fa-critical-role:before {
  content: "\f6c9";
}

.fa-croissant:before {
  content: "\f7f6";
}

.fa-crop:before {
  content: "\f125";
}

.fa-crop-alt:before {
  content: "\f565";
}

.fa-cross:before {
  content: "\f654";
}

.fa-crosshairs:before {
  content: "\f05b";
}

.fa-crow:before {
  content: "\f520";
}

.fa-crown:before {
  content: "\f521";
}

.fa-crutch:before {
  content: "\f7f7";
}

.fa-crutches:before {
  content: "\f7f8";
}

.fa-css3:before {
  content: "\f13c";
}

.fa-css3-alt:before {
  content: "\f38b";
}

.fa-cube:before {
  content: "\f1b2";
}

.fa-cubes:before {
  content: "\f1b3";
}

.fa-curling:before {
  content: "\f44a";
}

.fa-cut:before {
  content: "\f0c4";
}

.fa-cuttlefish:before {
  content: "\f38c";
}

.fa-d-and-d:before {
  content: "\f38d";
}

.fa-d-and-d-beyond:before {
  content: "\f6ca";
}

.fa-dagger:before {
  content: "\f6cb";
}

.fa-dashcube:before {
  content: "\f210";
}

.fa-database:before {
  content: "\f1c0";
}

.fa-deaf:before {
  content: "\f2a4";
}

.fa-debug:before {
  content: "\f7f9";
}

.fa-deer:before {
  content: "\f78e";
}

.fa-deer-rudolph:before {
  content: "\f78f";
}

.fa-delicious:before {
  content: "\f1a5";
}

.fa-democrat:before {
  content: "\f747";
}

.fa-deploydog:before {
  content: "\f38e";
}

.fa-deskpro:before {
  content: "\f38f";
}

.fa-desktop:before {
  content: "\f108";
}

.fa-desktop-alt:before {
  content: "\f390";
}

.fa-dev:before {
  content: "\f6cc";
}

.fa-deviantart:before {
  content: "\f1bd";
}

.fa-dewpoint:before {
  content: "\f748";
}

.fa-dharmachakra:before {
  content: "\f655";
}

.fa-dhl:before {
  content: "\f790";
}

.fa-diagnoses:before {
  content: "\f470";
}

.fa-diamond:before {
  content: "\f219";
}

.fa-diaspora:before {
  content: "\f791";
}

.fa-dice:before {
  content: "\f522";
}

.fa-dice-d10:before {
  content: "\f6cd";
}

.fa-dice-d12:before {
  content: "\f6ce";
}

.fa-dice-d20:before {
  content: "\f6cf";
}

.fa-dice-d4:before {
  content: "\f6d0";
}

.fa-dice-d6:before {
  content: "\f6d1";
}

.fa-dice-d8:before {
  content: "\f6d2";
}

.fa-dice-five:before {
  content: "\f523";
}

.fa-dice-four:before {
  content: "\f524";
}

.fa-dice-one:before {
  content: "\f525";
}

.fa-dice-six:before {
  content: "\f526";
}

.fa-dice-three:before {
  content: "\f527";
}

.fa-dice-two:before {
  content: "\f528";
}

.fa-digg:before {
  content: "\f1a6";
}

.fa-digging:before {
  content: "\f85e";
}

.fa-digital-ocean:before {
  content: "\f391";
}

.fa-digital-tachograph:before {
  content: "\f566";
}

.fa-diploma:before {
  content: "\f5ea";
}

.fa-directions:before {
  content: "\f5eb";
}

.fa-discord:before {
  content: "\f392";
}

.fa-discourse:before {
  content: "\f393";
}

.fa-disease:before {
  content: "\f7fa";
}

.fa-divide:before {
  content: "\f529";
}

.fa-dizzy:before {
  content: "\f567";
}

.fa-dna:before {
  content: "\f471";
}

.fa-do-not-enter:before {
  content: "\f5ec";
}

.fa-dochub:before {
  content: "\f394";
}

.fa-docker:before {
  content: "\f395";
}

.fa-dog:before {
  content: "\f6d3";
}

.fa-dog-leashed:before {
  content: "\f6d4";
}

.fa-dollar-sign:before {
  content: "\f155";
}

.fa-dolly:before {
  content: "\f472";
}

.fa-dolly-empty:before {
  content: "\f473";
}

.fa-dolly-flatbed:before {
  content: "\f474";
}

.fa-dolly-flatbed-alt:before {
  content: "\f475";
}

.fa-dolly-flatbed-empty:before {
  content: "\f476";
}

.fa-donate:before {
  content: "\f4b9";
}

.fa-door-closed:before {
  content: "\f52a";
}

.fa-door-open:before {
  content: "\f52b";
}

.fa-dot-circle:before {
  content: "\f192";
}

.fa-dove:before {
  content: "\f4ba";
}

.fa-download:before {
  content: "\f019";
}

.fa-draft2digital:before {
  content: "\f396";
}

.fa-drafting-compass:before {
  content: "\f568";
}

.fa-dragon:before {
  content: "\f6d5";
}

.fa-draw-circle:before {
  content: "\f5ed";
}

.fa-draw-polygon:before {
  content: "\f5ee";
}

.fa-draw-square:before {
  content: "\f5ef";
}

.fa-dreidel:before {
  content: "\f792";
}

.fa-dribbble:before {
  content: "\f17d";
}

.fa-dribbble-square:before {
  content: "\f397";
}

.fa-drone:before {
  content: "\f85f";
}

.fa-drone-alt:before {
  content: "\f860";
}

.fa-dropbox:before {
  content: "\f16b";
}

.fa-drum:before {
  content: "\f569";
}

.fa-drum-steelpan:before {
  content: "\f56a";
}

.fa-drumstick:before {
  content: "\f6d6";
}

.fa-drumstick-bite:before {
  content: "\f6d7";
}

.fa-drupal:before {
  content: "\f1a9";
}

.fa-dryer:before {
  content: "\f861";
}

.fa-dryer-alt:before {
  content: "\f862";
}

.fa-duck:before {
  content: "\f6d8";
}

.fa-dumbbell:before {
  content: "\f44b";
}

.fa-dumpster:before {
  content: "\f793";
}

.fa-dumpster-fire:before {
  content: "\f794";
}

.fa-dungeon:before {
  content: "\f6d9";
}

.fa-dyalog:before {
  content: "\f399";
}

.fa-ear:before {
  content: "\f5f0";
}

.fa-ear-muffs:before {
  content: "\f795";
}

.fa-earlybirds:before {
  content: "\f39a";
}

.fa-ebay:before {
  content: "\f4f4";
}

.fa-eclipse:before {
  content: "\f749";
}

.fa-eclipse-alt:before {
  content: "\f74a";
}

.fa-edge:before {
  content: "\f282";
}

.fa-edit:before {
  content: "\f044";
}

.fa-egg:before {
  content: "\f7fb";
}

.fa-egg-fried:before {
  content: "\f7fc";
}

.fa-eject:before {
  content: "\f052";
}

.fa-elementor:before {
  content: "\f430";
}

.fa-elephant:before {
  content: "\f6da";
}

.fa-ellipsis-h:before {
  content: "\f141";
}

.fa-ellipsis-h-alt:before {
  content: "\f39b";
}

.fa-ellipsis-v:before {
  content: "\f142";
}

.fa-ellipsis-v-alt:before {
  content: "\f39c";
}

.fa-ello:before {
  content: "\f5f1";
}

.fa-ember:before {
  content: "\f423";
}

.fa-empire:before {
  content: "\f1d1";
}

.fa-empty-set:before {
  content: "\f656";
}

.fa-engine-warning:before {
  content: "\f5f2";
}

.fa-envelope:before {
  content: "\f0e0";
}

.fa-envelope-open:before {
  content: "\f2b6";
}

.fa-envelope-open-dollar:before {
  content: "\f657";
}

.fa-envelope-open-text:before {
  content: "\f658";
}

.fa-envelope-square:before {
  content: "\f199";
}

.fa-envira:before {
  content: "\f299";
}

.fa-equals:before {
  content: "\f52c";
}

.fa-eraser:before {
  content: "\f12d";
}

.fa-erlang:before {
  content: "\f39d";
}

.fa-ethereum:before {
  content: "\f42e";
}

.fa-ethernet:before {
  content: "\f796";
}

.fa-etsy:before {
  content: "\f2d7";
}

.fa-euro-sign:before {
  content: "\f153";
}

.fa-evernote:before {
  content: "\f839";
}

.fa-exchange:before {
  content: "\f0ec";
}

.fa-exchange-alt:before {
  content: "\f362";
}

.fa-exclamation:before {
  content: "\f12a";
}

.fa-exclamation-circle:before {
  content: "\f06a";
}

.fa-exclamation-square:before {
  content: "\f321";
}

.fa-exclamation-triangle:before {
  content: "\f071";
}

.fa-expand:before {
  content: "\f065";
}

.fa-expand-alt:before {
  content: "\f424";
}

.fa-expand-arrows:before {
  content: "\f31d";
}

.fa-expand-arrows-alt:before {
  content: "\f31e";
}

.fa-expand-wide:before {
  content: "\f320";
}

.fa-expeditedssl:before {
  content: "\f23e";
}

.fa-external-link:before {
  content: "\f08e";
}

.fa-external-link-alt:before {
  content: "\f35d";
}

.fa-external-link-square:before {
  content: "\f14c";
}

.fa-external-link-square-alt:before {
  content: "\f360";
}

.fa-eye:before {
  content: "\f06e";
}

.fa-eye-dropper:before {
  content: "\f1fb";
}

.fa-eye-evil:before {
  content: "\f6db";
}

.fa-eye-slash:before {
  content: "\f070";
}

.fa-facebook:before {
  content: "\f09a";
}

.fa-facebook-f:before {
  content: "\f39e";
}

.fa-facebook-messenger:before {
  content: "\f39f";
}

.fa-facebook-square:before {
  content: "\f082";
}

.fa-fan:before {
  content: "\f863";
}

.fa-fantasy-flight-games:before {
  content: "\f6dc";
}

.fa-farm:before {
  content: "\f864";
}

.fa-fast-backward:before {
  content: "\f049";
}

.fa-fast-forward:before {
  content: "\f050";
}

.fa-fax:before {
  content: "\f1ac";
}

.fa-feather:before {
  content: "\f52d";
}

.fa-feather-alt:before {
  content: "\f56b";
}

.fa-fedex:before {
  content: "\f797";
}

.fa-fedora:before {
  content: "\f798";
}

.fa-female:before {
  content: "\f182";
}

.fa-field-hockey:before {
  content: "\f44c";
}

.fa-fighter-jet:before {
  content: "\f0fb";
}

.fa-figma:before {
  content: "\f799";
}

.fa-file:before {
  content: "\f15b";
}

.fa-file-alt:before {
  content: "\f15c";
}

.fa-file-archive:before {
  content: "\f1c6";
}

.fa-file-audio:before {
  content: "\f1c7";
}

.fa-file-certificate:before {
  content: "\f5f3";
}

.fa-file-chart-line:before {
  content: "\f659";
}

.fa-file-chart-pie:before {
  content: "\f65a";
}

.fa-file-check:before {
  content: "\f316";
}

.fa-file-code:before {
  content: "\f1c9";
}

.fa-file-contract:before {
  content: "\f56c";
}

.fa-file-csv:before {
  content: "\f6dd";
}

.fa-file-download:before {
  content: "\f56d";
}

.fa-file-edit:before {
  content: "\f31c";
}

.fa-file-excel:before {
  content: "\f1c3";
}

.fa-file-exclamation:before {
  content: "\f31a";
}

.fa-file-export:before {
  content: "\f56e";
}

.fa-file-image:before {
  content: "\f1c5";
}

.fa-file-import:before {
  content: "\f56f";
}

.fa-file-invoice:before {
  content: "\f570";
}

.fa-file-invoice-dollar:before {
  content: "\f571";
}

.fa-file-medical:before {
  content: "\f477";
}

.fa-file-medical-alt:before {
  content: "\f478";
}

.fa-file-minus:before {
  content: "\f318";
}

.fa-file-pdf:before {
  content: "\f1c1";
}

.fa-file-plus:before {
  content: "\f319";
}

.fa-file-powerpoint:before {
  content: "\f1c4";
}

.fa-file-prescription:before {
  content: "\f572";
}

.fa-file-search:before {
  content: "\f865";
}

.fa-file-signature:before {
  content: "\f573";
}

.fa-file-spreadsheet:before {
  content: "\f65b";
}

.fa-file-times:before {
  content: "\f317";
}

.fa-file-upload:before {
  content: "\f574";
}

.fa-file-user:before {
  content: "\f65c";
}

.fa-file-video:before {
  content: "\f1c8";
}

.fa-file-word:before {
  content: "\f1c2";
}

.fa-files-medical:before {
  content: "\f7fd";
}

.fa-fill:before {
  content: "\f575";
}

.fa-fill-drip:before {
  content: "\f576";
}

.fa-film:before {
  content: "\f008";
}

.fa-film-alt:before {
  content: "\f3a0";
}

.fa-filter:before {
  content: "\f0b0";
}

.fa-fingerprint:before {
  content: "\f577";
}

.fa-fire:before {
  content: "\f06d";
}

.fa-fire-alt:before {
  content: "\f7e4";
}

.fa-fire-extinguisher:before {
  content: "\f134";
}

.fa-fire-smoke:before {
  content: "\f74b";
}

.fa-firefox:before {
  content: "\f269";
}

.fa-fireplace:before {
  content: "\f79a";
}

.fa-first-aid:before {
  content: "\f479";
}

.fa-first-order:before {
  content: "\f2b0";
}

.fa-first-order-alt:before {
  content: "\f50a";
}

.fa-firstdraft:before {
  content: "\f3a1";
}

.fa-fish:before {
  content: "\f578";
}

.fa-fish-cooked:before {
  content: "\f7fe";
}

.fa-fist-raised:before {
  content: "\f6de";
}

.fa-flag:before {
  content: "\f024";
}

.fa-flag-alt:before {
  content: "\f74c";
}

.fa-flag-checkered:before {
  content: "\f11e";
}

.fa-flag-usa:before {
  content: "\f74d";
}

.fa-flame:before {
  content: "\f6df";
}

.fa-flask:before {
  content: "\f0c3";
}

.fa-flask-poison:before {
  content: "\f6e0";
}

.fa-flask-potion:before {
  content: "\f6e1";
}

.fa-flickr:before {
  content: "\f16e";
}

.fa-flipboard:before {
  content: "\f44d";
}

.fa-flower:before {
  content: "\f7ff";
}

.fa-flower-daffodil:before {
  content: "\f800";
}

.fa-flower-tulip:before {
  content: "\f801";
}

.fa-flushed:before {
  content: "\f579";
}

.fa-fly:before {
  content: "\f417";
}

.fa-fog:before {
  content: "\f74e";
}

.fa-folder:before {
  content: "\f07b";
}

.fa-folder-minus:before {
  content: "\f65d";
}

.fa-folder-open:before {
  content: "\f07c";
}

.fa-folder-plus:before {
  content: "\f65e";
}

.fa-folder-times:before {
  content: "\f65f";
}

.fa-folder-tree:before {
  content: "\f802";
}

.fa-folders:before {
  content: "\f660";
}

.fa-font:before {
  content: "\f031";
}

.fa-font-awesome:before {
  content: "\f2b4";
}

.fa-font-awesome-alt:before {
  content: "\f35c";
}

.fa-font-awesome-flag:before {
  content: "\f425";
}

.fa-font-awesome-logo-full:before {
  content: "\f4e6";
}

.fa-font-case:before {
  content: "\f866";
}

.fa-fonticons:before {
  content: "\f280";
}

.fa-fonticons-fi:before {
  content: "\f3a2";
}

.fa-football-ball:before {
  content: "\f44e";
}

.fa-football-helmet:before {
  content: "\f44f";
}

.fa-forklift:before {
  content: "\f47a";
}

.fa-fort-awesome:before {
  content: "\f286";
}

.fa-fort-awesome-alt:before {
  content: "\f3a3";
}

.fa-forumbee:before {
  content: "\f211";
}

.fa-forward:before {
  content: "\f04e";
}

.fa-foursquare:before {
  content: "\f180";
}

.fa-fragile:before {
  content: "\f4bb";
}

.fa-free-code-camp:before {
  content: "\f2c5";
}

.fa-freebsd:before {
  content: "\f3a4";
}

.fa-french-fries:before {
  content: "\f803";
}

.fa-frog:before {
  content: "\f52e";
}

.fa-frosty-head:before {
  content: "\f79b";
}

.fa-frown:before {
  content: "\f119";
}

.fa-frown-open:before {
  content: "\f57a";
}

.fa-fulcrum:before {
  content: "\f50b";
}

.fa-function:before {
  content: "\f661";
}

.fa-funnel-dollar:before {
  content: "\f662";
}

.fa-futbol:before {
  content: "\f1e3";
}

.fa-galactic-republic:before {
  content: "\f50c";
}

.fa-galactic-senate:before {
  content: "\f50d";
}

.fa-game-board:before {
  content: "\f867";
}

.fa-game-board-alt:before {
  content: "\f868";
}

.fa-gamepad:before {
  content: "\f11b";
}

.fa-gas-pump:before {
  content: "\f52f";
}

.fa-gas-pump-slash:before {
  content: "\f5f4";
}

.fa-gavel:before {
  content: "\f0e3";
}

.fa-gem:before {
  content: "\f3a5";
}

.fa-genderless:before {
  content: "\f22d";
}

.fa-get-pocket:before {
  content: "\f265";
}

.fa-gg:before {
  content: "\f260";
}

.fa-gg-circle:before {
  content: "\f261";
}

.fa-ghost:before {
  content: "\f6e2";
}

.fa-gift:before {
  content: "\f06b";
}

.fa-gift-card:before {
  content: "\f663";
}

.fa-gifts:before {
  content: "\f79c";
}

.fa-gingerbread-man:before {
  content: "\f79d";
}

.fa-git:before {
  content: "\f1d3";
}

.fa-git-alt:before {
  content: "\f841";
}

.fa-git-square:before {
  content: "\f1d2";
}

.fa-github:before {
  content: "\f09b";
}

.fa-github-alt:before {
  content: "\f113";
}

.fa-github-square:before {
  content: "\f092";
}

.fa-gitkraken:before {
  content: "\f3a6";
}

.fa-gitlab:before {
  content: "\f296";
}

.fa-gitter:before {
  content: "\f426";
}

.fa-glass:before {
  content: "\f804";
}

.fa-glass-champagne:before {
  content: "\f79e";
}

.fa-glass-cheers:before {
  content: "\f79f";
}

.fa-glass-citrus:before {
  content: "\f869";
}

.fa-glass-martini:before {
  content: "\f000";
}

.fa-glass-martini-alt:before {
  content: "\f57b";
}

.fa-glass-whiskey:before {
  content: "\f7a0";
}

.fa-glass-whiskey-rocks:before {
  content: "\f7a1";
}

.fa-glasses:before {
  content: "\f530";
}

.fa-glasses-alt:before {
  content: "\f5f5";
}

.fa-glide:before {
  content: "\f2a5";
}

.fa-glide-g:before {
  content: "\f2a6";
}

.fa-globe:before {
  content: "\f0ac";
}

.fa-globe-africa:before {
  content: "\f57c";
}

.fa-globe-americas:before {
  content: "\f57d";
}

.fa-globe-asia:before {
  content: "\f57e";
}

.fa-globe-europe:before {
  content: "\f7a2";
}

.fa-globe-snow:before {
  content: "\f7a3";
}

.fa-globe-stand:before {
  content: "\f5f6";
}

.fa-gofore:before {
  content: "\f3a7";
}

.fa-golf-ball:before {
  content: "\f450";
}

.fa-golf-club:before {
  content: "\f451";
}

.fa-goodreads:before {
  content: "\f3a8";
}

.fa-goodreads-g:before {
  content: "\f3a9";
}

.fa-google:before {
  content: "\f1a0";
}

.fa-google-drive:before {
  content: "\f3aa";
}

.fa-google-play:before {
  content: "\f3ab";
}

.fa-google-plus:before {
  content: "\f2b3";
}

.fa-google-plus-g:before {
  content: "\f0d5";
}

.fa-google-plus-square:before {
  content: "\f0d4";
}

.fa-google-wallet:before {
  content: "\f1ee";
}

.fa-gopuram:before {
  content: "\f664";
}

.fa-graduation-cap:before {
  content: "\f19d";
}

.fa-gratipay:before {
  content: "\f184";
}

.fa-grav:before {
  content: "\f2d6";
}

.fa-greater-than:before {
  content: "\f531";
}

.fa-greater-than-equal:before {
  content: "\f532";
}

.fa-grimace:before {
  content: "\f57f";
}

.fa-grin:before {
  content: "\f580";
}

.fa-grin-alt:before {
  content: "\f581";
}

.fa-grin-beam:before {
  content: "\f582";
}

.fa-grin-beam-sweat:before {
  content: "\f583";
}

.fa-grin-hearts:before {
  content: "\f584";
}

.fa-grin-squint:before {
  content: "\f585";
}

.fa-grin-squint-tears:before {
  content: "\f586";
}

.fa-grin-stars:before {
  content: "\f587";
}

.fa-grin-tears:before {
  content: "\f588";
}

.fa-grin-tongue:before {
  content: "\f589";
}

.fa-grin-tongue-squint:before {
  content: "\f58a";
}

.fa-grin-tongue-wink:before {
  content: "\f58b";
}

.fa-grin-wink:before {
  content: "\f58c";
}

.fa-grip-horizontal:before {
  content: "\f58d";
}

.fa-grip-lines:before {
  content: "\f7a4";
}

.fa-grip-lines-vertical:before {
  content: "\f7a5";
}

.fa-grip-vertical:before {
  content: "\f58e";
}

.fa-gripfire:before {
  content: "\f3ac";
}

.fa-grunt:before {
  content: "\f3ad";
}

.fa-guitar:before {
  content: "\f7a6";
}

.fa-gulp:before {
  content: "\f3ae";
}

.fa-h-square:before {
  content: "\f0fd";
}

.fa-h1:before {
  content: "\f313";
}

.fa-h2:before {
  content: "\f314";
}

.fa-h3:before {
  content: "\f315";
}

.fa-h4:before {
  content: "\f86a";
}

.fa-hacker-news:before {
  content: "\f1d4";
}

.fa-hacker-news-square:before {
  content: "\f3af";
}

.fa-hackerrank:before {
  content: "\f5f7";
}

.fa-hamburger:before {
  content: "\f805";
}

.fa-hammer:before {
  content: "\f6e3";
}

.fa-hammer-war:before {
  content: "\f6e4";
}

.fa-hamsa:before {
  content: "\f665";
}

.fa-hand-heart:before {
  content: "\f4bc";
}

.fa-hand-holding:before {
  content: "\f4bd";
}

.fa-hand-holding-box:before {
  content: "\f47b";
}

.fa-hand-holding-heart:before {
  content: "\f4be";
}

.fa-hand-holding-magic:before {
  content: "\f6e5";
}

.fa-hand-holding-seedling:before {
  content: "\f4bf";
}

.fa-hand-holding-usd:before {
  content: "\f4c0";
}

.fa-hand-holding-water:before {
  content: "\f4c1";
}

.fa-hand-lizard:before {
  content: "\f258";
}

.fa-hand-middle-finger:before {
  content: "\f806";
}

.fa-hand-paper:before {
  content: "\f256";
}

.fa-hand-peace:before {
  content: "\f25b";
}

.fa-hand-point-down:before {
  content: "\f0a7";
}

.fa-hand-point-left:before {
  content: "\f0a5";
}

.fa-hand-point-right:before {
  content: "\f0a4";
}

.fa-hand-point-up:before {
  content: "\f0a6";
}

.fa-hand-pointer:before {
  content: "\f25a";
}

.fa-hand-receiving:before {
  content: "\f47c";
}

.fa-hand-rock:before {
  content: "\f255";
}

.fa-hand-scissors:before {
  content: "\f257";
}

.fa-hand-spock:before {
  content: "\f259";
}

.fa-hands:before {
  content: "\f4c2";
}

.fa-hands-heart:before {
  content: "\f4c3";
}

.fa-hands-helping:before {
  content: "\f4c4";
}

.fa-hands-usd:before {
  content: "\f4c5";
}

.fa-handshake:before {
  content: "\f2b5";
}

.fa-handshake-alt:before {
  content: "\f4c6";
}

.fa-hanukiah:before {
  content: "\f6e6";
}

.fa-hard-hat:before {
  content: "\f807";
}

.fa-hashtag:before {
  content: "\f292";
}

.fa-hat-chef:before {
  content: "\f86b";
}

.fa-hat-santa:before {
  content: "\f7a7";
}

.fa-hat-winter:before {
  content: "\f7a8";
}

.fa-hat-witch:before {
  content: "\f6e7";
}

.fa-hat-wizard:before {
  content: "\f6e8";
}

.fa-haykal:before {
  content: "\f666";
}

.fa-hdd:before {
  content: "\f0a0";
}

.fa-head-side:before {
  content: "\f6e9";
}

.fa-head-side-brain:before {
  content: "\f808";
}

.fa-head-side-medical:before {
  content: "\f809";
}

.fa-head-vr:before {
  content: "\f6ea";
}

.fa-heading:before {
  content: "\f1dc";
}

.fa-headphones:before {
  content: "\f025";
}

.fa-headphones-alt:before {
  content: "\f58f";
}

.fa-headset:before {
  content: "\f590";
}

.fa-heart:before {
  content: "\f004";
}

.fa-heart-broken:before {
  content: "\f7a9";
}

.fa-heart-circle:before {
  content: "\f4c7";
}

.fa-heart-rate:before {
  content: "\f5f8";
}

.fa-heart-square:before {
  content: "\f4c8";
}

.fa-heartbeat:before {
  content: "\f21e";
}

.fa-helicopter:before {
  content: "\f533";
}

.fa-helmet-battle:before {
  content: "\f6eb";
}

.fa-hexagon:before {
  content: "\f312";
}

.fa-highlighter:before {
  content: "\f591";
}

.fa-hiking:before {
  content: "\f6ec";
}

.fa-hippo:before {
  content: "\f6ed";
}

.fa-hips:before {
  content: "\f452";
}

.fa-hire-a-helper:before {
  content: "\f3b0";
}

.fa-history:before {
  content: "\f1da";
}

.fa-hockey-mask:before {
  content: "\f6ee";
}

.fa-hockey-puck:before {
  content: "\f453";
}

.fa-hockey-sticks:before {
  content: "\f454";
}

.fa-holly-berry:before {
  content: "\f7aa";
}

.fa-home:before {
  content: "\f015";
}

.fa-home-alt:before {
  content: "\f80a";
}

.fa-home-heart:before {
  content: "\f4c9";
}

.fa-home-lg:before {
  content: "\f80b";
}

.fa-home-lg-alt:before {
  content: "\f80c";
}

.fa-hood-cloak:before {
  content: "\f6ef";
}

.fa-hooli:before {
  content: "\f427";
}

.fa-horizontal-rule:before {
  content: "\f86c";
}

.fa-hornbill:before {
  content: "\f592";
}

.fa-horse:before {
  content: "\f6f0";
}

.fa-horse-head:before {
  content: "\f7ab";
}

.fa-hospital:before {
  content: "\f0f8";
}

.fa-hospital-alt:before {
  content: "\f47d";
}

.fa-hospital-symbol:before {
  content: "\f47e";
}

.fa-hospital-user:before {
  content: "\f80d";
}

.fa-hospitals:before {
  content: "\f80e";
}

.fa-hot-tub:before {
  content: "\f593";
}

.fa-hotdog:before {
  content: "\f80f";
}

.fa-hotel:before {
  content: "\f594";
}

.fa-hotjar:before {
  content: "\f3b1";
}

.fa-hourglass:before {
  content: "\f254";
}

.fa-hourglass-end:before {
  content: "\f253";
}

.fa-hourglass-half:before {
  content: "\f252";
}

.fa-hourglass-start:before {
  content: "\f251";
}

.fa-house-damage:before {
  content: "\f6f1";
}

.fa-house-flood:before {
  content: "\f74f";
}

.fa-houzz:before {
  content: "\f27c";
}

.fa-hryvnia:before {
  content: "\f6f2";
}

.fa-html5:before {
  content: "\f13b";
}

.fa-hubspot:before {
  content: "\f3b2";
}

.fa-humidity:before {
  content: "\f750";
}

.fa-hurricane:before {
  content: "\f751";
}

.fa-i-cursor:before {
  content: "\f246";
}

.fa-ice-cream:before {
  content: "\f810";
}

.fa-ice-skate:before {
  content: "\f7ac";
}

.fa-icicles:before {
  content: "\f7ad";
}

.fa-icons:before {
  content: "\f86d";
}

.fa-icons-alt:before {
  content: "\f86e";
}

.fa-id-badge:before {
  content: "\f2c1";
}

.fa-id-card:before {
  content: "\f2c2";
}

.fa-id-card-alt:before {
  content: "\f47f";
}

.fa-igloo:before {
  content: "\f7ae";
}

.fa-image:before {
  content: "\f03e";
}

.fa-images:before {
  content: "\f302";
}

.fa-imdb:before {
  content: "\f2d8";
}

.fa-inbox:before {
  content: "\f01c";
}

.fa-inbox-in:before {
  content: "\f310";
}

.fa-inbox-out:before {
  content: "\f311";
}

.fa-indent:before {
  content: "\f03c";
}

.fa-industry:before {
  content: "\f275";
}

.fa-industry-alt:before {
  content: "\f3b3";
}

.fa-infinity:before {
  content: "\f534";
}

.fa-info:before {
  content: "\f129";
}

.fa-info-circle:before {
  content: "\f05a";
}

.fa-info-square:before {
  content: "\f30f";
}

.fa-inhaler:before {
  content: "\f5f9";
}

.fa-instagram:before {
  content: "\f16d";
}

.fa-integral:before {
  content: "\f667";
}

.fa-intercom:before {
  content: "\f7af";
}

.fa-internet-explorer:before {
  content: "\f26b";
}

.fa-intersection:before {
  content: "\f668";
}

.fa-inventory:before {
  content: "\f480";
}

.fa-invision:before {
  content: "\f7b0";
}

.fa-ioxhost:before {
  content: "\f208";
}

.fa-island-tropical:before {
  content: "\f811";
}

.fa-italic:before {
  content: "\f033";
}

.fa-itch-io:before {
  content: "\f83a";
}

.fa-itunes:before {
  content: "\f3b4";
}

.fa-itunes-note:before {
  content: "\f3b5";
}

.fa-jack-o-lantern:before {
  content: "\f30e";
}

.fa-java:before {
  content: "\f4e4";
}

.fa-jedi:before {
  content: "\f669";
}

.fa-jedi-order:before {
  content: "\f50e";
}

.fa-jenkins:before {
  content: "\f3b6";
}

.fa-jira:before {
  content: "\f7b1";
}

.fa-joget:before {
  content: "\f3b7";
}

.fa-joint:before {
  content: "\f595";
}

.fa-joomla:before {
  content: "\f1aa";
}

.fa-journal-whills:before {
  content: "\f66a";
}

.fa-js:before {
  content: "\f3b8";
}

.fa-js-square:before {
  content: "\f3b9";
}

.fa-jsfiddle:before {
  content: "\f1cc";
}

.fa-kaaba:before {
  content: "\f66b";
}

.fa-kaggle:before {
  content: "\f5fa";
}

.fa-kerning:before {
  content: "\f86f";
}

.fa-key:before {
  content: "\f084";
}

.fa-key-skeleton:before {
  content: "\f6f3";
}

.fa-keybase:before {
  content: "\f4f5";
}

.fa-keyboard:before {
  content: "\f11c";
}

.fa-keycdn:before {
  content: "\f3ba";
}

.fa-keynote:before {
  content: "\f66c";
}

.fa-khanda:before {
  content: "\f66d";
}

.fa-kickstarter:before {
  content: "\f3bb";
}

.fa-kickstarter-k:before {
  content: "\f3bc";
}

.fa-kidneys:before {
  content: "\f5fb";
}

.fa-kiss:before {
  content: "\f596";
}

.fa-kiss-beam:before {
  content: "\f597";
}

.fa-kiss-wink-heart:before {
  content: "\f598";
}

.fa-kite:before {
  content: "\f6f4";
}

.fa-kiwi-bird:before {
  content: "\f535";
}

.fa-knife-kitchen:before {
  content: "\f6f5";
}

.fa-korvue:before {
  content: "\f42f";
}

.fa-lambda:before {
  content: "\f66e";
}

.fa-lamp:before {
  content: "\f4ca";
}

.fa-landmark:before {
  content: "\f66f";
}

.fa-landmark-alt:before {
  content: "\f752";
}

.fa-language:before {
  content: "\f1ab";
}

.fa-laptop:before {
  content: "\f109";
}

.fa-laptop-code:before {
  content: "\f5fc";
}

.fa-laptop-medical:before {
  content: "\f812";
}

.fa-laravel:before {
  content: "\f3bd";
}

.fa-lastfm:before {
  content: "\f202";
}

.fa-lastfm-square:before {
  content: "\f203";
}

.fa-laugh:before {
  content: "\f599";
}

.fa-laugh-beam:before {
  content: "\f59a";
}

.fa-laugh-squint:before {
  content: "\f59b";
}

.fa-laugh-wink:before {
  content: "\f59c";
}

.fa-layer-group:before {
  content: "\f5fd";
}

.fa-layer-minus:before {
  content: "\f5fe";
}

.fa-layer-plus:before {
  content: "\f5ff";
}

.fa-leaf:before {
  content: "\f06c";
}

.fa-leaf-heart:before {
  content: "\f4cb";
}

.fa-leaf-maple:before {
  content: "\f6f6";
}

.fa-leaf-oak:before {
  content: "\f6f7";
}

.fa-leanpub:before {
  content: "\f212";
}

.fa-lemon:before {
  content: "\f094";
}

.fa-less:before {
  content: "\f41d";
}

.fa-less-than:before {
  content: "\f536";
}

.fa-less-than-equal:before {
  content: "\f537";
}

.fa-level-down:before {
  content: "\f149";
}

.fa-level-down-alt:before {
  content: "\f3be";
}

.fa-level-up:before {
  content: "\f148";
}

.fa-level-up-alt:before {
  content: "\f3bf";
}

.fa-life-ring:before {
  content: "\f1cd";
}

.fa-lightbulb:before {
  content: "\f0eb";
}

.fa-lightbulb-dollar:before {
  content: "\f670";
}

.fa-lightbulb-exclamation:before {
  content: "\f671";
}

.fa-lightbulb-on:before {
  content: "\f672";
}

.fa-lightbulb-slash:before {
  content: "\f673";
}

.fa-lights-holiday:before {
  content: "\f7b2";
}

.fa-line:before {
  content: "\f3c0";
}

.fa-line-columns:before {
  content: "\f870";
}

.fa-line-height:before {
  content: "\f871";
}

.fa-link:before {
  content: "\f0c1";
}

.fa-linkedin:before {
  content: "\f08c";
}

.fa-linkedin-in:before {
  content: "\f0e1";
}

.fa-linode:before {
  content: "\f2b8";
}

.fa-linux:before {
  content: "\f17c";
}

.fa-lips:before {
  content: "\f600";
}

.fa-lira-sign:before {
  content: "\f195";
}

.fa-list:before {
  content: "\f03a";
}

.fa-list-alt:before {
  content: "\f022";
}

.fa-list-ol:before {
  content: "\f0cb";
}

.fa-list-ul:before {
  content: "\f0ca";
}

.fa-location:before {
  content: "\f601";
}

.fa-location-arrow:before {
  content: "\f124";
}

.fa-location-circle:before {
  content: "\f602";
}

.fa-location-slash:before {
  content: "\f603";
}

.fa-lock:before {
  content: "\f023";
}

.fa-lock-alt:before {
  content: "\f30d";
}

.fa-lock-open:before {
  content: "\f3c1";
}

.fa-lock-open-alt:before {
  content: "\f3c2";
}

.fa-long-arrow-alt-down:before {
  content: "\f309";
}

.fa-long-arrow-alt-left:before {
  content: "\f30a";
}

.fa-long-arrow-alt-right:before {
  content: "\f30b";
}

.fa-long-arrow-alt-up:before {
  content: "\f30c";
}

.fa-long-arrow-down:before {
  content: "\f175";
}

.fa-long-arrow-left:before {
  content: "\f177";
}

.fa-long-arrow-right:before {
  content: "\f178";
}

.fa-long-arrow-up:before {
  content: "\f176";
}

.fa-loveseat:before {
  content: "\f4cc";
}

.fa-low-vision:before {
  content: "\f2a8";
}

.fa-luchador:before {
  content: "\f455";
}

.fa-luggage-cart:before {
  content: "\f59d";
}

.fa-lungs:before {
  content: "\f604";
}

.fa-lyft:before {
  content: "\f3c3";
}

.fa-mace:before {
  content: "\f6f8";
}

.fa-magento:before {
  content: "\f3c4";
}

.fa-magic:before {
  content: "\f0d0";
}

.fa-magnet:before {
  content: "\f076";
}

.fa-mail-bulk:before {
  content: "\f674";
}

.fa-mailbox:before {
  content: "\f813";
}

.fa-mailchimp:before {
  content: "\f59e";
}

.fa-male:before {
  content: "\f183";
}

.fa-mandalorian:before {
  content: "\f50f";
}

.fa-mandolin:before {
  content: "\f6f9";
}

.fa-map:before {
  content: "\f279";
}

.fa-map-marked:before {
  content: "\f59f";
}

.fa-map-marked-alt:before {
  content: "\f5a0";
}

.fa-map-marker:before {
  content: "\f041";
}

.fa-map-marker-alt:before {
  content: "\f3c5";
}

.fa-map-marker-alt-slash:before {
  content: "\f605";
}

.fa-map-marker-check:before {
  content: "\f606";
}

.fa-map-marker-edit:before {
  content: "\f607";
}

.fa-map-marker-exclamation:before {
  content: "\f608";
}

.fa-map-marker-minus:before {
  content: "\f609";
}

.fa-map-marker-plus:before {
  content: "\f60a";
}

.fa-map-marker-question:before {
  content: "\f60b";
}

.fa-map-marker-slash:before {
  content: "\f60c";
}

.fa-map-marker-smile:before {
  content: "\f60d";
}

.fa-map-marker-times:before {
  content: "\f60e";
}

.fa-map-pin:before {
  content: "\f276";
}

.fa-map-signs:before {
  content: "\f277";
}

.fa-markdown:before {
  content: "\f60f";
}

.fa-marker:before {
  content: "\f5a1";
}

.fa-mars:before {
  content: "\f222";
}

.fa-mars-double:before {
  content: "\f227";
}

.fa-mars-stroke:before {
  content: "\f229";
}

.fa-mars-stroke-h:before {
  content: "\f22b";
}

.fa-mars-stroke-v:before {
  content: "\f22a";
}

.fa-mask:before {
  content: "\f6fa";
}

.fa-mastodon:before {
  content: "\f4f6";
}

.fa-maxcdn:before {
  content: "\f136";
}

.fa-meat:before {
  content: "\f814";
}

.fa-medal:before {
  content: "\f5a2";
}

.fa-medapps:before {
  content: "\f3c6";
}

.fa-medium:before {
  content: "\f23a";
}

.fa-medium-m:before {
  content: "\f3c7";
}

.fa-medkit:before {
  content: "\f0fa";
}

.fa-medrt:before {
  content: "\f3c8";
}

.fa-meetup:before {
  content: "\f2e0";
}

.fa-megaphone:before {
  content: "\f675";
}

.fa-megaport:before {
  content: "\f5a3";
}

.fa-meh:before {
  content: "\f11a";
}

.fa-meh-blank:before {
  content: "\f5a4";
}

.fa-meh-rolling-eyes:before {
  content: "\f5a5";
}

.fa-memory:before {
  content: "\f538";
}

.fa-mendeley:before {
  content: "\f7b3";
}

.fa-menorah:before {
  content: "\f676";
}

.fa-mercury:before {
  content: "\f223";
}

.fa-meteor:before {
  content: "\f753";
}

.fa-microchip:before {
  content: "\f2db";
}

.fa-microphone:before {
  content: "\f130";
}

.fa-microphone-alt:before {
  content: "\f3c9";
}

.fa-microphone-alt-slash:before {
  content: "\f539";
}

.fa-microphone-slash:before {
  content: "\f131";
}

.fa-microscope:before {
  content: "\f610";
}

.fa-microsoft:before {
  content: "\f3ca";
}

.fa-mind-share:before {
  content: "\f677";
}

.fa-minus:before {
  content: "\f068";
}

.fa-minus-circle:before {
  content: "\f056";
}

.fa-minus-hexagon:before {
  content: "\f307";
}

.fa-minus-octagon:before {
  content: "\f308";
}

.fa-minus-square:before {
  content: "\f146";
}

.fa-mistletoe:before {
  content: "\f7b4";
}

.fa-mitten:before {
  content: "\f7b5";
}

.fa-mix:before {
  content: "\f3cb";
}

.fa-mixcloud:before {
  content: "\f289";
}

.fa-mizuni:before {
  content: "\f3cc";
}

.fa-mobile:before {
  content: "\f10b";
}

.fa-mobile-alt:before {
  content: "\f3cd";
}

.fa-mobile-android:before {
  content: "\f3ce";
}

.fa-mobile-android-alt:before {
  content: "\f3cf";
}

.fa-modx:before {
  content: "\f285";
}

.fa-monero:before {
  content: "\f3d0";
}

.fa-money-bill:before {
  content: "\f0d6";
}

.fa-money-bill-alt:before {
  content: "\f3d1";
}

.fa-money-bill-wave:before {
  content: "\f53a";
}

.fa-money-bill-wave-alt:before {
  content: "\f53b";
}

.fa-money-check:before {
  content: "\f53c";
}

.fa-money-check-alt:before {
  content: "\f53d";
}

.fa-money-check-edit:before {
  content: "\f872";
}

.fa-money-check-edit-alt:before {
  content: "\f873";
}

.fa-monitor-heart-rate:before {
  content: "\f611";
}

.fa-monkey:before {
  content: "\f6fb";
}

.fa-monument:before {
  content: "\f5a6";
}

.fa-moon:before {
  content: "\f186";
}

.fa-moon-cloud:before {
  content: "\f754";
}

.fa-moon-stars:before {
  content: "\f755";
}

.fa-mortar-pestle:before {
  content: "\f5a7";
}

.fa-mosque:before {
  content: "\f678";
}

.fa-motorcycle:before {
  content: "\f21c";
}

.fa-mountain:before {
  content: "\f6fc";
}

.fa-mountains:before {
  content: "\f6fd";
}

.fa-mouse-pointer:before {
  content: "\f245";
}

.fa-mug:before {
  content: "\f874";
}

.fa-mug-hot:before {
  content: "\f7b6";
}

.fa-mug-marshmallows:before {
  content: "\f7b7";
}

.fa-mug-tea:before {
  content: "\f875";
}

.fa-music:before {
  content: "\f001";
}

.fa-napster:before {
  content: "\f3d2";
}

.fa-narwhal:before {
  content: "\f6fe";
}

.fa-neos:before {
  content: "\f612";
}

.fa-network-wired:before {
  content: "\f6ff";
}

.fa-neuter:before {
  content: "\f22c";
}

.fa-newspaper:before {
  content: "\f1ea";
}

.fa-nimblr:before {
  content: "\f5a8";
}

.fa-node:before {
  content: "\f419";
}

.fa-node-js:before {
  content: "\f3d3";
}

.fa-not-equal:before {
  content: "\f53e";
}

.fa-notes-medical:before {
  content: "\f481";
}

.fa-npm:before {
  content: "\f3d4";
}

.fa-ns8:before {
  content: "\f3d5";
}

.fa-nutritionix:before {
  content: "\f3d6";
}

.fa-object-group:before {
  content: "\f247";
}

.fa-object-ungroup:before {
  content: "\f248";
}

.fa-octagon:before {
  content: "\f306";
}

.fa-odnoklassniki:before {
  content: "\f263";
}

.fa-odnoklassniki-square:before {
  content: "\f264";
}

.fa-oil-can:before {
  content: "\f613";
}

.fa-oil-temp:before {
  content: "\f614";
}

.fa-old-republic:before {
  content: "\f510";
}

.fa-om:before {
  content: "\f679";
}

.fa-omega:before {
  content: "\f67a";
}

.fa-opencart:before {
  content: "\f23d";
}

.fa-openid:before {
  content: "\f19b";
}

.fa-opera:before {
  content: "\f26a";
}

.fa-optin-monster:before {
  content: "\f23c";
}

.fa-ornament:before {
  content: "\f7b8";
}

.fa-osi:before {
  content: "\f41a";
}

.fa-otter:before {
  content: "\f700";
}

.fa-outdent:before {
  content: "\f03b";
}

.fa-overline:before {
  content: "\f876";
}

.fa-page-break:before {
  content: "\f877";
}

.fa-page4:before {
  content: "\f3d7";
}

.fa-pagelines:before {
  content: "\f18c";
}

.fa-pager:before {
  content: "\f815";
}

.fa-paint-brush:before {
  content: "\f1fc";
}

.fa-paint-brush-alt:before {
  content: "\f5a9";
}

.fa-paint-roller:before {
  content: "\f5aa";
}

.fa-palette:before {
  content: "\f53f";
}

.fa-palfed:before {
  content: "\f3d8";
}

.fa-pallet:before {
  content: "\f482";
}

.fa-pallet-alt:before {
  content: "\f483";
}

.fa-paper-plane:before {
  content: "\f1d8";
}

.fa-paperclip:before {
  content: "\f0c6";
}

.fa-parachute-box:before {
  content: "\f4cd";
}

.fa-paragraph:before {
  content: "\f1dd";
}

.fa-paragraph-rtl:before {
  content: "\f878";
}

.fa-parking:before {
  content: "\f540";
}

.fa-parking-circle:before {
  content: "\f615";
}

.fa-parking-circle-slash:before {
  content: "\f616";
}

.fa-parking-slash:before {
  content: "\f617";
}

.fa-passport:before {
  content: "\f5ab";
}

.fa-pastafarianism:before {
  content: "\f67b";
}

.fa-paste:before {
  content: "\f0ea";
}

.fa-patreon:before {
  content: "\f3d9";
}

.fa-pause:before {
  content: "\f04c";
}

.fa-pause-circle:before {
  content: "\f28b";
}

.fa-paw:before {
  content: "\f1b0";
}

.fa-paw-alt:before {
  content: "\f701";
}

.fa-paw-claws:before {
  content: "\f702";
}

.fa-paypal:before {
  content: "\f1ed";
}

.fa-peace:before {
  content: "\f67c";
}

.fa-pegasus:before {
  content: "\f703";
}

.fa-pen:before {
  content: "\f304";
}

.fa-pen-alt:before {
  content: "\f305";
}

.fa-pen-fancy:before {
  content: "\f5ac";
}

.fa-pen-nib:before {
  content: "\f5ad";
}

.fa-pen-square:before {
  content: "\f14b";
}

.fa-pencil:before {
  content: "\f040";
}

.fa-pencil-alt:before {
  content: "\f303";
}

.fa-pencil-paintbrush:before {
  content: "\f618";
}

.fa-pencil-ruler:before {
  content: "\f5ae";
}

.fa-pennant:before {
  content: "\f456";
}

.fa-penny-arcade:before {
  content: "\f704";
}

.fa-people-carry:before {
  content: "\f4ce";
}

.fa-pepper-hot:before {
  content: "\f816";
}

.fa-percent:before {
  content: "\f295";
}

.fa-percentage:before {
  content: "\f541";
}

.fa-periscope:before {
  content: "\f3da";
}

.fa-person-booth:before {
  content: "\f756";
}

.fa-person-carry:before {
  content: "\f4cf";
}

.fa-person-dolly:before {
  content: "\f4d0";
}

.fa-person-dolly-empty:before {
  content: "\f4d1";
}

.fa-person-sign:before {
  content: "\f757";
}

.fa-phabricator:before {
  content: "\f3db";
}

.fa-phoenix-framework:before {
  content: "\f3dc";
}

.fa-phoenix-squadron:before {
  content: "\f511";
}

.fa-phone:before {
  content: "\f095";
}

.fa-phone-alt:before {
  content: "\f879";
}

.fa-phone-laptop:before {
  content: "\f87a";
}

.fa-phone-office:before {
  content: "\f67d";
}

.fa-phone-plus:before {
  content: "\f4d2";
}

.fa-phone-slash:before {
  content: "\f3dd";
}

.fa-phone-square:before {
  content: "\f098";
}

.fa-phone-square-alt:before {
  content: "\f87b";
}

.fa-phone-volume:before {
  content: "\f2a0";
}

.fa-photo-video:before {
  content: "\f87c";
}

.fa-php:before {
  content: "\f457";
}

.fa-pi:before {
  content: "\f67e";
}

.fa-pie:before {
  content: "\f705";
}

.fa-pied-piper:before {
  content: "\f2ae";
}

.fa-pied-piper-alt:before {
  content: "\f1a8";
}

.fa-pied-piper-hat:before {
  content: "\f4e5";
}

.fa-pied-piper-pp:before {
  content: "\f1a7";
}

.fa-pig:before {
  content: "\f706";
}

.fa-piggy-bank:before {
  content: "\f4d3";
}

.fa-pills:before {
  content: "\f484";
}

.fa-pinterest:before {
  content: "\f0d2";
}

.fa-pinterest-p:before {
  content: "\f231";
}

.fa-pinterest-square:before {
  content: "\f0d3";
}

.fa-pizza:before {
  content: "\f817";
}

.fa-pizza-slice:before {
  content: "\f818";
}

.fa-place-of-worship:before {
  content: "\f67f";
}

.fa-plane:before {
  content: "\f072";
}

.fa-plane-alt:before {
  content: "\f3de";
}

.fa-plane-arrival:before {
  content: "\f5af";
}

.fa-plane-departure:before {
  content: "\f5b0";
}

.fa-play:before {
  content: "\f04b";
}

.fa-play-circle:before {
  content: "\f144";
}

.fa-playstation:before {
  content: "\f3df";
}

.fa-plug:before {
  content: "\f1e6";
}

.fa-plus:before {
  content: "\f067";
}

.fa-plus-circle:before {
  content: "\f055";
}

.fa-plus-hexagon:before {
  content: "\f300";
}

.fa-plus-octagon:before {
  content: "\f301";
}

.fa-plus-square:before {
  content: "\f0fe";
}

.fa-podcast:before {
  content: "\f2ce";
}

.fa-podium:before {
  content: "\f680";
}

.fa-podium-star:before {
  content: "\f758";
}

.fa-poll:before {
  content: "\f681";
}

.fa-poll-h:before {
  content: "\f682";
}

.fa-poll-people:before {
  content: "\f759";
}

.fa-poo:before {
  content: "\f2fe";
}

.fa-poo-storm:before {
  content: "\f75a";
}

.fa-poop:before {
  content: "\f619";
}

.fa-popcorn:before {
  content: "\f819";
}

.fa-portrait:before {
  content: "\f3e0";
}

.fa-pound-sign:before {
  content: "\f154";
}

.fa-power-off:before {
  content: "\f011";
}

.fa-pray:before {
  content: "\f683";
}

.fa-praying-hands:before {
  content: "\f684";
}

.fa-prescription:before {
  content: "\f5b1";
}

.fa-prescription-bottle:before {
  content: "\f485";
}

.fa-prescription-bottle-alt:before {
  content: "\f486";
}

.fa-presentation:before {
  content: "\f685";
}

.fa-print:before {
  content: "\f02f";
}

.fa-print-search:before {
  content: "\f81a";
}

.fa-print-slash:before {
  content: "\f686";
}

.fa-procedures:before {
  content: "\f487";
}

.fa-product-hunt:before {
  content: "\f288";
}

.fa-project-diagram:before {
  content: "\f542";
}

.fa-pumpkin:before {
  content: "\f707";
}

.fa-pushed:before {
  content: "\f3e1";
}

.fa-puzzle-piece:before {
  content: "\f12e";
}

.fa-python:before {
  content: "\f3e2";
}

.fa-qq:before {
  content: "\f1d6";
}

.fa-qrcode:before {
  content: "\f029";
}

.fa-question:before {
  content: "\f128";
}

.fa-question-circle:before {
  content: "\f059";
}

.fa-question-square:before {
  content: "\f2fd";
}

.fa-quidditch:before {
  content: "\f458";
}

.fa-quinscape:before {
  content: "\f459";
}

.fa-quora:before {
  content: "\f2c4";
}

.fa-quote-left:before {
  content: "\f10d";
}

.fa-quote-right:before {
  content: "\f10e";
}

.fa-quran:before {
  content: "\f687";
}

.fa-r-project:before {
  content: "\f4f7";
}

.fa-rabbit:before {
  content: "\f708";
}

.fa-rabbit-fast:before {
  content: "\f709";
}

.fa-racquet:before {
  content: "\f45a";
}

.fa-radiation:before {
  content: "\f7b9";
}

.fa-radiation-alt:before {
  content: "\f7ba";
}

.fa-rainbow:before {
  content: "\f75b";
}

.fa-raindrops:before {
  content: "\f75c";
}

.fa-ram:before {
  content: "\f70a";
}

.fa-ramp-loading:before {
  content: "\f4d4";
}

.fa-random:before {
  content: "\f074";
}

.fa-raspberry-pi:before {
  content: "\f7bb";
}

.fa-ravelry:before {
  content: "\f2d9";
}

.fa-react:before {
  content: "\f41b";
}

.fa-reacteurope:before {
  content: "\f75d";
}

.fa-readme:before {
  content: "\f4d5";
}

.fa-rebel:before {
  content: "\f1d0";
}

.fa-receipt:before {
  content: "\f543";
}

.fa-rectangle-landscape:before {
  content: "\f2fa";
}

.fa-rectangle-portrait:before {
  content: "\f2fb";
}

.fa-rectangle-wide:before {
  content: "\f2fc";
}

.fa-recycle:before {
  content: "\f1b8";
}

.fa-red-river:before {
  content: "\f3e3";
}

.fa-reddit:before {
  content: "\f1a1";
}

.fa-reddit-alien:before {
  content: "\f281";
}

.fa-reddit-square:before {
  content: "\f1a2";
}

.fa-redhat:before {
  content: "\f7bc";
}

.fa-redo:before {
  content: "\f01e";
}

.fa-redo-alt:before {
  content: "\f2f9";
}

.fa-registered:before {
  content: "\f25d";
}

.fa-remove-format:before {
  content: "\f87d";
}

.fa-renren:before {
  content: "\f18b";
}

.fa-repeat:before {
  content: "\f363";
}

.fa-repeat-1:before {
  content: "\f365";
}

.fa-repeat-1-alt:before {
  content: "\f366";
}

.fa-repeat-alt:before {
  content: "\f364";
}

.fa-reply:before {
  content: "\f3e5";
}

.fa-reply-all:before {
  content: "\f122";
}

.fa-replyd:before {
  content: "\f3e6";
}

.fa-republican:before {
  content: "\f75e";
}

.fa-researchgate:before {
  content: "\f4f8";
}

.fa-resolving:before {
  content: "\f3e7";
}

.fa-restroom:before {
  content: "\f7bd";
}

.fa-retweet:before {
  content: "\f079";
}

.fa-retweet-alt:before {
  content: "\f361";
}

.fa-rev:before {
  content: "\f5b2";
}

.fa-ribbon:before {
  content: "\f4d6";
}

.fa-ring:before {
  content: "\f70b";
}

.fa-rings-wedding:before {
  content: "\f81b";
}

.fa-road:before {
  content: "\f018";
}

.fa-robot:before {
  content: "\f544";
}

.fa-rocket:before {
  content: "\f135";
}

.fa-rocketchat:before {
  content: "\f3e8";
}

.fa-rockrms:before {
  content: "\f3e9";
}

.fa-route:before {
  content: "\f4d7";
}

.fa-route-highway:before {
  content: "\f61a";
}

.fa-route-interstate:before {
  content: "\f61b";
}

.fa-rss:before {
  content: "\f09e";
}

.fa-rss-square:before {
  content: "\f143";
}

.fa-ruble-sign:before {
  content: "\f158";
}

.fa-ruler:before {
  content: "\f545";
}

.fa-ruler-combined:before {
  content: "\f546";
}

.fa-ruler-horizontal:before {
  content: "\f547";
}

.fa-ruler-triangle:before {
  content: "\f61c";
}

.fa-ruler-vertical:before {
  content: "\f548";
}

.fa-running:before {
  content: "\f70c";
}

.fa-rupee-sign:before {
  content: "\f156";
}

.fa-rv:before {
  content: "\f7be";
}

.fa-sack:before {
  content: "\f81c";
}

.fa-sack-dollar:before {
  content: "\f81d";
}

.fa-sad-cry:before {
  content: "\f5b3";
}

.fa-sad-tear:before {
  content: "\f5b4";
}

.fa-safari:before {
  content: "\f267";
}

.fa-salad:before {
  content: "\f81e";
}

.fa-salesforce:before {
  content: "\f83b";
}

.fa-sandwich:before {
  content: "\f81f";
}

.fa-sass:before {
  content: "\f41e";
}

.fa-satellite:before {
  content: "\f7bf";
}

.fa-satellite-dish:before {
  content: "\f7c0";
}

.fa-sausage:before {
  content: "\f820";
}

.fa-save:before {
  content: "\f0c7";
}

.fa-scalpel:before {
  content: "\f61d";
}

.fa-scalpel-path:before {
  content: "\f61e";
}

.fa-scanner:before {
  content: "\f488";
}

.fa-scanner-keyboard:before {
  content: "\f489";
}

.fa-scanner-touchscreen:before {
  content: "\f48a";
}

.fa-scarecrow:before {
  content: "\f70d";
}

.fa-scarf:before {
  content: "\f7c1";
}

.fa-schlix:before {
  content: "\f3ea";
}

.fa-school:before {
  content: "\f549";
}

.fa-screwdriver:before {
  content: "\f54a";
}

.fa-scribd:before {
  content: "\f28a";
}

.fa-scroll:before {
  content: "\f70e";
}

.fa-scroll-old:before {
  content: "\f70f";
}

.fa-scrubber:before {
  content: "\f2f8";
}

.fa-scythe:before {
  content: "\f710";
}

.fa-sd-card:before {
  content: "\f7c2";
}

.fa-search:before {
  content: "\f002";
}

.fa-search-dollar:before {
  content: "\f688";
}

.fa-search-location:before {
  content: "\f689";
}

.fa-search-minus:before {
  content: "\f010";
}

.fa-search-plus:before {
  content: "\f00e";
}

.fa-searchengin:before {
  content: "\f3eb";
}

.fa-seedling:before {
  content: "\f4d8";
}

.fa-sellcast:before {
  content: "\f2da";
}

.fa-sellsy:before {
  content: "\f213";
}

.fa-send-back:before {
  content: "\f87e";
}

.fa-send-backward:before {
  content: "\f87f";
}

.fa-server:before {
  content: "\f233";
}

.fa-servicestack:before {
  content: "\f3ec";
}

.fa-shapes:before {
  content: "\f61f";
}

.fa-share:before {
  content: "\f064";
}

.fa-share-all:before {
  content: "\f367";
}

.fa-share-alt:before {
  content: "\f1e0";
}

.fa-share-alt-square:before {
  content: "\f1e1";
}

.fa-share-square:before {
  content: "\f14d";
}

.fa-sheep:before {
  content: "\f711";
}

.fa-shekel-sign:before {
  content: "\f20b";
}

.fa-shield:before {
  content: "\f132";
}

.fa-shield-alt:before {
  content: "\f3ed";
}

.fa-shield-check:before {
  content: "\f2f7";
}

.fa-shield-cross:before {
  content: "\f712";
}

.fa-ship:before {
  content: "\f21a";
}

.fa-shipping-fast:before {
  content: "\f48b";
}

.fa-shipping-timed:before {
  content: "\f48c";
}

.fa-shirtsinbulk:before {
  content: "\f214";
}

.fa-shish-kebab:before {
  content: "\f821";
}

.fa-shoe-prints:before {
  content: "\f54b";
}

.fa-shopping-bag:before {
  content: "\f290";
}

.fa-shopping-basket:before {
  content: "\f291";
}

.fa-shopping-cart:before {
  content: "\f07a";
}

.fa-shopware:before {
  content: "\f5b5";
}

.fa-shovel:before {
  content: "\f713";
}

.fa-shovel-snow:before {
  content: "\f7c3";
}

.fa-shower:before {
  content: "\f2cc";
}

.fa-shredder:before {
  content: "\f68a";
}

.fa-shuttle-van:before {
  content: "\f5b6";
}

.fa-shuttlecock:before {
  content: "\f45b";
}

.fa-sickle:before {
  content: "\f822";
}

.fa-sigma:before {
  content: "\f68b";
}

.fa-sign:before {
  content: "\f4d9";
}

.fa-sign-in:before {
  content: "\f090";
}

.fa-sign-in-alt:before {
  content: "\f2f6";
}

.fa-sign-language:before {
  content: "\f2a7";
}

.fa-sign-out:before {
  content: "\f08b";
}

.fa-sign-out-alt:before {
  content: "\f2f5";
}

.fa-signal:before {
  content: "\f012";
}

.fa-signal-1:before {
  content: "\f68c";
}

.fa-signal-2:before {
  content: "\f68d";
}

.fa-signal-3:before {
  content: "\f68e";
}

.fa-signal-4:before {
  content: "\f68f";
}

.fa-signal-alt:before {
  content: "\f690";
}

.fa-signal-alt-1:before {
  content: "\f691";
}

.fa-signal-alt-2:before {
  content: "\f692";
}

.fa-signal-alt-3:before {
  content: "\f693";
}

.fa-signal-alt-slash:before {
  content: "\f694";
}

.fa-signal-slash:before {
  content: "\f695";
}

.fa-signature:before {
  content: "\f5b7";
}

.fa-sim-card:before {
  content: "\f7c4";
}

.fa-simplybuilt:before {
  content: "\f215";
}

.fa-sistrix:before {
  content: "\f3ee";
}

.fa-sitemap:before {
  content: "\f0e8";
}

.fa-sith:before {
  content: "\f512";
}

.fa-skating:before {
  content: "\f7c5";
}

.fa-skeleton:before {
  content: "\f620";
}

.fa-sketch:before {
  content: "\f7c6";
}

.fa-ski-jump:before {
  content: "\f7c7";
}

.fa-ski-lift:before {
  content: "\f7c8";
}

.fa-skiing:before {
  content: "\f7c9";
}

.fa-skiing-nordic:before {
  content: "\f7ca";
}

.fa-skull:before {
  content: "\f54c";
}

.fa-skull-crossbones:before {
  content: "\f714";
}

.fa-skyatlas:before {
  content: "\f216";
}

.fa-skype:before {
  content: "\f17e";
}

.fa-slack:before {
  content: "\f198";
}

.fa-slack-hash:before {
  content: "\f3ef";
}

.fa-slash:before {
  content: "\f715";
}

.fa-sledding:before {
  content: "\f7cb";
}

.fa-sleigh:before {
  content: "\f7cc";
}

.fa-sliders-h:before {
  content: "\f1de";
}

.fa-sliders-h-square:before {
  content: "\f3f0";
}

.fa-sliders-v:before {
  content: "\f3f1";
}

.fa-sliders-v-square:before {
  content: "\f3f2";
}

.fa-slideshare:before {
  content: "\f1e7";
}

.fa-smile:before {
  content: "\f118";
}

.fa-smile-beam:before {
  content: "\f5b8";
}

.fa-smile-plus:before {
  content: "\f5b9";
}

.fa-smile-wink:before {
  content: "\f4da";
}

.fa-smog:before {
  content: "\f75f";
}

.fa-smoke:before {
  content: "\f760";
}

.fa-smoking:before {
  content: "\f48d";
}

.fa-smoking-ban:before {
  content: "\f54d";
}

.fa-sms:before {
  content: "\f7cd";
}

.fa-snake:before {
  content: "\f716";
}

.fa-snapchat:before {
  content: "\f2ab";
}

.fa-snapchat-ghost:before {
  content: "\f2ac";
}

.fa-snapchat-square:before {
  content: "\f2ad";
}

.fa-snooze:before {
  content: "\f880";
}

.fa-snow-blowing:before {
  content: "\f761";
}

.fa-snowboarding:before {
  content: "\f7ce";
}

.fa-snowflake:before {
  content: "\f2dc";
}

.fa-snowflakes:before {
  content: "\f7cf";
}

.fa-snowman:before {
  content: "\f7d0";
}

.fa-snowmobile:before {
  content: "\f7d1";
}

.fa-snowplow:before {
  content: "\f7d2";
}

.fa-socks:before {
  content: "\f696";
}

.fa-solar-panel:before {
  content: "\f5ba";
}

.fa-sort:before {
  content: "\f0dc";
}

.fa-sort-alpha-down:before {
  content: "\f15d";
}

.fa-sort-alpha-down-alt:before {
  content: "\f881";
}

.fa-sort-alpha-up:before {
  content: "\f15e";
}

.fa-sort-alpha-up-alt:before {
  content: "\f882";
}

.fa-sort-alt:before {
  content: "\f883";
}

.fa-sort-amount-down:before {
  content: "\f160";
}

.fa-sort-amount-down-alt:before {
  content: "\f884";
}

.fa-sort-amount-up:before {
  content: "\f161";
}

.fa-sort-amount-up-alt:before {
  content: "\f885";
}

.fa-sort-down:before {
  content: "\f0dd";
}

.fa-sort-numeric-down:before {
  content: "\f162";
}

.fa-sort-numeric-down-alt:before {
  content: "\f886";
}

.fa-sort-numeric-up:before {
  content: "\f163";
}

.fa-sort-numeric-up-alt:before {
  content: "\f887";
}

.fa-sort-shapes-down:before {
  content: "\f888";
}

.fa-sort-shapes-down-alt:before {
  content: "\f889";
}

.fa-sort-shapes-up:before {
  content: "\f88a";
}

.fa-sort-shapes-up-alt:before {
  content: "\f88b";
}

.fa-sort-size-down:before {
  content: "\f88c";
}

.fa-sort-size-down-alt:before {
  content: "\f88d";
}

.fa-sort-size-up:before {
  content: "\f88e";
}

.fa-sort-size-up-alt:before {
  content: "\f88f";
}

.fa-sort-up:before {
  content: "\f0de";
}

.fa-soundcloud:before {
  content: "\f1be";
}

.fa-soup:before {
  content: "\f823";
}

.fa-sourcetree:before {
  content: "\f7d3";
}

.fa-spa:before {
  content: "\f5bb";
}

.fa-space-shuttle:before {
  content: "\f197";
}

.fa-spade:before {
  content: "\f2f4";
}

.fa-sparkles:before {
  content: "\f890";
}

.fa-speakap:before {
  content: "\f3f3";
}

.fa-speaker-deck:before {
  content: "\f83c";
}

.fa-spell-check:before {
  content: "\f891";
}

.fa-spider:before {
  content: "\f717";
}

.fa-spider-black-widow:before {
  content: "\f718";
}

.fa-spider-web:before {
  content: "\f719";
}

.fa-spinner:before {
  content: "\f110";
}

.fa-spinner-third:before {
  content: "\f3f4";
}

.fa-splotch:before {
  content: "\f5bc";
}

.fa-spotify:before {
  content: "\f1bc";
}

.fa-spray-can:before {
  content: "\f5bd";
}

.fa-square:before {
  content: "\f0c8";
}

.fa-square-full:before {
  content: "\f45c";
}

.fa-square-root:before {
  content: "\f697";
}

.fa-square-root-alt:before {
  content: "\f698";
}

.fa-squarespace:before {
  content: "\f5be";
}

.fa-squirrel:before {
  content: "\f71a";
}

.fa-stack-exchange:before {
  content: "\f18d";
}

.fa-stack-overflow:before {
  content: "\f16c";
}

.fa-stackpath:before {
  content: "\f842";
}

.fa-staff:before {
  content: "\f71b";
}

.fa-stamp:before {
  content: "\f5bf";
}

.fa-star:before {
  content: "\f005";
}

.fa-star-and-crescent:before {
  content: "\f699";
}

.fa-star-christmas:before {
  content: "\f7d4";
}

.fa-star-exclamation:before {
  content: "\f2f3";
}

.fa-star-half:before {
  content: "\f089";
}

.fa-star-half-alt:before {
  content: "\f5c0";
}

.fa-star-of-david:before {
  content: "\f69a";
}

.fa-star-of-life:before {
  content: "\f621";
}

.fa-stars:before {
  content: "\f762";
}

.fa-staylinked:before {
  content: "\f3f5";
}

.fa-steak:before {
  content: "\f824";
}

.fa-steam:before {
  content: "\f1b6";
}

.fa-steam-square:before {
  content: "\f1b7";
}

.fa-steam-symbol:before {
  content: "\f3f6";
}

.fa-steering-wheel:before {
  content: "\f622";
}

.fa-step-backward:before {
  content: "\f048";
}

.fa-step-forward:before {
  content: "\f051";
}

.fa-stethoscope:before {
  content: "\f0f1";
}

.fa-sticker-mule:before {
  content: "\f3f7";
}

.fa-sticky-note:before {
  content: "\f249";
}

.fa-stocking:before {
  content: "\f7d5";
}

.fa-stomach:before {
  content: "\f623";
}

.fa-stop:before {
  content: "\f04d";
}

.fa-stop-circle:before {
  content: "\f28d";
}

.fa-stopwatch:before {
  content: "\f2f2";
}

.fa-store:before {
  content: "\f54e";
}

.fa-store-alt:before {
  content: "\f54f";
}

.fa-strava:before {
  content: "\f428";
}

.fa-stream:before {
  content: "\f550";
}

.fa-street-view:before {
  content: "\f21d";
}

.fa-stretcher:before {
  content: "\f825";
}

.fa-strikethrough:before {
  content: "\f0cc";
}

.fa-stripe:before {
  content: "\f429";
}

.fa-stripe-s:before {
  content: "\f42a";
}

.fa-stroopwafel:before {
  content: "\f551";
}

.fa-studiovinari:before {
  content: "\f3f8";
}

.fa-stumbleupon:before {
  content: "\f1a4";
}

.fa-stumbleupon-circle:before {
  content: "\f1a3";
}

.fa-subscript:before {
  content: "\f12c";
}

.fa-subway:before {
  content: "\f239";
}

.fa-suitcase:before {
  content: "\f0f2";
}

.fa-suitcase-rolling:before {
  content: "\f5c1";
}

.fa-sun:before {
  content: "\f185";
}

.fa-sun-cloud:before {
  content: "\f763";
}

.fa-sun-dust:before {
  content: "\f764";
}

.fa-sun-haze:before {
  content: "\f765";
}

.fa-sunglasses:before {
  content: "\f892";
}

.fa-sunrise:before {
  content: "\f766";
}

.fa-sunset:before {
  content: "\f767";
}

.fa-superpowers:before {
  content: "\f2dd";
}

.fa-superscript:before {
  content: "\f12b";
}

.fa-supple:before {
  content: "\f3f9";
}

.fa-surprise:before {
  content: "\f5c2";
}

.fa-suse:before {
  content: "\f7d6";
}

.fa-swatchbook:before {
  content: "\f5c3";
}

.fa-swimmer:before {
  content: "\f5c4";
}

.fa-swimming-pool:before {
  content: "\f5c5";
}

.fa-sword:before {
  content: "\f71c";
}

.fa-swords:before {
  content: "\f71d";
}

.fa-symfony:before {
  content: "\f83d";
}

.fa-synagogue:before {
  content: "\f69b";
}

.fa-sync:before {
  content: "\f021";
}

.fa-sync-alt:before {
  content: "\f2f1";
}

.fa-syringe:before {
  content: "\f48e";
}

.fa-table:before {
  content: "\f0ce";
}

.fa-table-tennis:before {
  content: "\f45d";
}

.fa-tablet:before {
  content: "\f10a";
}

.fa-tablet-alt:before {
  content: "\f3fa";
}

.fa-tablet-android:before {
  content: "\f3fb";
}

.fa-tablet-android-alt:before {
  content: "\f3fc";
}

.fa-tablet-rugged:before {
  content: "\f48f";
}

.fa-tablets:before {
  content: "\f490";
}

.fa-tachometer:before {
  content: "\f0e4";
}

.fa-tachometer-alt:before {
  content: "\f3fd";
}

.fa-tachometer-alt-average:before {
  content: "\f624";
}

.fa-tachometer-alt-fast:before {
  content: "\f625";
}

.fa-tachometer-alt-fastest:before {
  content: "\f626";
}

.fa-tachometer-alt-slow:before {
  content: "\f627";
}

.fa-tachometer-alt-slowest:before {
  content: "\f628";
}

.fa-tachometer-average:before {
  content: "\f629";
}

.fa-tachometer-fast:before {
  content: "\f62a";
}

.fa-tachometer-fastest:before {
  content: "\f62b";
}

.fa-tachometer-slow:before {
  content: "\f62c";
}

.fa-tachometer-slowest:before {
  content: "\f62d";
}

.fa-taco:before {
  content: "\f826";
}

.fa-tag:before {
  content: "\f02b";
}

.fa-tags:before {
  content: "\f02c";
}

.fa-tally:before {
  content: "\f69c";
}

.fa-tanakh:before {
  content: "\f827";
}

.fa-tape:before {
  content: "\f4db";
}

.fa-tasks:before {
  content: "\f0ae";
}

.fa-tasks-alt:before {
  content: "\f828";
}

.fa-taxi:before {
  content: "\f1ba";
}

.fa-teamspeak:before {
  content: "\f4f9";
}

.fa-teeth:before {
  content: "\f62e";
}

.fa-teeth-open:before {
  content: "\f62f";
}

.fa-telegram:before {
  content: "\f2c6";
}

.fa-telegram-plane:before {
  content: "\f3fe";
}

.fa-temperature-frigid:before {
  content: "\f768";
}

.fa-temperature-high:before {
  content: "\f769";
}

.fa-temperature-hot:before {
  content: "\f76a";
}

.fa-temperature-low:before {
  content: "\f76b";
}

.fa-tencent-weibo:before {
  content: "\f1d5";
}

.fa-tenge:before {
  content: "\f7d7";
}

.fa-tennis-ball:before {
  content: "\f45e";
}

.fa-terminal:before {
  content: "\f120";
}

.fa-text:before {
  content: "\f893";
}

.fa-text-height:before {
  content: "\f034";
}

.fa-text-size:before {
  content: "\f894";
}

.fa-text-width:before {
  content: "\f035";
}

.fa-th:before {
  content: "\f00a";
}

.fa-th-large:before {
  content: "\f009";
}

.fa-th-list:before {
  content: "\f00b";
}

.fa-the-red-yeti:before {
  content: "\f69d";
}

.fa-theater-masks:before {
  content: "\f630";
}

.fa-themeco:before {
  content: "\f5c6";
}

.fa-themeisle:before {
  content: "\f2b2";
}

.fa-thermometer:before {
  content: "\f491";
}

.fa-thermometer-empty:before {
  content: "\f2cb";
}

.fa-thermometer-full:before {
  content: "\f2c7";
}

.fa-thermometer-half:before {
  content: "\f2c9";
}

.fa-thermometer-quarter:before {
  content: "\f2ca";
}

.fa-thermometer-three-quarters:before {
  content: "\f2c8";
}

.fa-theta:before {
  content: "\f69e";
}

.fa-think-peaks:before {
  content: "\f731";
}

.fa-thumbs-down:before {
  content: "\f165";
}

.fa-thumbs-up:before {
  content: "\f164";
}

.fa-thumbtack:before {
  content: "\f08d";
}

.fa-thunderstorm:before {
  content: "\f76c";
}

.fa-thunderstorm-moon:before {
  content: "\f76d";
}

.fa-thunderstorm-sun:before {
  content: "\f76e";
}

.fa-ticket:before {
  content: "\f145";
}

.fa-ticket-alt:before {
  content: "\f3ff";
}

.fa-tilde:before {
  content: "\f69f";
}

.fa-times:before {
  content: "\f00d";
}

.fa-times-circle:before {
  content: "\f057";
}

.fa-times-hexagon:before {
  content: "\f2ee";
}

.fa-times-octagon:before {
  content: "\f2f0";
}

.fa-times-square:before {
  content: "\f2d3";
}

.fa-tint:before {
  content: "\f043";
}

.fa-tint-slash:before {
  content: "\f5c7";
}

.fa-tire:before {
  content: "\f631";
}

.fa-tire-flat:before {
  content: "\f632";
}

.fa-tire-pressure-warning:before {
  content: "\f633";
}

.fa-tire-rugged:before {
  content: "\f634";
}

.fa-tired:before {
  content: "\f5c8";
}

.fa-toggle-off:before {
  content: "\f204";
}

.fa-toggle-on:before {
  content: "\f205";
}

.fa-toilet:before {
  content: "\f7d8";
}

.fa-toilet-paper:before {
  content: "\f71e";
}

.fa-toilet-paper-alt:before {
  content: "\f71f";
}

.fa-tombstone:before {
  content: "\f720";
}

.fa-tombstone-alt:before {
  content: "\f721";
}

.fa-toolbox:before {
  content: "\f552";
}

.fa-tools:before {
  content: "\f7d9";
}

.fa-tooth:before {
  content: "\f5c9";
}

.fa-toothbrush:before {
  content: "\f635";
}

.fa-torah:before {
  content: "\f6a0";
}

.fa-torii-gate:before {
  content: "\f6a1";
}

.fa-tornado:before {
  content: "\f76f";
}

.fa-tractor:before {
  content: "\f722";
}

.fa-trade-federation:before {
  content: "\f513";
}

.fa-trademark:before {
  content: "\f25c";
}

.fa-traffic-cone:before {
  content: "\f636";
}

.fa-traffic-light:before {
  content: "\f637";
}

.fa-traffic-light-go:before {
  content: "\f638";
}

.fa-traffic-light-slow:before {
  content: "\f639";
}

.fa-traffic-light-stop:before {
  content: "\f63a";
}

.fa-train:before {
  content: "\f238";
}

.fa-tram:before {
  content: "\f7da";
}

.fa-transgender:before {
  content: "\f224";
}

.fa-transgender-alt:before {
  content: "\f225";
}

.fa-trash:before {
  content: "\f1f8";
}

.fa-trash-alt:before {
  content: "\f2ed";
}

.fa-trash-restore:before {
  content: "\f829";
}

.fa-trash-restore-alt:before {
  content: "\f82a";
}

.fa-trash-undo:before {
  content: "\f895";
}

.fa-trash-undo-alt:before {
  content: "\f896";
}

.fa-treasure-chest:before {
  content: "\f723";
}

.fa-tree:before {
  content: "\f1bb";
}

.fa-tree-alt:before {
  content: "\f400";
}

.fa-tree-christmas:before {
  content: "\f7db";
}

.fa-tree-decorated:before {
  content: "\f7dc";
}

.fa-tree-large:before {
  content: "\f7dd";
}

.fa-tree-palm:before {
  content: "\f82b";
}

.fa-trees:before {
  content: "\f724";
}

.fa-trello:before {
  content: "\f181";
}

.fa-triangle:before {
  content: "\f2ec";
}

.fa-tripadvisor:before {
  content: "\f262";
}

.fa-trophy:before {
  content: "\f091";
}

.fa-trophy-alt:before {
  content: "\f2eb";
}

.fa-truck:before {
  content: "\f0d1";
}

.fa-truck-container:before {
  content: "\f4dc";
}

.fa-truck-couch:before {
  content: "\f4dd";
}

.fa-truck-loading:before {
  content: "\f4de";
}

.fa-truck-monster:before {
  content: "\f63b";
}

.fa-truck-moving:before {
  content: "\f4df";
}

.fa-truck-pickup:before {
  content: "\f63c";
}

.fa-truck-plow:before {
  content: "\f7de";
}

.fa-truck-ramp:before {
  content: "\f4e0";
}

.fa-tshirt:before {
  content: "\f553";
}

.fa-tty:before {
  content: "\f1e4";
}

.fa-tumblr:before {
  content: "\f173";
}

.fa-tumblr-square:before {
  content: "\f174";
}

.fa-turkey:before {
  content: "\f725";
}

.fa-turtle:before {
  content: "\f726";
}

.fa-tv:before {
  content: "\f26c";
}

.fa-tv-retro:before {
  content: "\f401";
}

.fa-twitch:before {
  content: "\f1e8";
}

.fa-twitter:before {
  content: "\f099";
}

.fa-twitter-square:before {
  content: "\f081";
}

.fa-typo3:before {
  content: "\f42b";
}

.fa-uber:before {
  content: "\f402";
}

.fa-ubuntu:before {
  content: "\f7df";
}

.fa-uikit:before {
  content: "\f403";
}

.fa-umbrella:before {
  content: "\f0e9";
}

.fa-umbrella-beach:before {
  content: "\f5ca";
}

.fa-underline:before {
  content: "\f0cd";
}

.fa-undo:before {
  content: "\f0e2";
}

.fa-undo-alt:before {
  content: "\f2ea";
}

.fa-unicorn:before {
  content: "\f727";
}

.fa-union:before {
  content: "\f6a2";
}

.fa-uniregistry:before {
  content: "\f404";
}

.fa-universal-access:before {
  content: "\f29a";
}

.fa-university:before {
  content: "\f19c";
}

.fa-unlink:before {
  content: "\f127";
}

.fa-unlock:before {
  content: "\f09c";
}

.fa-unlock-alt:before {
  content: "\f13e";
}

.fa-untappd:before {
  content: "\f405";
}

.fa-upload:before {
  content: "\f093";
}

.fa-ups:before {
  content: "\f7e0";
}

.fa-usb:before {
  content: "\f287";
}

.fa-usd-circle:before {
  content: "\f2e8";
}

.fa-usd-square:before {
  content: "\f2e9";
}

.fa-user:before {
  content: "\f007";
}

.fa-user-alt:before {
  content: "\f406";
}

.fa-user-alt-slash:before {
  content: "\f4fa";
}

.fa-user-astronaut:before {
  content: "\f4fb";
}

.fa-user-chart:before {
  content: "\f6a3";
}

.fa-user-check:before {
  content: "\f4fc";
}

.fa-user-circle:before {
  content: "\f2bd";
}

.fa-user-clock:before {
  content: "\f4fd";
}

.fa-user-cog:before {
  content: "\f4fe";
}

.fa-user-crown:before {
  content: "\f6a4";
}

.fa-user-edit:before {
  content: "\f4ff";
}

.fa-user-friends:before {
  content: "\f500";
}

.fa-user-graduate:before {
  content: "\f501";
}

.fa-user-hard-hat:before {
  content: "\f82c";
}

.fa-user-headset:before {
  content: "\f82d";
}

.fa-user-injured:before {
  content: "\f728";
}

.fa-user-lock:before {
  content: "\f502";
}

.fa-user-md:before {
  content: "\f0f0";
}

.fa-user-md-chat:before {
  content: "\f82e";
}

.fa-user-minus:before {
  content: "\f503";
}

.fa-user-ninja:before {
  content: "\f504";
}

.fa-user-nurse:before {
  content: "\f82f";
}

.fa-user-plus:before {
  content: "\f234";
}

.fa-user-secret:before {
  content: "\f21b";
}

.fa-user-shield:before {
  content: "\f505";
}

.fa-user-slash:before {
  content: "\f506";
}

.fa-user-tag:before {
  content: "\f507";
}

.fa-user-tie:before {
  content: "\f508";
}

.fa-user-times:before {
  content: "\f235";
}

.fa-users:before {
  content: "\f0c0";
}

.fa-users-class:before {
  content: "\f63d";
}

.fa-users-cog:before {
  content: "\f509";
}

.fa-users-crown:before {
  content: "\f6a5";
}

.fa-users-medical:before {
  content: "\f830";
}

.fa-usps:before {
  content: "\f7e1";
}

.fa-ussunnah:before {
  content: "\f407";
}

.fa-utensil-fork:before {
  content: "\f2e3";
}

.fa-utensil-knife:before {
  content: "\f2e4";
}

.fa-utensil-spoon:before {
  content: "\f2e5";
}

.fa-utensils:before {
  content: "\f2e7";
}

.fa-utensils-alt:before {
  content: "\f2e6";
}

.fa-vaadin:before {
  content: "\f408";
}

.fa-value-absolute:before {
  content: "\f6a6";
}

.fa-vector-square:before {
  content: "\f5cb";
}

.fa-venus:before {
  content: "\f221";
}

.fa-venus-double:before {
  content: "\f226";
}

.fa-venus-mars:before {
  content: "\f228";
}

.fa-viacoin:before {
  content: "\f237";
}

.fa-viadeo:before {
  content: "\f2a9";
}

.fa-viadeo-square:before {
  content: "\f2aa";
}

.fa-vial:before {
  content: "\f492";
}

.fa-vials:before {
  content: "\f493";
}

.fa-viber:before {
  content: "\f409";
}

.fa-video:before {
  content: "\f03d";
}

.fa-video-plus:before {
  content: "\f4e1";
}

.fa-video-slash:before {
  content: "\f4e2";
}

.fa-vihara:before {
  content: "\f6a7";
}

.fa-vimeo:before {
  content: "\f40a";
}

.fa-vimeo-square:before {
  content: "\f194";
}

.fa-vimeo-v:before {
  content: "\f27d";
}

.fa-vine:before {
  content: "\f1ca";
}

.fa-vk:before {
  content: "\f189";
}

.fa-vnv:before {
  content: "\f40b";
}

.fa-voicemail:before {
  content: "\f897";
}

.fa-volcano:before {
  content: "\f770";
}

.fa-volleyball-ball:before {
  content: "\f45f";
}

.fa-volume:before {
  content: "\f6a8";
}

.fa-volume-down:before {
  content: "\f027";
}

.fa-volume-mute:before {
  content: "\f6a9";
}

.fa-volume-off:before {
  content: "\f026";
}

.fa-volume-slash:before {
  content: "\f2e2";
}

.fa-volume-up:before {
  content: "\f028";
}

.fa-vote-nay:before {
  content: "\f771";
}

.fa-vote-yea:before {
  content: "\f772";
}

.fa-vr-cardboard:before {
  content: "\f729";
}

.fa-vuejs:before {
  content: "\f41f";
}

.fa-walker:before {
  content: "\f831";
}

.fa-walking:before {
  content: "\f554";
}

.fa-wallet:before {
  content: "\f555";
}

.fa-wand:before {
  content: "\f72a";
}

.fa-wand-magic:before {
  content: "\f72b";
}

.fa-warehouse:before {
  content: "\f494";
}

.fa-warehouse-alt:before {
  content: "\f495";
}

.fa-washer:before {
  content: "\f898";
}

.fa-watch:before {
  content: "\f2e1";
}

.fa-watch-fitness:before {
  content: "\f63e";
}

.fa-water:before {
  content: "\f773";
}

.fa-water-lower:before {
  content: "\f774";
}

.fa-water-rise:before {
  content: "\f775";
}

.fa-wave-sine:before {
  content: "\f899";
}

.fa-wave-square:before {
  content: "\f83e";
}

.fa-wave-triangle:before {
  content: "\f89a";
}

.fa-waze:before {
  content: "\f83f";
}

.fa-webcam:before {
  content: "\f832";
}

.fa-webcam-slash:before {
  content: "\f833";
}

.fa-weebly:before {
  content: "\f5cc";
}

.fa-weibo:before {
  content: "\f18a";
}

.fa-weight:before {
  content: "\f496";
}

.fa-weight-hanging:before {
  content: "\f5cd";
}

.fa-weixin:before {
  content: "\f1d7";
}

.fa-whale:before {
  content: "\f72c";
}

.fa-whatsapp:before {
  content: "\f232";
}

.fa-whatsapp-square:before {
  content: "\f40c";
}

.fa-wheat:before {
  content: "\f72d";
}

.fa-wheelchair:before {
  content: "\f193";
}

.fa-whistle:before {
  content: "\f460";
}

.fa-whmcs:before {
  content: "\f40d";
}

.fa-wifi:before {
  content: "\f1eb";
}

.fa-wifi-1:before {
  content: "\f6aa";
}

.fa-wifi-2:before {
  content: "\f6ab";
}

.fa-wifi-slash:before {
  content: "\f6ac";
}

.fa-wikipedia-w:before {
  content: "\f266";
}

.fa-wind:before {
  content: "\f72e";
}

.fa-wind-turbine:before {
  content: "\f89b";
}

.fa-wind-warning:before {
  content: "\f776";
}

.fa-window:before {
  content: "\f40e";
}

.fa-window-alt:before {
  content: "\f40f";
}

.fa-window-close:before {
  content: "\f410";
}

.fa-window-maximize:before {
  content: "\f2d0";
}

.fa-window-minimize:before {
  content: "\f2d1";
}

.fa-window-restore:before {
  content: "\f2d2";
}

.fa-windows:before {
  content: "\f17a";
}

.fa-windsock:before {
  content: "\f777";
}

.fa-wine-bottle:before {
  content: "\f72f";
}

.fa-wine-glass:before {
  content: "\f4e3";
}

.fa-wine-glass-alt:before {
  content: "\f5ce";
}

.fa-wix:before {
  content: "\f5cf";
}

.fa-wizards-of-the-coast:before {
  content: "\f730";
}

.fa-wolf-pack-battalion:before {
  content: "\f514";
}

.fa-won-sign:before {
  content: "\f159";
}

.fa-wordpress:before {
  content: "\f19a";
}

.fa-wordpress-simple:before {
  content: "\f411";
}

.fa-wpbeginner:before {
  content: "\f297";
}

.fa-wpexplorer:before {
  content: "\f2de";
}

.fa-wpforms:before {
  content: "\f298";
}

.fa-wpressr:before {
  content: "\f3e4";
}

.fa-wreath:before {
  content: "\f7e2";
}

.fa-wrench:before {
  content: "\f0ad";
}

.fa-x-ray:before {
  content: "\f497";
}

.fa-xbox:before {
  content: "\f412";
}

.fa-xing:before {
  content: "\f168";
}

.fa-xing-square:before {
  content: "\f169";
}

.fa-y-combinator:before {
  content: "\f23b";
}

.fa-yahoo:before {
  content: "\f19e";
}

.fa-yammer:before {
  content: "\f840";
}

.fa-yandex:before {
  content: "\f413";
}

.fa-yandex-international:before {
  content: "\f414";
}

.fa-yarn:before {
  content: "\f7e3";
}

.fa-yelp:before {
  content: "\f1e9";
}

.fa-yen-sign:before {
  content: "\f157";
}

.fa-yin-yang:before {
  content: "\f6ad";
}

.fa-yoast:before {
  content: "\f2b1";
}

.fa-youtube:before {
  content: "\f167";
}

.fa-youtube-square:before {
  content: "\f431";
}

.fa-zhihu:before {
  content: "\f63f";
}

.sr-only {
  border: 0;
  clip: rect(0, 0, 0, 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  clip: auto;
  height: auto;
  margin: 0;
  overflow: visible;
  position: static;
  width: auto;
}

/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 400;
  font-display: auto;
  src: url("../fonts/FontAwesome/fa-regular-400.eot");
  src: url("../fonts/FontAwesome/fa-regular-400.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-regular-400.woff2") format("woff2"), url("../fonts/FontAwesome/fa-regular-400.woff") format("woff"), url("../fonts/FontAwesome/fa-regular-400.ttf") format("truetype"), url("../fonts/FontAwesome/fa-regular-400.svg#fontawesome") format("svg");
}
.far {
  font-family: "Font Awesome 5 Pro";
  font-weight: 400;
}

/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 300;
  font-display: auto;
  src: url("../fonts/FontAwesome/fa-light-300.eot");
  src: url("../fonts/FontAwesome/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-light-300.woff2") format("woff2"), url("../fonts/FontAwesome/fa-light-300.woff") format("woff"), url("../fonts/FontAwesome/fa-light-300.ttf") format("truetype"), url("../fonts/FontAwesome/fa-light-300.svg#fontawesome") format("svg");
}
.fal {
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
}

/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Pro";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/FontAwesome/fa-solid-900.eot");
  src: url("../fonts/FontAwesome/fa-solid-900.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-solid-900.woff2") format("woff2"), url("../fonts/FontAwesome/fa-solid-900.woff") format("woff"), url("../fonts/FontAwesome/fa-solid-900.ttf") format("truetype"), url("../fonts/FontAwesome/fa-solid-900.svg#fontawesome") format("svg");
}
.fa,
.fas {
  font-family: "Font Awesome 5 Pro";
  font-weight: 900;
}

/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Brands";
  font-style: normal;
  font-weight: normal;
  font-display: auto;
  src: url("../fonts/FontAwesome/fa-brands-400.eot");
  src: url("../fonts/FontAwesome/fa-brands-400.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-brands-400.woff2") format("woff2"), url("../fonts/FontAwesome/fa-brands-400.woff") format("woff"), url("../fonts/FontAwesome/fa-brands-400.ttf") format("truetype"), url("../fonts/FontAwesome/fa-brands-400.svg#fontawesome") format("svg");
}
.fab {
  font-family: "Font Awesome 5 Brands";
}

/*!
 * Font Awesome Pro 5.10.0-11 by @fontawesome - https://fontawesome.com
 * License - https://fontawesome.com/license (Commercial License)
 */
@font-face {
  font-family: "Font Awesome 5 Duotone";
  font-style: normal;
  font-weight: 900;
  font-display: auto;
  src: url("../fonts/FontAwesome/fa-duotone-900.eot");
  src: url("../fonts/FontAwesome/fa-duotone-900.eot?#iefix") format("embedded-opentype"), url("../fonts/FontAwesome/fa-duotone-900.woff2") format("woff2"), url("../fonts/FontAwesome/fa-duotone-900.woff") format("woff"), url("../fonts/FontAwesome/fa-duotone-900.ttf") format("truetype"), url("../fonts/FontAwesome/fa-duotone-900.svg#fontawesome") format("svg");
}
.fad {
  position: relative;
  font-family: "Font Awesome 5 Duotone";
  font-weight: 900;
}

.fad:before {
  position: absolute;
  color: var(--fa-primary-color, inherit);
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad:after {
  color: var(--fa-secondary-color, inherit);
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fad.fa-swap-opacity:before {
  opacity: 0.4;
  opacity: var(--fa-secondary-opacity, 0.4);
}

.fad.fa-swap-opacity:after {
  opacity: 1;
  opacity: var(--fa-primary-opacity, 1);
}

.fad.fa-inverse {
  color: #fff;
}

.fad.fa-stack-1x, .fad.fa-stack-2x {
  position: absolute;
}

.fad.fa-stack-1x:before,
.fad.fa-stack-2x:before,
.fad.fa-fw:before {
  left: 50%;
  transform: translateX(-50%);
}

.fad.fa-abacus:after {
  content: "\f640 10";
}

.fad.fa-acorn:after {
  content: "\f6ae 10";
}

.fad.fa-ad:after {
  content: "\f641 10";
}

.fad.fa-address-book:after {
  content: "\f2b9 10";
}

.fad.fa-address-card:after {
  content: "\f2bb 10";
}

.fad.fa-adjust:after {
  content: "\f042 10";
}

.fad.fa-air-freshener:after {
  content: "\f5d0 10";
}

.fad.fa-alarm-clock:after {
  content: "\f34e 10";
}

.fad.fa-alarm-exclamation:after {
  content: "\f843 10";
}

.fad.fa-alarm-plus:after {
  content: "\f844 10";
}

.fad.fa-alarm-snooze:after {
  content: "\f845 10";
}

.fad.fa-alicorn:after {
  content: "\f6b0 10";
}

.fad.fa-align-center:after {
  content: "\f037 10";
}

.fad.fa-align-justify:after {
  content: "\f039 10";
}

.fad.fa-align-left:after {
  content: "\f036 10";
}

.fad.fa-align-right:after {
  content: "\f038 10";
}

.fad.fa-align-slash:after {
  content: "\f846 10";
}

.fad.fa-allergies:after {
  content: "\f461 10";
}

.fad.fa-ambulance:after {
  content: "\f0f9 10";
}

.fad.fa-american-sign-language-interpreting:after {
  content: "\f2a3 10";
}

.fad.fa-analytics:after {
  content: "\f643 10";
}

.fad.fa-anchor:after {
  content: "\f13d 10";
}

.fad.fa-angel:after {
  content: "\f779 10";
}

.fad.fa-angle-double-down:after {
  content: "\f103 10";
}

.fad.fa-angle-double-left:after {
  content: "\f100 10";
}

.fad.fa-angle-double-right:after {
  content: "\f101 10";
}

.fad.fa-angle-double-up:after {
  content: "\f102 10";
}

.fad.fa-angle-down:after {
  content: "\f107";
  visibility: hidden;
}

.fad.fa-angle-left:after {
  content: "\f104";
  visibility: hidden;
}

.fad.fa-angle-right:after {
  content: "\f105";
  visibility: hidden;
}

.fad.fa-angle-up:after {
  content: "\f106";
  visibility: hidden;
}

.fad.fa-angry:after {
  content: "\f556 10";
}

.fad.fa-ankh:after {
  content: "\f644";
  visibility: hidden;
}

.fad.fa-apple-alt:after {
  content: "\f5d1 10";
}

.fad.fa-apple-crate:after {
  content: "\f6b1 10";
}

.fad.fa-archive:after {
  content: "\f187 10";
}

.fad.fa-archway:after {
  content: "\f557 10";
}

.fad.fa-arrow-alt-circle-down:after {
  content: "\f358 10";
}

.fad.fa-arrow-alt-circle-left:after {
  content: "\f359 10";
}

.fad.fa-arrow-alt-circle-right:after {
  content: "\f35a 10";
}

.fad.fa-arrow-alt-circle-up:after {
  content: "\f35b 10";
}

.fad.fa-arrow-alt-down:after {
  content: "\f354";
  visibility: hidden;
}

.fad.fa-arrow-alt-from-bottom:after {
  content: "\f346 10";
}

.fad.fa-arrow-alt-from-left:after {
  content: "\f347 10";
}

.fad.fa-arrow-alt-from-right:after {
  content: "\f348 10";
}

.fad.fa-arrow-alt-from-top:after {
  content: "\f349 10";
}

.fad.fa-arrow-alt-left:after {
  content: "\f355";
  visibility: hidden;
}

.fad.fa-arrow-alt-right:after {
  content: "\f356";
  visibility: hidden;
}

.fad.fa-arrow-alt-square-down:after {
  content: "\f350 10";
}

.fad.fa-arrow-alt-square-left:after {
  content: "\f351 10";
}

.fad.fa-arrow-alt-square-right:after {
  content: "\f352 10";
}

.fad.fa-arrow-alt-square-up:after {
  content: "\f353 10";
}

.fad.fa-arrow-alt-to-bottom:after {
  content: "\f34a 10";
}

.fad.fa-arrow-alt-to-left:after {
  content: "\f34b 10";
}

.fad.fa-arrow-alt-to-right:after {
  content: "\f34c 10";
}

.fad.fa-arrow-alt-to-top:after {
  content: "\f34d 10";
}

.fad.fa-arrow-alt-up:after {
  content: "\f357";
  visibility: hidden;
}

.fad.fa-arrow-circle-down:after {
  content: "\f0ab 10";
}

.fad.fa-arrow-circle-left:after {
  content: "\f0a8 10";
}

.fad.fa-arrow-circle-right:after {
  content: "\f0a9 10";
}

.fad.fa-arrow-circle-up:after {
  content: "\f0aa 10";
}

.fad.fa-arrow-down:after {
  content: "\f063";
  visibility: hidden;
}

.fad.fa-arrow-from-bottom:after {
  content: "\f342 10";
}

.fad.fa-arrow-from-left:after {
  content: "\f343 10";
}

.fad.fa-arrow-from-right:after {
  content: "\f344 10";
}

.fad.fa-arrow-from-top:after {
  content: "\f345 10";
}

.fad.fa-arrow-left:after {
  content: "\f060";
  visibility: hidden;
}

.fad.fa-arrow-right:after {
  content: "\f061";
  visibility: hidden;
}

.fad.fa-arrow-square-down:after {
  content: "\f339 10";
}

.fad.fa-arrow-square-left:after {
  content: "\f33a 10";
}

.fad.fa-arrow-square-right:after {
  content: "\f33b 10";
}

.fad.fa-arrow-square-up:after {
  content: "\f33c 10";
}

.fad.fa-arrow-to-bottom:after {
  content: "\f33d 10";
}

.fad.fa-arrow-to-left:after {
  content: "\f33e 10";
}

.fad.fa-arrow-to-right:after {
  content: "\f340 10";
}

.fad.fa-arrow-to-top:after {
  content: "\f341 10";
}

.fad.fa-arrow-up:after {
  content: "\f062";
  visibility: hidden;
}

.fad.fa-arrows:after {
  content: "\f047";
  visibility: hidden;
}

.fad.fa-arrows-alt:after {
  content: "\f0b2";
  visibility: hidden;
}

.fad.fa-arrows-alt-h:after {
  content: "\f337";
  visibility: hidden;
}

.fad.fa-arrows-alt-v:after {
  content: "\f338";
  visibility: hidden;
}

.fad.fa-arrows-h:after {
  content: "\f07e";
  visibility: hidden;
}

.fad.fa-arrows-v:after {
  content: "\f07d";
  visibility: hidden;
}

.fad.fa-assistive-listening-systems:after {
  content: "\f2a2 10";
}

.fad.fa-asterisk:after {
  content: "\f069";
  visibility: hidden;
}

.fad.fa-at:after {
  content: "\f1fa";
  visibility: hidden;
}

.fad.fa-atlas:after {
  content: "\f558 10";
}

.fad.fa-atom:after {
  content: "\f5d2 10";
}

.fad.fa-atom-alt:after {
  content: "\f5d3 10";
}

.fad.fa-audio-description:after {
  content: "\f29e 10";
}

.fad.fa-award:after {
  content: "\f559 10";
}

.fad.fa-axe:after {
  content: "\f6b2 10";
}

.fad.fa-axe-battle:after {
  content: "\f6b3 10";
}

.fad.fa-baby:after {
  content: "\f77c 10";
}

.fad.fa-baby-carriage:after {
  content: "\f77d 10";
}

.fad.fa-backpack:after {
  content: "\f5d4 10";
}

.fad.fa-backspace:after {
  content: "\f55a 10";
}

.fad.fa-backward:after {
  content: "\f04a 10";
}

.fad.fa-bacon:after {
  content: "\f7e5 10";
}

.fad.fa-badge:after {
  content: "\f335";
  visibility: hidden;
}

.fad.fa-badge-check:after {
  content: "\f336 10";
}

.fad.fa-badge-dollar:after {
  content: "\f645 10";
}

.fad.fa-badge-percent:after {
  content: "\f646 10";
}

.fad.fa-badger-honey:after {
  content: "\f6b4 10";
}

.fad.fa-bags-shopping:after {
  content: "\f847 10";
}

.fad.fa-balance-scale:after {
  content: "\f24e 10";
}

.fad.fa-balance-scale-left:after {
  content: "\f515 10";
}

.fad.fa-balance-scale-right:after {
  content: "\f516 10";
}

.fad.fa-ball-pile:after {
  content: "\f77e 10";
}

.fad.fa-ballot:after {
  content: "\f732 10";
}

.fad.fa-ballot-check:after {
  content: "\f733 10";
}

.fad.fa-ban:after {
  content: "\f05e";
  visibility: hidden;
}

.fad.fa-band-aid:after {
  content: "\f462 10";
}

.fad.fa-barcode:after {
  content: "\f02a";
  visibility: hidden;
}

.fad.fa-barcode-alt:after {
  content: "\f463 10";
}

.fad.fa-barcode-read:after {
  content: "\f464 10";
}

.fad.fa-barcode-scan:after {
  content: "\f465 10";
}

.fad.fa-bars:after {
  content: "\f0c9";
  visibility: hidden;
}

.fad.fa-baseball:after {
  content: "\f432 10";
}

.fad.fa-baseball-ball:after {
  content: "\f433 10";
}

.fad.fa-basketball-ball:after {
  content: "\f434 10";
}

.fad.fa-basketball-hoop:after {
  content: "\f435 10";
}

.fad.fa-bat:after {
  content: "\f6b5";
  visibility: hidden;
}

.fad.fa-bath:after {
  content: "\f2cd 10";
}

.fad.fa-battery-bolt:after {
  content: "\f376 10";
}

.fad.fa-battery-empty:after {
  content: "\f244";
  visibility: hidden;
}

.fad.fa-battery-full:after {
  content: "\f240 10";
}

.fad.fa-battery-half:after {
  content: "\f242 10";
}

.fad.fa-battery-quarter:after {
  content: "\f243 10";
}

.fad.fa-battery-slash:after {
  content: "\f377 10";
}

.fad.fa-battery-three-quarters:after {
  content: "\f241 10";
}

.fad.fa-bed:after {
  content: "\f236 10";
}

.fad.fa-beer:after {
  content: "\f0fc 10";
}

.fad.fa-bell:after {
  content: "\f0f3 10";
}

.fad.fa-bell-exclamation:after {
  content: "\f848 10";
}

.fad.fa-bell-plus:after {
  content: "\f849 10";
}

.fad.fa-bell-school:after {
  content: "\f5d5 10";
}

.fad.fa-bell-school-slash:after {
  content: "\f5d6 10";
}

.fad.fa-bell-slash:after {
  content: "\f1f6 10";
}

.fad.fa-bells:after {
  content: "\f77f 10";
}

.fad.fa-bezier-curve:after {
  content: "\f55b 10";
}

.fad.fa-bible:after {
  content: "\f647 10";
}

.fad.fa-bicycle:after {
  content: "\f206 10";
}

.fad.fa-biking:after {
  content: "\f84a 10";
}

.fad.fa-biking-mountain:after {
  content: "\f84b 10";
}

.fad.fa-binoculars:after {
  content: "\f1e5 10";
}

.fad.fa-biohazard:after {
  content: "\f780 10";
}

.fad.fa-birthday-cake:after {
  content: "\f1fd 10";
}

.fad.fa-blanket:after {
  content: "\f498";
  visibility: hidden;
}

.fad.fa-blender:after {
  content: "\f517 10";
}

.fad.fa-blender-phone:after {
  content: "\f6b6 10";
}

.fad.fa-blind:after {
  content: "\f29d 10";
}

.fad.fa-blog:after {
  content: "\f781 10";
}

.fad.fa-bold:after {
  content: "\f032";
  visibility: hidden;
}

.fad.fa-bolt:after {
  content: "\f0e7";
  visibility: hidden;
}

.fad.fa-bomb:after {
  content: "\f1e2 10";
}

.fad.fa-bone:after {
  content: "\f5d7";
  visibility: hidden;
}

.fad.fa-bone-break:after {
  content: "\f5d8 10";
}

.fad.fa-bong:after {
  content: "\f55c 10";
}

.fad.fa-book:after {
  content: "\f02d 10";
}

.fad.fa-book-alt:after {
  content: "\f5d9 10";
}

.fad.fa-book-dead:after {
  content: "\f6b7 10";
}

.fad.fa-book-heart:after {
  content: "\f499 10";
}

.fad.fa-book-medical:after {
  content: "\f7e6 10";
}

.fad.fa-book-open:after {
  content: "\f518 10";
}

.fad.fa-book-reader:after {
  content: "\f5da 10";
}

.fad.fa-book-spells:after {
  content: "\f6b8 10";
}

.fad.fa-book-user:after {
  content: "\f7e7 10";
}

.fad.fa-bookmark:after {
  content: "\f02e 10";
}

.fad.fa-books:after {
  content: "\f5db 10";
}

.fad.fa-books-medical:after {
  content: "\f7e8 10";
}

.fad.fa-boot:after {
  content: "\f782 10";
}

.fad.fa-booth-curtain:after {
  content: "\f734 10";
}

.fad.fa-border-all:after {
  content: "\f84c";
  visibility: hidden;
}

.fad.fa-border-bottom:after {
  content: "\f84d 10";
}

.fad.fa-border-inner:after {
  content: "\f84e 10";
}

.fad.fa-border-left:after {
  content: "\f84f 10";
}

.fad.fa-border-none:after {
  content: "\f850";
  visibility: hidden;
}

.fad.fa-border-outer:after {
  content: "\f851 10";
}

.fad.fa-border-right:after {
  content: "\f852 10";
}

.fad.fa-border-style:after {
  content: "\f853 10";
}

.fad.fa-border-style-alt:after {
  content: "\f854 10";
}

.fad.fa-border-top:after {
  content: "\f855 10";
}

.fad.fa-bow-arrow:after {
  content: "\f6b9 10";
}

.fad.fa-bowling-ball:after {
  content: "\f436 10";
}

.fad.fa-bowling-pins:after {
  content: "\f437 10";
}

.fad.fa-box:after {
  content: "\f466 10";
}

.fad.fa-box-alt:after {
  content: "\f49a 10";
}

.fad.fa-box-ballot:after {
  content: "\f735 10";
}

.fad.fa-box-check:after {
  content: "\f467 10";
}

.fad.fa-box-fragile:after {
  content: "\f49b 10";
}

.fad.fa-box-full:after {
  content: "\f49c 10";
}

.fad.fa-box-heart:after {
  content: "\f49d 10";
}

.fad.fa-box-open:after {
  content: "\f49e 10";
}

.fad.fa-box-up:after {
  content: "\f49f 10";
}

.fad.fa-box-usd:after {
  content: "\f4a0 10";
}

.fad.fa-boxes:after {
  content: "\f468 10";
}

.fad.fa-boxes-alt:after {
  content: "\f4a1 10";
}

.fad.fa-boxing-glove:after {
  content: "\f438 10";
}

.fad.fa-brackets:after {
  content: "\f7e9 10";
}

.fad.fa-brackets-curly:after {
  content: "\f7ea 10";
}

.fad.fa-braille:after {
  content: "\f2a1 10";
}

.fad.fa-brain:after {
  content: "\f5dc 10";
}

.fad.fa-bread-loaf:after {
  content: "\f7eb 10";
}

.fad.fa-bread-slice:after {
  content: "\f7ec";
  visibility: hidden;
}

.fad.fa-briefcase:after {
  content: "\f0b1 10";
}

.fad.fa-briefcase-medical:after {
  content: "\f469 10";
}

.fad.fa-bring-forward:after {
  content: "\f856 10";
}

.fad.fa-bring-front:after {
  content: "\f857 10";
}

.fad.fa-broadcast-tower:after {
  content: "\f519 10";
}

.fad.fa-broom:after {
  content: "\f51a 10";
}

.fad.fa-browser:after {
  content: "\f37e 10";
}

.fad.fa-brush:after {
  content: "\f55d 10";
}

.fad.fa-bug:after {
  content: "\f188 10";
}

.fad.fa-building:after {
  content: "\f1ad 10";
}

.fad.fa-bullhorn:after {
  content: "\f0a1 10";
}

.fad.fa-bullseye:after {
  content: "\f140 10";
}

.fad.fa-bullseye-arrow:after {
  content: "\f648 10";
}

.fad.fa-bullseye-pointer:after {
  content: "\f649 10";
}

.fad.fa-burger-soda:after {
  content: "\f858 10";
}

.fad.fa-burn:after {
  content: "\f46a 10";
}

.fad.fa-burrito:after {
  content: "\f7ed 10";
}

.fad.fa-bus:after {
  content: "\f207 10";
}

.fad.fa-bus-alt:after {
  content: "\f55e 10";
}

.fad.fa-bus-school:after {
  content: "\f5dd 10";
}

.fad.fa-business-time:after {
  content: "\f64a 10";
}

.fad.fa-cabinet-filing:after {
  content: "\f64b 10";
}

.fad.fa-calculator:after {
  content: "\f1ec 10";
}

.fad.fa-calculator-alt:after {
  content: "\f64c 10";
}

.fad.fa-calendar:after {
  content: "\f133 10";
}

.fad.fa-calendar-alt:after {
  content: "\f073 10";
}

.fad.fa-calendar-check:after {
  content: "\f274 10";
}

.fad.fa-calendar-day:after {
  content: "\f783 10";
}

.fad.fa-calendar-edit:after {
  content: "\f333 10";
}

.fad.fa-calendar-exclamation:after {
  content: "\f334 10";
}

.fad.fa-calendar-minus:after {
  content: "\f272 10";
}

.fad.fa-calendar-plus:after {
  content: "\f271 10";
}

.fad.fa-calendar-star:after {
  content: "\f736 10";
}

.fad.fa-calendar-times:after {
  content: "\f273 10";
}

.fad.fa-calendar-week:after {
  content: "\f784 10";
}

.fad.fa-camera:after {
  content: "\f030 10";
}

.fad.fa-camera-alt:after {
  content: "\f332 10";
}

.fad.fa-camera-retro:after {
  content: "\f083 10";
}

.fad.fa-campfire:after {
  content: "\f6ba 10";
}

.fad.fa-campground:after {
  content: "\f6bb 10";
}

.fad.fa-candle-holder:after {
  content: "\f6bc 10";
}

.fad.fa-candy-cane:after {
  content: "\f786 10";
}

.fad.fa-candy-corn:after {
  content: "\f6bd 10";
}

.fad.fa-cannabis:after {
  content: "\f55f 10";
}

.fad.fa-capsules:after {
  content: "\f46b 10";
}

.fad.fa-car:after {
  content: "\f1b9 10";
}

.fad.fa-car-alt:after {
  content: "\f5de 10";
}

.fad.fa-car-battery:after {
  content: "\f5df 10";
}

.fad.fa-car-building:after {
  content: "\f859 10";
}

.fad.fa-car-bump:after {
  content: "\f5e0 10";
}

.fad.fa-car-bus:after {
  content: "\f85a 10";
}

.fad.fa-car-crash:after {
  content: "\f5e1 10";
}

.fad.fa-car-garage:after {
  content: "\f5e2 10";
}

.fad.fa-car-mechanic:after {
  content: "\f5e3 10";
}

.fad.fa-car-side:after {
  content: "\f5e4 10";
}

.fad.fa-car-tilt:after {
  content: "\f5e5 10";
}

.fad.fa-car-wash:after {
  content: "\f5e6 10";
}

.fad.fa-caret-circle-down:after {
  content: "\f32d 10";
}

.fad.fa-caret-circle-left:after {
  content: "\f32e 10";
}

.fad.fa-caret-circle-right:after {
  content: "\f330 10";
}

.fad.fa-caret-circle-up:after {
  content: "\f331 10";
}

.fad.fa-caret-down:after {
  content: "\f0d7";
  visibility: hidden;
}

.fad.fa-caret-left:after {
  content: "\f0d9";
  visibility: hidden;
}

.fad.fa-caret-right:after {
  content: "\f0da";
  visibility: hidden;
}

.fad.fa-caret-square-down:after {
  content: "\f150 10";
}

.fad.fa-caret-square-left:after {
  content: "\f191 10";
}

.fad.fa-caret-square-right:after {
  content: "\f152 10";
}

.fad.fa-caret-square-up:after {
  content: "\f151 10";
}

.fad.fa-caret-up:after {
  content: "\f0d8";
  visibility: hidden;
}

.fad.fa-carrot:after {
  content: "\f787 10";
}

.fad.fa-cars:after {
  content: "\f85b 10";
}

.fad.fa-cart-arrow-down:after {
  content: "\f218 10";
}

.fad.fa-cart-plus:after {
  content: "\f217 10";
}

.fad.fa-cash-register:after {
  content: "\f788 10";
}

.fad.fa-cat:after {
  content: "\f6be 10";
}

.fad.fa-cauldron:after {
  content: "\f6bf 10";
}

.fad.fa-certificate:after {
  content: "\f0a3";
  visibility: hidden;
}

.fad.fa-chair:after {
  content: "\f6c0 10";
}

.fad.fa-chair-office:after {
  content: "\f6c1 10";
}

.fad.fa-chalkboard:after {
  content: "\f51b 10";
}

.fad.fa-chalkboard-teacher:after {
  content: "\f51c 10";
}

.fad.fa-charging-station:after {
  content: "\f5e7 10";
}

.fad.fa-chart-area:after {
  content: "\f1fe 10";
}

.fad.fa-chart-bar:after {
  content: "\f080 10";
}

.fad.fa-chart-line:after {
  content: "\f201 10";
}

.fad.fa-chart-line-down:after {
  content: "\f64d 10";
}

.fad.fa-chart-network:after {
  content: "\f78a 10";
}

.fad.fa-chart-pie:after {
  content: "\f200 10";
}

.fad.fa-chart-pie-alt:after {
  content: "\f64e 10";
}

.fad.fa-chart-scatter:after {
  content: "\f7ee 10";
}

.fad.fa-check:after {
  content: "\f00c";
  visibility: hidden;
}

.fad.fa-check-circle:after {
  content: "\f058 10";
}

.fad.fa-check-double:after {
  content: "\f560 10";
}

.fad.fa-check-square:after {
  content: "\f14a 10";
}

.fad.fa-cheese:after {
  content: "\f7ef 10";
}

.fad.fa-cheese-swiss:after {
  content: "\f7f0 10";
}

.fad.fa-cheeseburger:after {
  content: "\f7f1 10";
}

.fad.fa-chess:after {
  content: "\f439 10";
}

.fad.fa-chess-bishop:after {
  content: "\f43a 10";
}

.fad.fa-chess-bishop-alt:after {
  content: "\f43b 10";
}

.fad.fa-chess-board:after {
  content: "\f43c 10";
}

.fad.fa-chess-clock:after {
  content: "\f43d 10";
}

.fad.fa-chess-clock-alt:after {
  content: "\f43e 10";
}

.fad.fa-chess-king:after {
  content: "\f43f 10";
}

.fad.fa-chess-king-alt:after {
  content: "\f440 10";
}

.fad.fa-chess-knight:after {
  content: "\f441 10";
}

.fad.fa-chess-knight-alt:after {
  content: "\f442 10";
}

.fad.fa-chess-pawn:after {
  content: "\f443 10";
}

.fad.fa-chess-pawn-alt:after {
  content: "\f444 10";
}

.fad.fa-chess-queen:after {
  content: "\f445 10";
}

.fad.fa-chess-queen-alt:after {
  content: "\f446 10";
}

.fad.fa-chess-rook:after {
  content: "\f447 10";
}

.fad.fa-chess-rook-alt:after {
  content: "\f448 10";
}

.fad.fa-chevron-circle-down:after {
  content: "\f13a 10";
}

.fad.fa-chevron-circle-left:after {
  content: "\f137 10";
}

.fad.fa-chevron-circle-right:after {
  content: "\f138 10";
}

.fad.fa-chevron-circle-up:after {
  content: "\f139 10";
}

.fad.fa-chevron-double-down:after {
  content: "\f322 10";
}

.fad.fa-chevron-double-left:after {
  content: "\f323 10";
}

.fad.fa-chevron-double-right:after {
  content: "\f324 10";
}

.fad.fa-chevron-double-up:after {
  content: "\f325 10";
}

.fad.fa-chevron-down:after {
  content: "\f078";
  visibility: hidden;
}

.fad.fa-chevron-left:after {
  content: "\f053";
  visibility: hidden;
}

.fad.fa-chevron-right:after {
  content: "\f054";
  visibility: hidden;
}

.fad.fa-chevron-square-down:after {
  content: "\f329 10";
}

.fad.fa-chevron-square-left:after {
  content: "\f32a 10";
}

.fad.fa-chevron-square-right:after {
  content: "\f32b 10";
}

.fad.fa-chevron-square-up:after {
  content: "\f32c 10";
}

.fad.fa-chevron-up:after {
  content: "\f077";
  visibility: hidden;
}

.fad.fa-child:after {
  content: "\f1ae 10";
}

.fad.fa-chimney:after {
  content: "\f78b 10";
}

.fad.fa-church:after {
  content: "\f51d 10";
}

.fad.fa-circle:after {
  content: "\f111";
  visibility: hidden;
}

.fad.fa-circle-notch:after {
  content: "\f1ce";
  visibility: hidden;
}

.fad.fa-city:after {
  content: "\f64f 10";
}

.fad.fa-claw-marks:after {
  content: "\f6c2 10";
}

.fad.fa-clinic-medical:after {
  content: "\f7f2 10";
}

.fad.fa-clipboard:after {
  content: "\f328 10";
}

.fad.fa-clipboard-check:after {
  content: "\f46c 10";
}

.fad.fa-clipboard-list:after {
  content: "\f46d 10";
}

.fad.fa-clipboard-list-check:after {
  content: "\f737 10";
}

.fad.fa-clipboard-prescription:after {
  content: "\f5e8 10";
}

.fad.fa-clipboard-user:after {
  content: "\f7f3 10";
}

.fad.fa-clock:after {
  content: "\f017 10";
}

.fad.fa-clone:after {
  content: "\f24d 10";
}

.fad.fa-closed-captioning:after {
  content: "\f20a 10";
}

.fad.fa-cloud:after {
  content: "\f0c2";
  visibility: hidden;
}

.fad.fa-cloud-download:after {
  content: "\f0ed 10";
}

.fad.fa-cloud-download-alt:after {
  content: "\f381 10";
}

.fad.fa-cloud-drizzle:after {
  content: "\f738 10";
}

.fad.fa-cloud-hail:after {
  content: "\f739 10";
}

.fad.fa-cloud-hail-mixed:after {
  content: "\f73a 10";
}

.fad.fa-cloud-meatball:after {
  content: "\f73b 10";
}

.fad.fa-cloud-moon:after {
  content: "\f6c3 10";
}

.fad.fa-cloud-moon-rain:after {
  content: "\f73c 10";
}

.fad.fa-cloud-rain:after {
  content: "\f73d 10";
}

.fad.fa-cloud-rainbow:after {
  content: "\f73e 10";
}

.fad.fa-cloud-showers:after {
  content: "\f73f 10";
}

.fad.fa-cloud-showers-heavy:after {
  content: "\f740 10";
}

.fad.fa-cloud-sleet:after {
  content: "\f741 10";
}

.fad.fa-cloud-snow:after {
  content: "\f742 10";
}

.fad.fa-cloud-sun:after {
  content: "\f6c4 10";
}

.fad.fa-cloud-sun-rain:after {
  content: "\f743 10";
}

.fad.fa-cloud-upload:after {
  content: "\f0ee 10";
}

.fad.fa-cloud-upload-alt:after {
  content: "\f382 10";
}

.fad.fa-clouds:after {
  content: "\f744 10";
}

.fad.fa-clouds-moon:after {
  content: "\f745 10";
}

.fad.fa-clouds-sun:after {
  content: "\f746 10";
}

.fad.fa-club:after {
  content: "\f327";
  visibility: hidden;
}

.fad.fa-cocktail:after {
  content: "\f561 10";
}

.fad.fa-code:after {
  content: "\f121 10";
}

.fad.fa-code-branch:after {
  content: "\f126 10";
}

.fad.fa-code-commit:after {
  content: "\f386 10";
}

.fad.fa-code-merge:after {
  content: "\f387 10";
}

.fad.fa-coffee:after {
  content: "\f0f4 10";
}

.fad.fa-coffee-togo:after {
  content: "\f6c5 10";
}

.fad.fa-coffin:after {
  content: "\f6c6 10";
}

.fad.fa-cog:after {
  content: "\f013";
  visibility: hidden;
}

.fad.fa-cogs:after {
  content: "\f085 10";
}

.fad.fa-coin:after {
  content: "\f85c 10";
}

.fad.fa-coins:after {
  content: "\f51e 10";
}

.fad.fa-columns:after {
  content: "\f0db 10";
}

.fad.fa-comment:after {
  content: "\f075";
  visibility: hidden;
}

.fad.fa-comment-alt:after {
  content: "\f27a";
  visibility: hidden;
}

.fad.fa-comment-alt-check:after {
  content: "\f4a2 10";
}

.fad.fa-comment-alt-dollar:after {
  content: "\f650 10";
}

.fad.fa-comment-alt-dots:after {
  content: "\f4a3 10";
}

.fad.fa-comment-alt-edit:after {
  content: "\f4a4 10";
}

.fad.fa-comment-alt-exclamation:after {
  content: "\f4a5 10";
}

.fad.fa-comment-alt-lines:after {
  content: "\f4a6 10";
}

.fad.fa-comment-alt-medical:after {
  content: "\f7f4 10";
}

.fad.fa-comment-alt-minus:after {
  content: "\f4a7 10";
}

.fad.fa-comment-alt-plus:after {
  content: "\f4a8 10";
}

.fad.fa-comment-alt-slash:after {
  content: "\f4a9 10";
}

.fad.fa-comment-alt-smile:after {
  content: "\f4aa 10";
}

.fad.fa-comment-alt-times:after {
  content: "\f4ab 10";
}

.fad.fa-comment-check:after {
  content: "\f4ac 10";
}

.fad.fa-comment-dollar:after {
  content: "\f651 10";
}

.fad.fa-comment-dots:after {
  content: "\f4ad 10";
}

.fad.fa-comment-edit:after {
  content: "\f4ae 10";
}

.fad.fa-comment-exclamation:after {
  content: "\f4af 10";
}

.fad.fa-comment-lines:after {
  content: "\f4b0 10";
}

.fad.fa-comment-medical:after {
  content: "\f7f5 10";
}

.fad.fa-comment-minus:after {
  content: "\f4b1 10";
}

.fad.fa-comment-plus:after {
  content: "\f4b2 10";
}

.fad.fa-comment-slash:after {
  content: "\f4b3 10";
}

.fad.fa-comment-smile:after {
  content: "\f4b4 10";
}

.fad.fa-comment-times:after {
  content: "\f4b5 10";
}

.fad.fa-comments:after {
  content: "\f086 10";
}

.fad.fa-comments-alt:after {
  content: "\f4b6 10";
}

.fad.fa-comments-alt-dollar:after {
  content: "\f652 10";
}

.fad.fa-comments-dollar:after {
  content: "\f653 10";
}

.fad.fa-compact-disc:after {
  content: "\f51f 10";
}

.fad.fa-compass:after {
  content: "\f14e 10";
}

.fad.fa-compass-slash:after {
  content: "\f5e9 10";
}

.fad.fa-compress:after {
  content: "\f066 10";
}

.fad.fa-compress-alt:after {
  content: "\f422 10";
}

.fad.fa-compress-arrows-alt:after {
  content: "\f78c 10";
}

.fad.fa-compress-wide:after {
  content: "\f326 10";
}

.fad.fa-concierge-bell:after {
  content: "\f562 10";
}

.fad.fa-construction:after {
  content: "\f85d 10";
}

.fad.fa-container-storage:after {
  content: "\f4b7 10";
}

.fad.fa-conveyor-belt:after {
  content: "\f46e 10";
}

.fad.fa-conveyor-belt-alt:after {
  content: "\f46f 10";
}

.fad.fa-cookie:after {
  content: "\f563 10";
}

.fad.fa-cookie-bite:after {
  content: "\f564 10";
}

.fad.fa-copy:after {
  content: "\f0c5 10";
}

.fad.fa-copyright:after {
  content: "\f1f9 10";
}

.fad.fa-corn:after {
  content: "\f6c7 10";
}

.fad.fa-couch:after {
  content: "\f4b8 10";
}

.fad.fa-cow:after {
  content: "\f6c8 10";
}

.fad.fa-credit-card:after {
  content: "\f09d 10";
}

.fad.fa-credit-card-blank:after {
  content: "\f389 10";
}

.fad.fa-credit-card-front:after {
  content: "\f38a 10";
}

.fad.fa-cricket:after {
  content: "\f449 10";
}

.fad.fa-croissant:after {
  content: "\f7f6 10";
}

.fad.fa-crop:after {
  content: "\f125 10";
}

.fad.fa-crop-alt:after {
  content: "\f565 10";
}

.fad.fa-cross:after {
  content: "\f654";
  visibility: hidden;
}

.fad.fa-crosshairs:after {
  content: "\f05b 10";
}

.fad.fa-crow:after {
  content: "\f520 10";
}

.fad.fa-crown:after {
  content: "\f521 10";
}

.fad.fa-crutch:after {
  content: "\f7f7 10";
}

.fad.fa-crutches:after {
  content: "\f7f8 10";
}

.fad.fa-cube:after {
  content: "\f1b2 10";
}

.fad.fa-cubes:after {
  content: "\f1b3 10";
}

.fad.fa-curling:after {
  content: "\f44a 10";
}

.fad.fa-cut:after {
  content: "\f0c4 10";
}

.fad.fa-dagger:after {
  content: "\f6cb 10";
}

.fad.fa-database:after {
  content: "\f1c0 10";
}

.fad.fa-deaf:after {
  content: "\f2a4 10";
}

.fad.fa-debug:after {
  content: "\f7f9 10";
}

.fad.fa-deer:after {
  content: "\f78e 10";
}

.fad.fa-deer-rudolph:after {
  content: "\f78f 10";
}

.fad.fa-democrat:after {
  content: "\f747 10";
}

.fad.fa-desktop:after {
  content: "\f108 10";
}

.fad.fa-desktop-alt:after {
  content: "\f390 10";
}

.fad.fa-dewpoint:after {
  content: "\f748 10";
}

.fad.fa-dharmachakra:after {
  content: "\f655 10";
}

.fad.fa-diagnoses:after {
  content: "\f470 10";
}

.fad.fa-diamond:after {
  content: "\f219";
  visibility: hidden;
}

.fad.fa-dice:after {
  content: "\f522 10";
}

.fad.fa-dice-d10:after {
  content: "\f6cd 10";
}

.fad.fa-dice-d12:after {
  content: "\f6ce 10";
}

.fad.fa-dice-d20:after {
  content: "\f6cf 10";
}

.fad.fa-dice-d4:after {
  content: "\f6d0 10";
}

.fad.fa-dice-d6:after {
  content: "\f6d1 10";
}

.fad.fa-dice-d8:after {
  content: "\f6d2 10";
}

.fad.fa-dice-five:after {
  content: "\f523 10";
}

.fad.fa-dice-four:after {
  content: "\f524 10";
}

.fad.fa-dice-one:after {
  content: "\f525 10";
}

.fad.fa-dice-six:after {
  content: "\f526 10";
}

.fad.fa-dice-three:after {
  content: "\f527 10";
}

.fad.fa-dice-two:after {
  content: "\f528 10";
}

.fad.fa-digging:after {
  content: "\f85e 10";
}

.fad.fa-digital-tachograph:after {
  content: "\f566 10";
}

.fad.fa-diploma:after {
  content: "\f5ea 10";
}

.fad.fa-directions:after {
  content: "\f5eb 10";
}

.fad.fa-disease:after {
  content: "\f7fa 10";
}

.fad.fa-divide:after {
  content: "\f529 10";
}

.fad.fa-dizzy:after {
  content: "\f567 10";
}

.fad.fa-dna:after {
  content: "\f471 10";
}

.fad.fa-do-not-enter:after {
  content: "\f5ec 10";
}

.fad.fa-dog:after {
  content: "\f6d3 10";
}

.fad.fa-dog-leashed:after {
  content: "\f6d4 10";
}

.fad.fa-dollar-sign:after {
  content: "\f155";
  visibility: hidden;
}

.fad.fa-dolly:after {
  content: "\f472 10";
}

.fad.fa-dolly-empty:after {
  content: "\f473 10";
}

.fad.fa-dolly-flatbed:after {
  content: "\f474 10";
}

.fad.fa-dolly-flatbed-alt:after {
  content: "\f475 10";
}

.fad.fa-dolly-flatbed-empty:after {
  content: "\f476";
  visibility: hidden;
}

.fad.fa-donate:after {
  content: "\f4b9 10";
}

.fad.fa-door-closed:after {
  content: "\f52a 10";
}

.fad.fa-door-open:after {
  content: "\f52b 10";
}

.fad.fa-dot-circle:after {
  content: "\f192 10";
}

.fad.fa-dove:after {
  content: "\f4ba 10";
}

.fad.fa-download:after {
  content: "\f019 10";
}

.fad.fa-drafting-compass:after {
  content: "\f568 10";
}

.fad.fa-dragon:after {
  content: "\f6d5 10";
}

.fad.fa-draw-circle:after {
  content: "\f5ed 10";
}

.fad.fa-draw-polygon:after {
  content: "\f5ee 10";
}

.fad.fa-draw-square:after {
  content: "\f5ef 10";
}

.fad.fa-dreidel:after {
  content: "\f792 10";
}

.fad.fa-drone:after {
  content: "\f85f 10";
}

.fad.fa-drone-alt:after {
  content: "\f860 10";
}

.fad.fa-drum:after {
  content: "\f569 10";
}

.fad.fa-drum-steelpan:after {
  content: "\f56a 10";
}

.fad.fa-drumstick:after {
  content: "\f6d6 10";
}

.fad.fa-drumstick-bite:after {
  content: "\f6d7 10";
}

.fad.fa-dryer:after {
  content: "\f861 10";
}

.fad.fa-dryer-alt:after {
  content: "\f862 10";
}

.fad.fa-duck:after {
  content: "\f6d8 10";
}

.fad.fa-dumbbell:after {
  content: "\f44b 10";
}

.fad.fa-dumpster:after {
  content: "\f793 10";
}

.fad.fa-dumpster-fire:after {
  content: "\f794 10";
}

.fad.fa-dungeon:after {
  content: "\f6d9 10";
}

.fad.fa-ear:after {
  content: "\f5f0 10";
}

.fad.fa-ear-muffs:after {
  content: "\f795 10";
}

.fad.fa-eclipse:after {
  content: "\f749 10";
}

.fad.fa-eclipse-alt:after {
  content: "\f74a 10";
}

.fad.fa-edit:after {
  content: "\f044 10";
}

.fad.fa-egg:after {
  content: "\f7fb";
  visibility: hidden;
}

.fad.fa-egg-fried:after {
  content: "\f7fc 10";
}

.fad.fa-eject:after {
  content: "\f052 10";
}

.fad.fa-elephant:after {
  content: "\f6da 10";
}

.fad.fa-ellipsis-h:after {
  content: "\f141";
  visibility: hidden;
}

.fad.fa-ellipsis-h-alt:after {
  content: "\f39b 10";
}

.fad.fa-ellipsis-v:after {
  content: "\f142";
  visibility: hidden;
}

.fad.fa-ellipsis-v-alt:after {
  content: "\f39c 10";
}

.fad.fa-empty-set:after {
  content: "\f656 10";
}

.fad.fa-engine-warning:after {
  content: "\f5f2 10";
}

.fad.fa-envelope:after {
  content: "\f0e0 10";
}

.fad.fa-envelope-open:after {
  content: "\f2b6 10";
}

.fad.fa-envelope-open-dollar:after {
  content: "\f657 10";
}

.fad.fa-envelope-open-text:after {
  content: "\f658 10";
}

.fad.fa-envelope-square:after {
  content: "\f199 10";
}

.fad.fa-equals:after {
  content: "\f52c";
  visibility: hidden;
}

.fad.fa-eraser:after {
  content: "\f12d 10";
}

.fad.fa-ethernet:after {
  content: "\f796 10";
}

.fad.fa-euro-sign:after {
  content: "\f153";
  visibility: hidden;
}

.fad.fa-exchange:after {
  content: "\f0ec 10";
}

.fad.fa-exchange-alt:after {
  content: "\f362 10";
}

.fad.fa-exclamation:after {
  content: "\f12a 10";
}

.fad.fa-exclamation-circle:after {
  content: "\f06a 10";
}

.fad.fa-exclamation-square:after {
  content: "\f321 10";
}

.fad.fa-exclamation-triangle:after {
  content: "\f071 10";
}

.fad.fa-expand:after {
  content: "\f065 10";
}

.fad.fa-expand-alt:after {
  content: "\f424 10";
}

.fad.fa-expand-arrows:after {
  content: "\f31d 10";
}

.fad.fa-expand-arrows-alt:after {
  content: "\f31e 10";
}

.fad.fa-expand-wide:after {
  content: "\f320 10";
}

.fad.fa-external-link:after {
  content: "\f08e 10";
}

.fad.fa-external-link-alt:after {
  content: "\f35d 10";
}

.fad.fa-external-link-square:after {
  content: "\f14c 10";
}

.fad.fa-external-link-square-alt:after {
  content: "\f360 10";
}

.fad.fa-eye:after {
  content: "\f06e 10";
}

.fad.fa-eye-dropper:after {
  content: "\f1fb 10";
}

.fad.fa-eye-evil:after {
  content: "\f6db 10";
}

.fad.fa-eye-slash:after {
  content: "\f070 10";
}

.fad.fa-fan:after {
  content: "\f863 10";
}

.fad.fa-farm:after {
  content: "\f864 10";
}

.fad.fa-fast-backward:after {
  content: "\f049 10";
}

.fad.fa-fast-forward:after {
  content: "\f050 10";
}

.fad.fa-fax:after {
  content: "\f1ac 10";
}

.fad.fa-feather:after {
  content: "\f52d 10";
}

.fad.fa-feather-alt:after {
  content: "\f56b 10";
}

.fad.fa-female:after {
  content: "\f182 10";
}

.fad.fa-field-hockey:after {
  content: "\f44c 10";
}

.fad.fa-fighter-jet:after {
  content: "\f0fb 10";
}

.fad.fa-file:after {
  content: "\f15b 10";
}

.fad.fa-file-alt:after {
  content: "\f15c 10";
}

.fad.fa-file-archive:after {
  content: "\f1c6 10";
}

.fad.fa-file-audio:after {
  content: "\f1c7 10";
}

.fad.fa-file-certificate:after {
  content: "\f5f3 10";
}

.fad.fa-file-chart-line:after {
  content: "\f659 10";
}

.fad.fa-file-chart-pie:after {
  content: "\f65a 10";
}

.fad.fa-file-check:after {
  content: "\f316 10";
}

.fad.fa-file-code:after {
  content: "\f1c9 10";
}

.fad.fa-file-contract:after {
  content: "\f56c 10";
}

.fad.fa-file-csv:after {
  content: "\f6dd 10";
}

.fad.fa-file-download:after {
  content: "\f56d 10";
}

.fad.fa-file-edit:after {
  content: "\f31c 10";
}

.fad.fa-file-excel:after {
  content: "\f1c3 10";
}

.fad.fa-file-exclamation:after {
  content: "\f31a 10";
}

.fad.fa-file-export:after {
  content: "\f56e 10";
}

.fad.fa-file-image:after {
  content: "\f1c5 10";
}

.fad.fa-file-import:after {
  content: "\f56f 10";
}

.fad.fa-file-invoice:after {
  content: "\f570 10";
}

.fad.fa-file-invoice-dollar:after {
  content: "\f571 10";
}

.fad.fa-file-medical:after {
  content: "\f477 10";
}

.fad.fa-file-medical-alt:after {
  content: "\f478 10";
}

.fad.fa-file-minus:after {
  content: "\f318 10";
}

.fad.fa-file-pdf:after {
  content: "\f1c1 10";
}

.fad.fa-file-plus:after {
  content: "\f319 10";
}

.fad.fa-file-powerpoint:after {
  content: "\f1c4 10";
}

.fad.fa-file-prescription:after {
  content: "\f572 10";
}

.fad.fa-file-search:after {
  content: "\f865 10";
}

.fad.fa-file-signature:after {
  content: "\f573 10";
}

.fad.fa-file-spreadsheet:after {
  content: "\f65b 10";
}

.fad.fa-file-times:after {
  content: "\f317 10";
}

.fad.fa-file-upload:after {
  content: "\f574 10";
}

.fad.fa-file-user:after {
  content: "\f65c 10";
}

.fad.fa-file-video:after {
  content: "\f1c8 10";
}

.fad.fa-file-word:after {
  content: "\f1c2 10";
}

.fad.fa-files-medical:after {
  content: "\f7fd 10";
}

.fad.fa-fill:after {
  content: "\f575 10";
}

.fad.fa-fill-drip:after {
  content: "\f576 10";
}

.fad.fa-film:after {
  content: "\f008 10";
}

.fad.fa-film-alt:after {
  content: "\f3a0";
  visibility: hidden;
}

.fad.fa-filter:after {
  content: "\f0b0";
  visibility: hidden;
}

.fad.fa-fingerprint:after {
  content: "\f577 10";
}

.fad.fa-fire:after {
  content: "\f06d";
  visibility: hidden;
}

.fad.fa-fire-alt:after {
  content: "\f7e4 10";
}

.fad.fa-fire-extinguisher:after {
  content: "\f134 10";
}

.fad.fa-fire-smoke:after {
  content: "\f74b 10";
}

.fad.fa-fireplace:after {
  content: "\f79a 10";
}

.fad.fa-first-aid:after {
  content: "\f479 10";
}

.fad.fa-fish:after {
  content: "\f578 10";
}

.fad.fa-fish-cooked:after {
  content: "\f7fe 10";
}

.fad.fa-fist-raised:after {
  content: "\f6de 10";
}

.fad.fa-flag:after {
  content: "\f024 10";
}

.fad.fa-flag-alt:after {
  content: "\f74c 10";
}

.fad.fa-flag-checkered:after {
  content: "\f11e 10";
}

.fad.fa-flag-usa:after {
  content: "\f74d 10";
}

.fad.fa-flame:after {
  content: "\f6df 10";
}

.fad.fa-flask:after {
  content: "\f0c3 10";
}

.fad.fa-flask-poison:after {
  content: "\f6e0 10";
}

.fad.fa-flask-potion:after {
  content: "\f6e1 10";
}

.fad.fa-flower:after {
  content: "\f7ff 10";
}

.fad.fa-flower-daffodil:after {
  content: "\f800 10";
}

.fad.fa-flower-tulip:after {
  content: "\f801 10";
}

.fad.fa-flushed:after {
  content: "\f579 10";
}

.fad.fa-fog:after {
  content: "\f74e 10";
}

.fad.fa-folder:after {
  content: "\f07b";
  visibility: hidden;
}

.fad.fa-folder-minus:after {
  content: "\f65d 10";
}

.fad.fa-folder-open:after {
  content: "\f07c 10";
}

.fad.fa-folder-plus:after {
  content: "\f65e 10";
}

.fad.fa-folder-times:after {
  content: "\f65f 10";
}

.fad.fa-folder-tree:after {
  content: "\f802 10";
}

.fad.fa-folders:after {
  content: "\f660 10";
}

.fad.fa-font:after {
  content: "\f031";
  visibility: hidden;
}

.fad.fa-font-awesome-logo-full:after {
  content: "\f4e6";
  visibility: hidden;
}

.fad.fa-font-case:after {
  content: "\f866 10";
}

.fad.fa-football-ball:after {
  content: "\f44e 10";
}

.fad.fa-football-helmet:after {
  content: "\f44f 10";
}

.fad.fa-forklift:after {
  content: "\f47a 10";
}

.fad.fa-forward:after {
  content: "\f04e 10";
}

.fad.fa-fragile:after {
  content: "\f4bb 10";
}

.fad.fa-french-fries:after {
  content: "\f803 10";
}

.fad.fa-frog:after {
  content: "\f52e 10";
}

.fad.fa-frosty-head:after {
  content: "\f79b 10";
}

.fad.fa-frown:after {
  content: "\f119 10";
}

.fad.fa-frown-open:after {
  content: "\f57a 10";
}

.fad.fa-function:after {
  content: "\f661 10";
}

.fad.fa-funnel-dollar:after {
  content: "\f662 10";
}

.fad.fa-futbol:after {
  content: "\f1e3 10";
}

.fad.fa-game-board:after {
  content: "\f867 10";
}

.fad.fa-game-board-alt:after {
  content: "\f868 10";
}

.fad.fa-gamepad:after {
  content: "\f11b 10";
}

.fad.fa-gas-pump:after {
  content: "\f52f 10";
}

.fad.fa-gas-pump-slash:after {
  content: "\f5f4 10";
}

.fad.fa-gavel:after {
  content: "\f0e3 10";
}

.fad.fa-gem:after {
  content: "\f3a5 10";
}

.fad.fa-genderless:after {
  content: "\f22d";
  visibility: hidden;
}

.fad.fa-ghost:after {
  content: "\f6e2 10";
}

.fad.fa-gift:after {
  content: "\f06b 10";
}

.fad.fa-gift-card:after {
  content: "\f663 10";
}

.fad.fa-gifts:after {
  content: "\f79c 10";
}

.fad.fa-gingerbread-man:after {
  content: "\f79d 10";
}

.fad.fa-glass:after {
  content: "\f804 10";
}

.fad.fa-glass-champagne:after {
  content: "\f79e 10";
}

.fad.fa-glass-cheers:after {
  content: "\f79f 10";
}

.fad.fa-glass-citrus:after {
  content: "\f869 10";
}

.fad.fa-glass-martini:after {
  content: "\f000";
  visibility: hidden;
}

.fad.fa-glass-martini-alt:after {
  content: "\f57b 10";
}

.fad.fa-glass-whiskey:after {
  content: "\f7a0 10";
}

.fad.fa-glass-whiskey-rocks:after {
  content: "\f7a1 10";
}

.fad.fa-glasses:after {
  content: "\f530 10";
}

.fad.fa-glasses-alt:after {
  content: "\f5f5 10";
}

.fad.fa-globe:after {
  content: "\f0ac 10";
}

.fad.fa-globe-africa:after {
  content: "\f57c 10";
}

.fad.fa-globe-americas:after {
  content: "\f57d 10";
}

.fad.fa-globe-asia:after {
  content: "\f57e 10";
}

.fad.fa-globe-europe:after {
  content: "\f7a2 10";
}

.fad.fa-globe-snow:after {
  content: "\f7a3 10";
}

.fad.fa-globe-stand:after {
  content: "\f5f6 10";
}

.fad.fa-golf-ball:after {
  content: "\f450 10";
}

.fad.fa-golf-club:after {
  content: "\f451 10";
}

.fad.fa-gopuram:after {
  content: "\f664 10";
}

.fad.fa-graduation-cap:after {
  content: "\f19d 10";
}

.fad.fa-greater-than:after {
  content: "\f531";
  visibility: hidden;
}

.fad.fa-greater-than-equal:after {
  content: "\f532 10";
}

.fad.fa-grimace:after {
  content: "\f57f 10";
}

.fad.fa-grin:after {
  content: "\f580 10";
}

.fad.fa-grin-alt:after {
  content: "\f581 10";
}

.fad.fa-grin-beam:after {
  content: "\f582 10";
}

.fad.fa-grin-beam-sweat:after {
  content: "\f583 10";
}

.fad.fa-grin-hearts:after {
  content: "\f584 10";
}

.fad.fa-grin-squint:after {
  content: "\f585 10";
}

.fad.fa-grin-squint-tears:after {
  content: "\f586 10";
}

.fad.fa-grin-stars:after {
  content: "\f587 10";
}

.fad.fa-grin-tears:after {
  content: "\f588 10";
}

.fad.fa-grin-tongue:after {
  content: "\f589 10";
}

.fad.fa-grin-tongue-squint:after {
  content: "\f58a 10";
}

.fad.fa-grin-tongue-wink:after {
  content: "\f58b 10";
}

.fad.fa-grin-wink:after {
  content: "\f58c 10";
}

.fad.fa-grip-horizontal:after {
  content: "\f58d";
  visibility: hidden;
}

.fad.fa-grip-lines:after {
  content: "\f7a4";
  visibility: hidden;
}

.fad.fa-grip-lines-vertical:after {
  content: "\f7a5";
  visibility: hidden;
}

.fad.fa-grip-vertical:after {
  content: "\f58e";
  visibility: hidden;
}

.fad.fa-guitar:after {
  content: "\f7a6 10";
}

.fad.fa-h-square:after {
  content: "\f0fd 10";
}

.fad.fa-h1:after {
  content: "\f313 10";
}

.fad.fa-h2:after {
  content: "\f314 10";
}

.fad.fa-h3:after {
  content: "\f315 10";
}

.fad.fa-h4:after {
  content: "\f86a 10";
}

.fad.fa-hamburger:after {
  content: "\f805 10";
}

.fad.fa-hammer:after {
  content: "\f6e3 10";
}

.fad.fa-hammer-war:after {
  content: "\f6e4 10";
}

.fad.fa-hamsa:after {
  content: "\f665 10";
}

.fad.fa-hand-heart:after {
  content: "\f4bc 10";
}

.fad.fa-hand-holding:after {
  content: "\f4bd";
  visibility: hidden;
}

.fad.fa-hand-holding-box:after {
  content: "\f47b 10";
}

.fad.fa-hand-holding-heart:after {
  content: "\f4be 10";
}

.fad.fa-hand-holding-magic:after {
  content: "\f6e5 10";
}

.fad.fa-hand-holding-seedling:after {
  content: "\f4bf 10";
}

.fad.fa-hand-holding-usd:after {
  content: "\f4c0 10";
}

.fad.fa-hand-holding-water:after {
  content: "\f4c1 10";
}

.fad.fa-hand-lizard:after {
  content: "\f258";
  visibility: hidden;
}

.fad.fa-hand-middle-finger:after {
  content: "\f806";
  visibility: hidden;
}

.fad.fa-hand-paper:after {
  content: "\f256";
  visibility: hidden;
}

.fad.fa-hand-peace:after {
  content: "\f25b";
  visibility: hidden;
}

.fad.fa-hand-point-down:after {
  content: "\f0a7 10";
}

.fad.fa-hand-point-left:after {
  content: "\f0a5 10";
}

.fad.fa-hand-point-right:after {
  content: "\f0a4 10";
}

.fad.fa-hand-point-up:after {
  content: "\f0a6 10";
}

.fad.fa-hand-pointer:after {
  content: "\f25a 10";
}

.fad.fa-hand-receiving:after {
  content: "\f47c 10";
}

.fad.fa-hand-rock:after {
  content: "\f255";
  visibility: hidden;
}

.fad.fa-hand-scissors:after {
  content: "\f257";
  visibility: hidden;
}

.fad.fa-hand-spock:after {
  content: "\f259";
  visibility: hidden;
}

.fad.fa-hands:after {
  content: "\f4c2";
  visibility: hidden;
}

.fad.fa-hands-heart:after {
  content: "\f4c3 10";
}

.fad.fa-hands-helping:after {
  content: "\f4c4 10";
}

.fad.fa-hands-usd:after {
  content: "\f4c5 10";
}

.fad.fa-handshake:after {
  content: "\f2b5 10";
}

.fad.fa-handshake-alt:after {
  content: "\f4c6 10";
}

.fad.fa-hanukiah:after {
  content: "\f6e6 10";
}

.fad.fa-hard-hat:after {
  content: "\f807 10";
}

.fad.fa-hashtag:after {
  content: "\f292";
  visibility: hidden;
}

.fad.fa-hat-chef:after {
  content: "\f86b 10";
}

.fad.fa-hat-santa:after {
  content: "\f7a7 10";
}

.fad.fa-hat-winter:after {
  content: "\f7a8 10";
}

.fad.fa-hat-witch:after {
  content: "\f6e7 10";
}

.fad.fa-hat-wizard:after {
  content: "\f6e8 10";
}

.fad.fa-haykal:after {
  content: "\f666 10";
}

.fad.fa-hdd:after {
  content: "\f0a0 10";
}

.fad.fa-head-side:after {
  content: "\f6e9 10";
}

.fad.fa-head-side-brain:after {
  content: "\f808 10";
}

.fad.fa-head-side-medical:after {
  content: "\f809 10";
}

.fad.fa-head-vr:after {
  content: "\f6ea 10";
}

.fad.fa-heading:after {
  content: "\f1dc";
  visibility: hidden;
}

.fad.fa-headphones:after {
  content: "\f025 10";
}

.fad.fa-headphones-alt:after {
  content: "\f58f 10";
}

.fad.fa-headset:after {
  content: "\f590 10";
}

.fad.fa-heart:after {
  content: "\f004";
  visibility: hidden;
}

.fad.fa-heart-broken:after {
  content: "\f7a9 10";
}

.fad.fa-heart-circle:after {
  content: "\f4c7 10";
}

.fad.fa-heart-rate:after {
  content: "\f5f8";
  visibility: hidden;
}

.fad.fa-heart-square:after {
  content: "\f4c8 10";
}

.fad.fa-heartbeat:after {
  content: "\f21e 10";
}

.fad.fa-helicopter:after {
  content: "\f533 10";
}

.fad.fa-helmet-battle:after {
  content: "\f6eb 10";
}

.fad.fa-hexagon:after {
  content: "\f312";
  visibility: hidden;
}

.fad.fa-highlighter:after {
  content: "\f591 10";
}

.fad.fa-hiking:after {
  content: "\f6ec 10";
}

.fad.fa-hippo:after {
  content: "\f6ed";
  visibility: hidden;
}

.fad.fa-history:after {
  content: "\f1da 10";
}

.fad.fa-hockey-mask:after {
  content: "\f6ee 10";
}

.fad.fa-hockey-puck:after {
  content: "\f453 10";
}

.fad.fa-hockey-sticks:after {
  content: "\f454 10";
}

.fad.fa-holly-berry:after {
  content: "\f7aa 10";
}

.fad.fa-home:after {
  content: "\f015 10";
}

.fad.fa-home-alt:after {
  content: "\f80a 10";
}

.fad.fa-home-heart:after {
  content: "\f4c9 10";
}

.fad.fa-home-lg:after {
  content: "\f80b 10";
}

.fad.fa-home-lg-alt:after {
  content: "\f80c 10";
}

.fad.fa-hood-cloak:after {
  content: "\f6ef 10";
}

.fad.fa-horizontal-rule:after {
  content: "\f86c";
  visibility: hidden;
}

.fad.fa-horse:after {
  content: "\f6f0 10";
}

.fad.fa-horse-head:after {
  content: "\f7ab";
  visibility: hidden;
}

.fad.fa-hospital:after {
  content: "\f0f8 10";
}

.fad.fa-hospital-alt:after {
  content: "\f47d 10";
}

.fad.fa-hospital-symbol:after {
  content: "\f47e 10";
}

.fad.fa-hospital-user:after {
  content: "\f80d 10";
}

.fad.fa-hospitals:after {
  content: "\f80e 10";
}

.fad.fa-hot-tub:after {
  content: "\f593 10";
}

.fad.fa-hotdog:after {
  content: "\f80f 10";
}

.fad.fa-hotel:after {
  content: "\f594 10";
}

.fad.fa-hourglass:after {
  content: "\f254 10";
}

.fad.fa-hourglass-end:after {
  content: "\f253 10";
}

.fad.fa-hourglass-half:after {
  content: "\f252 10";
}

.fad.fa-hourglass-start:after {
  content: "\f251 10";
}

.fad.fa-house-damage:after {
  content: "\f6f1 10";
}

.fad.fa-house-flood:after {
  content: "\f74f 10";
}

.fad.fa-hryvnia:after {
  content: "\f6f2 10";
}

.fad.fa-humidity:after {
  content: "\f750 10";
}

.fad.fa-hurricane:after {
  content: "\f751 10";
}

.fad.fa-i-cursor:after {
  content: "\f246";
  visibility: hidden;
}

.fad.fa-ice-cream:after {
  content: "\f810 10";
}

.fad.fa-ice-skate:after {
  content: "\f7ac 10";
}

.fad.fa-icicles:after {
  content: "\f7ad";
  visibility: hidden;
}

.fad.fa-icons:after {
  content: "\f86d 10";
}

.fad.fa-icons-alt:after {
  content: "\f86e 10";
}

.fad.fa-id-badge:after {
  content: "\f2c1 10";
}

.fad.fa-id-card:after {
  content: "\f2c2 10";
}

.fad.fa-id-card-alt:after {
  content: "\f47f 10";
}

.fad.fa-igloo:after {
  content: "\f7ae 10";
}

.fad.fa-image:after {
  content: "\f03e 10";
}

.fad.fa-images:after {
  content: "\f302 10";
}

.fad.fa-inbox:after {
  content: "\f01c";
  visibility: hidden;
}

.fad.fa-inbox-in:after {
  content: "\f310 10";
}

.fad.fa-inbox-out:after {
  content: "\f311 10";
}

.fad.fa-indent:after {
  content: "\f03c 10";
}

.fad.fa-industry:after {
  content: "\f275";
  visibility: hidden;
}

.fad.fa-industry-alt:after {
  content: "\f3b3 10";
}

.fad.fa-infinity:after {
  content: "\f534";
  visibility: hidden;
}

.fad.fa-info:after {
  content: "\f129";
  visibility: hidden;
}

.fad.fa-info-circle:after {
  content: "\f05a 10";
}

.fad.fa-info-square:after {
  content: "\f30f 10";
}

.fad.fa-inhaler:after {
  content: "\f5f9 10";
}

.fad.fa-integral:after {
  content: "\f667";
  visibility: hidden;
}

.fad.fa-intersection:after {
  content: "\f668";
  visibility: hidden;
}

.fad.fa-inventory:after {
  content: "\f480 10";
}

.fad.fa-island-tropical:after {
  content: "\f811 10";
}

.fad.fa-italic:after {
  content: "\f033";
  visibility: hidden;
}

.fad.fa-jack-o-lantern:after {
  content: "\f30e 10";
}

.fad.fa-jedi:after {
  content: "\f669 10";
}

.fad.fa-joint:after {
  content: "\f595 10";
}

.fad.fa-journal-whills:after {
  content: "\f66a 10";
}

.fad.fa-kaaba:after {
  content: "\f66b 10";
}

.fad.fa-kerning:after {
  content: "\f86f 10";
}

.fad.fa-key:after {
  content: "\f084";
  visibility: hidden;
}

.fad.fa-key-skeleton:after {
  content: "\f6f3";
  visibility: hidden;
}

.fad.fa-keyboard:after {
  content: "\f11c 10";
}

.fad.fa-keynote:after {
  content: "\f66c 10";
}

.fad.fa-khanda:after {
  content: "\f66d 10";
}

.fad.fa-kidneys:after {
  content: "\f5fb 10";
}

.fad.fa-kiss:after {
  content: "\f596 10";
}

.fad.fa-kiss-beam:after {
  content: "\f597 10";
}

.fad.fa-kiss-wink-heart:after {
  content: "\f598 10";
}

.fad.fa-kite:after {
  content: "\f6f4 10";
}

.fad.fa-kiwi-bird:after {
  content: "\f535 10";
}

.fad.fa-knife-kitchen:after {
  content: "\f6f5 10";
}

.fad.fa-lambda:after {
  content: "\f66e";
  visibility: hidden;
}

.fad.fa-lamp:after {
  content: "\f4ca 10";
}

.fad.fa-landmark:after {
  content: "\f66f 10";
}

.fad.fa-landmark-alt:after {
  content: "\f752 10";
}

.fad.fa-language:after {
  content: "\f1ab 10";
}

.fad.fa-laptop:after {
  content: "\f109 10";
}

.fad.fa-laptop-code:after {
  content: "\f5fc 10";
}

.fad.fa-laptop-medical:after {
  content: "\f812 10";
}

.fad.fa-laugh:after {
  content: "\f599 10";
}

.fad.fa-laugh-beam:after {
  content: "\f59a 10";
}

.fad.fa-laugh-squint:after {
  content: "\f59b 10";
}

.fad.fa-laugh-wink:after {
  content: "\f59c 10";
}

.fad.fa-layer-group:after {
  content: "\f5fd 10";
}

.fad.fa-layer-minus:after {
  content: "\f5fe 10";
}

.fad.fa-layer-plus:after {
  content: "\f5ff 10";
}

.fad.fa-leaf:after {
  content: "\f06c 10";
}

.fad.fa-leaf-heart:after {
  content: "\f4cb 10";
}

.fad.fa-leaf-maple:after {
  content: "\f6f6 10";
}

.fad.fa-leaf-oak:after {
  content: "\f6f7 10";
}

.fad.fa-lemon:after {
  content: "\f094 10";
}

.fad.fa-less-than:after {
  content: "\f536";
  visibility: hidden;
}

.fad.fa-less-than-equal:after {
  content: "\f537 10";
}

.fad.fa-level-down:after {
  content: "\f149";
  visibility: hidden;
}

.fad.fa-level-down-alt:after {
  content: "\f3be";
  visibility: hidden;
}

.fad.fa-level-up:after {
  content: "\f148";
  visibility: hidden;
}

.fad.fa-level-up-alt:after {
  content: "\f3bf";
  visibility: hidden;
}

.fad.fa-life-ring:after {
  content: "\f1cd 10";
}

.fad.fa-lightbulb:after {
  content: "\f0eb 10";
}

.fad.fa-lightbulb-dollar:after {
  content: "\f670 10";
}

.fad.fa-lightbulb-exclamation:after {
  content: "\f671 10";
}

.fad.fa-lightbulb-on:after {
  content: "\f672 10";
}

.fad.fa-lightbulb-slash:after {
  content: "\f673 10";
}

.fad.fa-lights-holiday:after {
  content: "\f7b2 10";
}

.fad.fa-line-columns:after {
  content: "\f870 10";
}

.fad.fa-line-height:after {
  content: "\f871 10";
}

.fad.fa-link:after {
  content: "\f0c1 10";
}

.fad.fa-lips:after {
  content: "\f600 10";
}

.fad.fa-lira-sign:after {
  content: "\f195";
  visibility: hidden;
}

.fad.fa-list:after {
  content: "\f03a 10";
}

.fad.fa-list-alt:after {
  content: "\f022 10";
}

.fad.fa-list-ol:after {
  content: "\f0cb 10";
}

.fad.fa-list-ul:after {
  content: "\f0ca 10";
}

.fad.fa-location:after {
  content: "\f601 10";
}

.fad.fa-location-arrow:after {
  content: "\f124";
  visibility: hidden;
}

.fad.fa-location-circle:after {
  content: "\f602 10";
}

.fad.fa-location-slash:after {
  content: "\f603 10";
}

.fad.fa-lock:after {
  content: "\f023 10";
}

.fad.fa-lock-alt:after {
  content: "\f30d 10";
}

.fad.fa-lock-open:after {
  content: "\f3c1 10";
}

.fad.fa-lock-open-alt:after {
  content: "\f3c2 10";
}

.fad.fa-long-arrow-alt-down:after {
  content: "\f309";
  visibility: hidden;
}

.fad.fa-long-arrow-alt-left:after {
  content: "\f30a";
  visibility: hidden;
}

.fad.fa-long-arrow-alt-right:after {
  content: "\f30b";
  visibility: hidden;
}

.fad.fa-long-arrow-alt-up:after {
  content: "\f30c";
  visibility: hidden;
}

.fad.fa-long-arrow-down:after {
  content: "\f175";
  visibility: hidden;
}

.fad.fa-long-arrow-left:after {
  content: "\f177";
  visibility: hidden;
}

.fad.fa-long-arrow-right:after {
  content: "\f178";
  visibility: hidden;
}

.fad.fa-long-arrow-up:after {
  content: "\f176";
  visibility: hidden;
}

.fad.fa-loveseat:after {
  content: "\f4cc 10";
}

.fad.fa-low-vision:after {
  content: "\f2a8 10";
}

.fad.fa-luchador:after {
  content: "\f455 10";
}

.fad.fa-luggage-cart:after {
  content: "\f59d 10";
}

.fad.fa-lungs:after {
  content: "\f604 10";
}

.fad.fa-mace:after {
  content: "\f6f8 10";
}

.fad.fa-magic:after {
  content: "\f0d0 10";
}

.fad.fa-magnet:after {
  content: "\f076 10";
}

.fad.fa-mail-bulk:after {
  content: "\f674 10";
}

.fad.fa-mailbox:after {
  content: "\f813 10";
}

.fad.fa-male:after {
  content: "\f183 10";
}

.fad.fa-mandolin:after {
  content: "\f6f9 10";
}

.fad.fa-map:after {
  content: "\f279 10";
}

.fad.fa-map-marked:after {
  content: "\f59f 10";
}

.fad.fa-map-marked-alt:after {
  content: "\f5a0 10";
}

.fad.fa-map-marker:after {
  content: "\f041";
  visibility: hidden;
}

.fad.fa-map-marker-alt:after {
  content: "\f3c5 10";
}

.fad.fa-map-marker-alt-slash:after {
  content: "\f605 10";
}

.fad.fa-map-marker-check:after {
  content: "\f606 10";
}

.fad.fa-map-marker-edit:after {
  content: "\f607 10";
}

.fad.fa-map-marker-exclamation:after {
  content: "\f608 10";
}

.fad.fa-map-marker-minus:after {
  content: "\f609 10";
}

.fad.fa-map-marker-plus:after {
  content: "\f60a 10";
}

.fad.fa-map-marker-question:after {
  content: "\f60b 10";
}

.fad.fa-map-marker-slash:after {
  content: "\f60c 10";
}

.fad.fa-map-marker-smile:after {
  content: "\f60d 10";
}

.fad.fa-map-marker-times:after {
  content: "\f60e 10";
}

.fad.fa-map-pin:after {
  content: "\f276 10";
}

.fad.fa-map-signs:after {
  content: "\f277 10";
}

.fad.fa-marker:after {
  content: "\f5a1 10";
}

.fad.fa-mars:after {
  content: "\f222";
  visibility: hidden;
}

.fad.fa-mars-double:after {
  content: "\f227 10";
}

.fad.fa-mars-stroke:after {
  content: "\f229";
  visibility: hidden;
}

.fad.fa-mars-stroke-h:after {
  content: "\f22b";
  visibility: hidden;
}

.fad.fa-mars-stroke-v:after {
  content: "\f22a";
  visibility: hidden;
}

.fad.fa-mask:after {
  content: "\f6fa";
  visibility: hidden;
}

.fad.fa-meat:after {
  content: "\f814 10";
}

.fad.fa-medal:after {
  content: "\f5a2 10";
}

.fad.fa-medkit:after {
  content: "\f0fa 10";
}

.fad.fa-megaphone:after {
  content: "\f675 10";
}

.fad.fa-meh:after {
  content: "\f11a 10";
}

.fad.fa-meh-blank:after {
  content: "\f5a4 10";
}

.fad.fa-meh-rolling-eyes:after {
  content: "\f5a5 10";
}

.fad.fa-memory:after {
  content: "\f538 10";
}

.fad.fa-menorah:after {
  content: "\f676 10";
}

.fad.fa-mercury:after {
  content: "\f223";
  visibility: hidden;
}

.fad.fa-meteor:after {
  content: "\f753 10";
}

.fad.fa-microchip:after {
  content: "\f2db 10";
}

.fad.fa-microphone:after {
  content: "\f130 10";
}

.fad.fa-microphone-alt:after {
  content: "\f3c9 10";
}

.fad.fa-microphone-alt-slash:after {
  content: "\f539 10";
}

.fad.fa-microphone-slash:after {
  content: "\f131 10";
}

.fad.fa-microscope:after {
  content: "\f610 10";
}

.fad.fa-mind-share:after {
  content: "\f677 10";
}

.fad.fa-minus:after {
  content: "\f068";
  visibility: hidden;
}

.fad.fa-minus-circle:after {
  content: "\f056";
  visibility: hidden;
}

.fad.fa-minus-hexagon:after {
  content: "\f307 10";
}

.fad.fa-minus-octagon:after {
  content: "\f308 10";
}

.fad.fa-minus-square:after {
  content: "\f146 10";
}

.fad.fa-mistletoe:after {
  content: "\f7b4 10";
}

.fad.fa-mitten:after {
  content: "\f7b5 10";
}

.fad.fa-mobile:after {
  content: "\f10b";
  visibility: hidden;
}

.fad.fa-mobile-alt:after {
  content: "\f3cd 10";
}

.fad.fa-mobile-android:after {
  content: "\f3ce";
  visibility: hidden;
}

.fad.fa-mobile-android-alt:after {
  content: "\f3cf 10";
}

.fad.fa-money-bill:after {
  content: "\f0d6 10";
}

.fad.fa-money-bill-alt:after {
  content: "\f3d1 10";
}

.fad.fa-money-bill-wave:after {
  content: "\f53a 10";
}

.fad.fa-money-bill-wave-alt:after {
  content: "\f53b";
  visibility: hidden;
}

.fad.fa-money-check:after {
  content: "\f53c 10";
}

.fad.fa-money-check-alt:after {
  content: "\f53d 10";
}

.fad.fa-money-check-edit:after {
  content: "\f872 10";
}

.fad.fa-money-check-edit-alt:after {
  content: "\f873 10";
}

.fad.fa-monitor-heart-rate:after {
  content: "\f611 10";
}

.fad.fa-monkey:after {
  content: "\f6fb 10";
}

.fad.fa-monument:after {
  content: "\f5a6 10";
}

.fad.fa-moon:after {
  content: "\f186";
  visibility: hidden;
}

.fad.fa-moon-cloud:after {
  content: "\f754 10";
}

.fad.fa-moon-stars:after {
  content: "\f755 10";
}

.fad.fa-mortar-pestle:after {
  content: "\f5a7 10";
}

.fad.fa-mosque:after {
  content: "\f678 10";
}

.fad.fa-motorcycle:after {
  content: "\f21c 10";
}

.fad.fa-mountain:after {
  content: "\f6fc 10";
}

.fad.fa-mountains:after {
  content: "\f6fd 10";
}

.fad.fa-mouse-pointer:after {
  content: "\f245";
  visibility: hidden;
}

.fad.fa-mug:after {
  content: "\f874";
  visibility: hidden;
}

.fad.fa-mug-hot:after {
  content: "\f7b6 10";
}

.fad.fa-mug-marshmallows:after {
  content: "\f7b7 10";
}

.fad.fa-mug-tea:after {
  content: "\f875 10";
}

.fad.fa-music:after {
  content: "\f001";
  visibility: hidden;
}

.fad.fa-narwhal:after {
  content: "\f6fe 10";
}

.fad.fa-network-wired:after {
  content: "\f6ff 10";
}

.fad.fa-neuter:after {
  content: "\f22c";
  visibility: hidden;
}

.fad.fa-newspaper:after {
  content: "\f1ea 10";
}

.fad.fa-not-equal:after {
  content: "\f53e";
  visibility: hidden;
}

.fad.fa-notes-medical:after {
  content: "\f481 10";
}

.fad.fa-object-group:after {
  content: "\f247 10";
}

.fad.fa-object-ungroup:after {
  content: "\f248 10";
}

.fad.fa-octagon:after {
  content: "\f306";
  visibility: hidden;
}

.fad.fa-oil-can:after {
  content: "\f613 10";
}

.fad.fa-oil-temp:after {
  content: "\f614 10";
}

.fad.fa-om:after {
  content: "\f679 10";
}

.fad.fa-omega:after {
  content: "\f67a";
  visibility: hidden;
}

.fad.fa-ornament:after {
  content: "\f7b8 10";
}

.fad.fa-otter:after {
  content: "\f700 10";
}

.fad.fa-outdent:after {
  content: "\f03b 10";
}

.fad.fa-overline:after {
  content: "\f876 10";
}

.fad.fa-page-break:after {
  content: "\f877 10";
}

.fad.fa-pager:after {
  content: "\f815 10";
}

.fad.fa-paint-brush:after {
  content: "\f1fc 10";
}

.fad.fa-paint-brush-alt:after {
  content: "\f5a9 10";
}

.fad.fa-paint-roller:after {
  content: "\f5aa 10";
}

.fad.fa-palette:after {
  content: "\f53f 10";
}

.fad.fa-pallet:after {
  content: "\f482 10";
}

.fad.fa-pallet-alt:after {
  content: "\f483 10";
}

.fad.fa-paper-plane:after {
  content: "\f1d8 10";
}

.fad.fa-paperclip:after {
  content: "\f0c6";
  visibility: hidden;
}

.fad.fa-parachute-box:after {
  content: "\f4cd 10";
}

.fad.fa-paragraph:after {
  content: "\f1dd";
  visibility: hidden;
}

.fad.fa-paragraph-rtl:after {
  content: "\f878 10";
}

.fad.fa-parking:after {
  content: "\f540 10";
}

.fad.fa-parking-circle:after {
  content: "\f615 10";
}

.fad.fa-parking-circle-slash:after {
  content: "\f616 10";
}

.fad.fa-parking-slash:after {
  content: "\f617 10";
}

.fad.fa-passport:after {
  content: "\f5ab 10";
}

.fad.fa-pastafarianism:after {
  content: "\f67b 10";
}

.fad.fa-paste:after {
  content: "\f0ea 10";
}

.fad.fa-pause:after {
  content: "\f04c";
  visibility: hidden;
}

.fad.fa-pause-circle:after {
  content: "\f28b 10";
}

.fad.fa-paw:after {
  content: "\f1b0 10";
}

.fad.fa-paw-alt:after {
  content: "\f701 10";
}

.fad.fa-paw-claws:after {
  content: "\f702 10";
}

.fad.fa-peace:after {
  content: "\f67c";
  visibility: hidden;
}

.fad.fa-pegasus:after {
  content: "\f703 10";
}

.fad.fa-pen:after {
  content: "\f304 10";
}

.fad.fa-pen-alt:after {
  content: "\f305 10";
}

.fad.fa-pen-fancy:after {
  content: "\f5ac 10";
}

.fad.fa-pen-nib:after {
  content: "\f5ad 10";
}

.fad.fa-pen-square:after {
  content: "\f14b 10";
}

.fad.fa-pencil:after {
  content: "\f040 10";
}

.fad.fa-pencil-alt:after {
  content: "\f303 10";
}

.fad.fa-pencil-paintbrush:after {
  content: "\f618 10";
}

.fad.fa-pencil-ruler:after {
  content: "\f5ae 10";
}

.fad.fa-pennant:after {
  content: "\f456 10";
}

.fad.fa-people-carry:after {
  content: "\f4ce 10";
}

.fad.fa-pepper-hot:after {
  content: "\f816 10";
}

.fad.fa-percent:after {
  content: "\f295 10";
}

.fad.fa-percentage:after {
  content: "\f541 10";
}

.fad.fa-person-booth:after {
  content: "\f756 10";
}

.fad.fa-person-carry:after {
  content: "\f4cf 10";
}

.fad.fa-person-dolly:after {
  content: "\f4d0 10";
}

.fad.fa-person-dolly-empty:after {
  content: "\f4d1 10";
}

.fad.fa-person-sign:after {
  content: "\f757 10";
}

.fad.fa-phone:after {
  content: "\f095";
  visibility: hidden;
}

.fad.fa-phone-laptop:after {
  content: "\f87a 10";
}

.fad.fa-phone-office:after {
  content: "\f67d 10";
}

.fad.fa-phone-plus:after {
  content: "\f4d2 10";
}

.fad.fa-phone-slash:after {
  content: "\f3dd 10";
}

.fad.fa-phone-square:after {
  content: "\f098 10";
}

.fad.fa-phone-volume:after {
  content: "\f2a0 10";
}

.fad.fa-photo-video:after {
  content: "\f87c 10";
}

.fad.fa-pi:after {
  content: "\f67e";
  visibility: hidden;
}

.fad.fa-pie:after {
  content: "\f705 10";
}

.fad.fa-pig:after {
  content: "\f706 10";
}

.fad.fa-piggy-bank:after {
  content: "\f4d3 10";
}

.fad.fa-pills:after {
  content: "\f484 10";
}

.fad.fa-pizza:after {
  content: "\f817 10";
}

.fad.fa-pizza-slice:after {
  content: "\f818 10";
}

.fad.fa-place-of-worship:after {
  content: "\f67f 10";
}

.fad.fa-plane:after {
  content: "\f072 10";
}

.fad.fa-plane-alt:after {
  content: "\f3de 10";
}

.fad.fa-plane-arrival:after {
  content: "\f5af 10";
}

.fad.fa-plane-departure:after {
  content: "\f5b0 10";
}

.fad.fa-play:after {
  content: "\f04b";
  visibility: hidden;
}

.fad.fa-play-circle:after {
  content: "\f144 10";
}

.fad.fa-plug:after {
  content: "\f1e6 10";
}

.fad.fa-plus:after {
  content: "\f067";
  visibility: hidden;
}

.fad.fa-plus-circle:after {
  content: "\f055 10";
}

.fad.fa-plus-hexagon:after {
  content: "\f300 10";
}

.fad.fa-plus-octagon:after {
  content: "\f301 10";
}

.fad.fa-plus-square:after {
  content: "\f0fe 10";
}

.fad.fa-podcast:after {
  content: "\f2ce 10";
}

.fad.fa-podium:after {
  content: "\f680 10";
}

.fad.fa-podium-star:after {
  content: "\f758 10";
}

.fad.fa-poll:after {
  content: "\f681 10";
}

.fad.fa-poll-h:after {
  content: "\f682 10";
}

.fad.fa-poll-people:after {
  content: "\f759 10";
}

.fad.fa-poo:after {
  content: "\f2fe 10";
}

.fad.fa-poo-storm:after {
  content: "\f75a 10";
}

.fad.fa-poop:after {
  content: "\f619";
  visibility: hidden;
}

.fad.fa-popcorn:after {
  content: "\f819 10";
}

.fad.fa-portrait:after {
  content: "\f3e0 10";
}

.fad.fa-pound-sign:after {
  content: "\f154";
  visibility: hidden;
}

.fad.fa-power-off:after {
  content: "\f011 10";
}

.fad.fa-pray:after {
  content: "\f683 10";
}

.fad.fa-praying-hands:after {
  content: "\f684 10";
}

.fad.fa-prescription:after {
  content: "\f5b1";
  visibility: hidden;
}

.fad.fa-prescription-bottle:after {
  content: "\f485 10";
}

.fad.fa-prescription-bottle-alt:after {
  content: "\f486 10";
}

.fad.fa-presentation:after {
  content: "\f685 10";
}

.fad.fa-print:after {
  content: "\f02f 10";
}

.fad.fa-print-search:after {
  content: "\f81a 10";
}

.fad.fa-print-slash:after {
  content: "\f686 10";
}

.fad.fa-procedures:after {
  content: "\f487 10";
}

.fad.fa-project-diagram:after {
  content: "\f542 10";
}

.fad.fa-pumpkin:after {
  content: "\f707 10";
}

.fad.fa-puzzle-piece:after {
  content: "\f12e";
  visibility: hidden;
}

.fad.fa-qrcode:after {
  content: "\f029 10";
}

.fad.fa-question:after {
  content: "\f128";
  visibility: hidden;
}

.fad.fa-question-circle:after {
  content: "\f059 10";
}

.fad.fa-question-square:after {
  content: "\f2fd 10";
}

.fad.fa-quidditch:after {
  content: "\f458 10";
}

.fad.fa-quote-left:after {
  content: "\f10d";
  visibility: hidden;
}

.fad.fa-quote-right:after {
  content: "\f10e";
  visibility: hidden;
}

.fad.fa-quran:after {
  content: "\f687 10";
}

.fad.fa-rabbit:after {
  content: "\f708 10";
}

.fad.fa-rabbit-fast:after {
  content: "\f709 10";
}

.fad.fa-racquet:after {
  content: "\f45a 10";
}

.fad.fa-radiation:after {
  content: "\f7b9 10";
}

.fad.fa-radiation-alt:after {
  content: "\f7ba 10";
}

.fad.fa-rainbow:after {
  content: "\f75b 10";
}

.fad.fa-raindrops:after {
  content: "\f75c 10";
}

.fad.fa-ram:after {
  content: "\f70a 10";
}

.fad.fa-ramp-loading:after {
  content: "\f4d4 10";
}

.fad.fa-random:after {
  content: "\f074 10";
}

.fad.fa-receipt:after {
  content: "\f543 10";
}

.fad.fa-rectangle-landscape:after {
  content: "\f2fa";
  visibility: hidden;
}

.fad.fa-rectangle-portrait:after {
  content: "\f2fb";
  visibility: hidden;
}

.fad.fa-rectangle-wide:after {
  content: "\f2fc";
  visibility: hidden;
}

.fad.fa-recycle:after {
  content: "\f1b8";
  visibility: hidden;
}

.fad.fa-redo:after {
  content: "\f01e";
  visibility: hidden;
}

.fad.fa-redo-alt:after {
  content: "\f2f9";
  visibility: hidden;
}

.fad.fa-registered:after {
  content: "\f25d 10";
}

.fad.fa-remove-format:after {
  content: "\f87d 10";
}

.fad.fa-repeat:after {
  content: "\f363 10";
}

.fad.fa-repeat-1:after {
  content: "\f365 10";
}

.fad.fa-repeat-1-alt:after {
  content: "\f366 10";
}

.fad.fa-repeat-alt:after {
  content: "\f364 10";
}

.fad.fa-reply:after {
  content: "\f3e5";
  visibility: hidden;
}

.fad.fa-reply-all:after {
  content: "\f122 10";
}

.fad.fa-republican:after {
  content: "\f75e 10";
}

.fad.fa-restroom:after {
  content: "\f7bd 10";
}

.fad.fa-retweet:after {
  content: "\f079 10";
}

.fad.fa-retweet-alt:after {
  content: "\f361 10";
}

.fad.fa-ribbon:after {
  content: "\f4d6 10";
}

.fad.fa-ring:after {
  content: "\f70b 10";
}

.fad.fa-rings-wedding:after {
  content: "\f81b 10";
}

.fad.fa-road:after {
  content: "\f018 10";
}

.fad.fa-robot:after {
  content: "\f544 10";
}

.fad.fa-rocket:after {
  content: "\f135 10";
}

.fad.fa-route:after {
  content: "\f4d7 10";
}

.fad.fa-route-highway:after {
  content: "\f61a 10";
}

.fad.fa-route-interstate:after {
  content: "\f61b 10";
}

.fad.fa-rss:after {
  content: "\f09e 10";
}

.fad.fa-rss-square:after {
  content: "\f143 10";
}

.fad.fa-ruble-sign:after {
  content: "\f158";
  visibility: hidden;
}

.fad.fa-ruler:after {
  content: "\f545 10";
}

.fad.fa-ruler-combined:after {
  content: "\f546 10";
}

.fad.fa-ruler-horizontal:after {
  content: "\f547 10";
}

.fad.fa-ruler-triangle:after {
  content: "\f61c 10";
}

.fad.fa-ruler-vertical:after {
  content: "\f548 10";
}

.fad.fa-running:after {
  content: "\f70c 10";
}

.fad.fa-rupee-sign:after {
  content: "\f156";
  visibility: hidden;
}

.fad.fa-rv:after {
  content: "\f7be 10";
}

.fad.fa-sack:after {
  content: "\f81c";
  visibility: hidden;
}

.fad.fa-sack-dollar:after {
  content: "\f81d 10";
}

.fad.fa-sad-cry:after {
  content: "\f5b3 10";
}

.fad.fa-sad-tear:after {
  content: "\f5b4 10";
}

.fad.fa-salad:after {
  content: "\f81e 10";
}

.fad.fa-sandwich:after {
  content: "\f81f 10";
}

.fad.fa-satellite:after {
  content: "\f7bf 10";
}

.fad.fa-satellite-dish:after {
  content: "\f7c0 10";
}

.fad.fa-sausage:after {
  content: "\f820 10";
}

.fad.fa-save:after {
  content: "\f0c7 10";
}

.fad.fa-scalpel:after {
  content: "\f61d 10";
}

.fad.fa-scalpel-path:after {
  content: "\f61e 10";
}

.fad.fa-scanner:after {
  content: "\f488 10";
}

.fad.fa-scanner-keyboard:after {
  content: "\f489 10";
}

.fad.fa-scanner-touchscreen:after {
  content: "\f48a 10";
}

.fad.fa-scarecrow:after {
  content: "\f70d 10";
}

.fad.fa-scarf:after {
  content: "\f7c1 10";
}

.fad.fa-school:after {
  content: "\f549 10";
}

.fad.fa-screwdriver:after {
  content: "\f54a 10";
}

.fad.fa-scroll:after {
  content: "\f70e 10";
}

.fad.fa-scroll-old:after {
  content: "\f70f 10";
}

.fad.fa-scrubber:after {
  content: "\f2f8";
  visibility: hidden;
}

.fad.fa-scythe:after {
  content: "\f710 10";
}

.fad.fa-sd-card:after {
  content: "\f7c2 10";
}

.fad.fa-search:after {
  content: "\f002 10";
}

.fad.fa-search-dollar:after {
  content: "\f688 10";
}

.fad.fa-search-location:after {
  content: "\f689 10";
}

.fad.fa-search-minus:after {
  content: "\f010 10";
}

.fad.fa-search-plus:after {
  content: "\f00e 10";
}

.fad.fa-seedling:after {
  content: "\f4d8 10";
}

.fad.fa-send-back:after {
  content: "\f87e 10";
}

.fad.fa-send-backward:after {
  content: "\f87f 10";
}

.fad.fa-server:after {
  content: "\f233 10";
}

.fad.fa-shapes:after {
  content: "\f61f 10";
}

.fad.fa-share:after {
  content: "\f064";
  visibility: hidden;
}

.fad.fa-share-all:after {
  content: "\f367 10";
}

.fad.fa-share-alt:after {
  content: "\f1e0 10";
}

.fad.fa-share-alt-square:after {
  content: "\f1e1 10";
}

.fad.fa-share-square:after {
  content: "\f14d 10";
}

.fad.fa-sheep:after {
  content: "\f711 10";
}

.fad.fa-shekel-sign:after {
  content: "\f20b 10";
}

.fad.fa-shield:after {
  content: "\f132";
  visibility: hidden;
}

.fad.fa-shield-alt:after {
  content: "\f3ed 10";
}

.fad.fa-shield-check:after {
  content: "\f2f7 10";
}

.fad.fa-shield-cross:after {
  content: "\f712 10";
}

.fad.fa-ship:after {
  content: "\f21a 10";
}

.fad.fa-shipping-fast:after {
  content: "\f48b 10";
}

.fad.fa-shipping-timed:after {
  content: "\f48c 10";
}

.fad.fa-shish-kebab:after {
  content: "\f821 10";
}

.fad.fa-shoe-prints:after {
  content: "\f54b 10";
}

.fad.fa-shopping-bag:after {
  content: "\f290 10";
}

.fad.fa-shopping-basket:after {
  content: "\f291 10";
}

.fad.fa-shopping-cart:after {
  content: "\f07a 10";
}

.fad.fa-shovel:after {
  content: "\f713";
  visibility: hidden;
}

.fad.fa-shovel-snow:after {
  content: "\f7c3 10";
}

.fad.fa-shower:after {
  content: "\f2cc 10";
}

.fad.fa-shredder:after {
  content: "\f68a 10";
}

.fad.fa-shuttle-van:after {
  content: "\f5b6 10";
}

.fad.fa-shuttlecock:after {
  content: "\f45b 10";
}

.fad.fa-sickle:after {
  content: "\f822 10";
}

.fad.fa-sigma:after {
  content: "\f68b";
  visibility: hidden;
}

.fad.fa-sign:after {
  content: "\f4d9 10";
}

.fad.fa-sign-in:after {
  content: "\f090 10";
}

.fad.fa-sign-in-alt:after {
  content: "\f2f6 10";
}

.fad.fa-sign-language:after {
  content: "\f2a7 10";
}

.fad.fa-sign-out:after {
  content: "\f08b 10";
}

.fad.fa-sign-out-alt:after {
  content: "\f2f5 10";
}

.fad.fa-signal:after {
  content: "\f012 10";
}

.fad.fa-signal-1:after {
  content: "\f68c";
  visibility: hidden;
}

.fad.fa-signal-2:after {
  content: "\f68d";
  visibility: hidden;
}

.fad.fa-signal-3:after {
  content: "\f68e 10";
}

.fad.fa-signal-4:after {
  content: "\f68f 10";
}

.fad.fa-signal-alt:after {
  content: "\f690 10";
}

.fad.fa-signal-alt-1:after {
  content: "\f691";
  visibility: hidden;
}

.fad.fa-signal-alt-2:after {
  content: "\f692";
  visibility: hidden;
}

.fad.fa-signal-alt-3:after {
  content: "\f693 10";
}

.fad.fa-signal-alt-slash:after {
  content: "\f694 10";
}

.fad.fa-signal-slash:after {
  content: "\f695 10";
}

.fad.fa-signature:after {
  content: "\f5b7";
  visibility: hidden;
}

.fad.fa-sim-card:after {
  content: "\f7c4 10";
}

.fad.fa-sitemap:after {
  content: "\f0e8 10";
}

.fad.fa-skating:after {
  content: "\f7c5 10";
}

.fad.fa-skeleton:after {
  content: "\f620 10";
}

.fad.fa-ski-jump:after {
  content: "\f7c7 10";
}

.fad.fa-ski-lift:after {
  content: "\f7c8 10";
}

.fad.fa-skiing:after {
  content: "\f7c9 10";
}

.fad.fa-skiing-nordic:after {
  content: "\f7ca 10";
}

.fad.fa-skull:after {
  content: "\f54c 10";
}

.fad.fa-skull-crossbones:after {
  content: "\f714 10";
}

.fad.fa-slash:after {
  content: "\f715";
  visibility: hidden;
}

.fad.fa-sledding:after {
  content: "\f7cb 10";
}

.fad.fa-sleigh:after {
  content: "\f7cc 10";
}

.fad.fa-sliders-h:after {
  content: "\f1de 10";
}

.fad.fa-sliders-h-square:after {
  content: "\f3f0 10";
}

.fad.fa-sliders-v:after {
  content: "\f3f1 10";
}

.fad.fa-sliders-v-square:after {
  content: "\f3f2 10";
}

.fad.fa-smile:after {
  content: "\f118 10";
}

.fad.fa-smile-beam:after {
  content: "\f5b8 10";
}

.fad.fa-smile-plus:after {
  content: "\f5b9 10";
}

.fad.fa-smile-wink:after {
  content: "\f4da 10";
}

.fad.fa-smog:after {
  content: "\f75f 10";
}

.fad.fa-smoke:after {
  content: "\f760 10";
}

.fad.fa-smoking:after {
  content: "\f48d 10";
}

.fad.fa-smoking-ban:after {
  content: "\f54d 10";
}

.fad.fa-sms:after {
  content: "\f7cd 10";
}

.fad.fa-snake:after {
  content: "\f716";
  visibility: hidden;
}

.fad.fa-snooze:after {
  content: "\f880 10";
}

.fad.fa-snow-blowing:after {
  content: "\f761 10";
}

.fad.fa-snowboarding:after {
  content: "\f7ce 10";
}

.fad.fa-snowflake:after {
  content: "\f2dc";
  visibility: hidden;
}

.fad.fa-snowflakes:after {
  content: "\f7cf 10";
}

.fad.fa-snowman:after {
  content: "\f7d0 10";
}

.fad.fa-snowmobile:after {
  content: "\f7d1 10";
}

.fad.fa-snowplow:after {
  content: "\f7d2 10";
}

.fad.fa-socks:after {
  content: "\f696 10";
}

.fad.fa-solar-panel:after {
  content: "\f5ba 10";
}

.fad.fa-sort:after {
  content: "\f0dc 10";
}

.fad.fa-sort-alpha-down:after {
  content: "\f15d 10";
}

.fad.fa-sort-alpha-down-alt:after {
  content: "\f881 10";
}

.fad.fa-sort-alpha-up:after {
  content: "\f15e 10";
}

.fad.fa-sort-alpha-up-alt:after {
  content: "\f882 10";
}

.fad.fa-sort-alt:after {
  content: "\f883 10";
}

.fad.fa-sort-amount-down:after {
  content: "\f160 10";
}

.fad.fa-sort-amount-down-alt:after {
  content: "\f884 10";
}

.fad.fa-sort-amount-up:after {
  content: "\f161 10";
}

.fad.fa-sort-amount-up-alt:after {
  content: "\f885 10";
}

.fad.fa-sort-down:after {
  content: "\f0dd";
  visibility: hidden;
}

.fad.fa-sort-numeric-down:after {
  content: "\f162 10";
}

.fad.fa-sort-numeric-down-alt:after {
  content: "\f886 10";
}

.fad.fa-sort-numeric-up:after {
  content: "\f163 10";
}

.fad.fa-sort-numeric-up-alt:after {
  content: "\f887 10";
}

.fad.fa-sort-shapes-down:after {
  content: "\f888 10";
}

.fad.fa-sort-shapes-down-alt:after {
  content: "\f889 10";
}

.fad.fa-sort-shapes-up:after {
  content: "\f88a 10";
}

.fad.fa-sort-shapes-up-alt:after {
  content: "\f88b 10";
}

.fad.fa-sort-size-down:after {
  content: "\f88c 10";
}

.fad.fa-sort-size-down-alt:after {
  content: "\f88d 10";
}

.fad.fa-sort-size-up:after {
  content: "\f88e 10";
}

.fad.fa-sort-size-up-alt:after {
  content: "\f88f 10";
}

.fad.fa-sort-up:after {
  content: "\f0de";
  visibility: hidden;
}

.fad.fa-soup:after {
  content: "\f823 10";
}

.fad.fa-spa:after {
  content: "\f5bb 10";
}

.fad.fa-space-shuttle:after {
  content: "\f197 10";
}

.fad.fa-spade:after {
  content: "\f2f4";
  visibility: hidden;
}

.fad.fa-sparkles:after {
  content: "\f890 10";
}

.fad.fa-spell-check:after {
  content: "\f891 10";
}

.fad.fa-spider:after {
  content: "\f717 10";
}

.fad.fa-spider-black-widow:after {
  content: "\f718 10";
}

.fad.fa-spider-web:after {
  content: "\f719";
  visibility: hidden;
}

.fad.fa-spinner:after {
  content: "\f110 10";
}

.fad.fa-spinner-third:after {
  content: "\f3f4";
  visibility: hidden;
}

.fad.fa-splotch:after {
  content: "\f5bc";
  visibility: hidden;
}

.fad.fa-spray-can:after {
  content: "\f5bd 10";
}

.fad.fa-square:after {
  content: "\f0c8";
  visibility: hidden;
}

.fad.fa-square-full:after {
  content: "\f45c";
  visibility: hidden;
}

.fad.fa-square-root:after {
  content: "\f697";
  visibility: hidden;
}

.fad.fa-square-root-alt:after {
  content: "\f698 10";
}

.fad.fa-squirrel:after {
  content: "\f71a 10";
}

.fad.fa-staff:after {
  content: "\f71b";
  visibility: hidden;
}

.fad.fa-stamp:after {
  content: "\f5bf 10";
}

.fad.fa-star:after {
  content: "\f005";
  visibility: hidden;
}

.fad.fa-star-and-crescent:after {
  content: "\f699 10";
}

.fad.fa-star-christmas:after {
  content: "\f7d4 10";
}

.fad.fa-star-exclamation:after {
  content: "\f2f3 10";
}

.fad.fa-star-half:after {
  content: "\f089";
  visibility: hidden;
}

.fad.fa-star-half-alt:after {
  content: "\f5c0 10";
}

.fad.fa-star-of-david:after {
  content: "\f69a";
  visibility: hidden;
}

.fad.fa-star-of-life:after {
  content: "\f621";
  visibility: hidden;
}

.fad.fa-stars:after {
  content: "\f762 10";
}

.fad.fa-steak:after {
  content: "\f824 10";
}

.fad.fa-steering-wheel:after {
  content: "\f622 10";
}

.fad.fa-step-backward:after {
  content: "\f048 10";
}

.fad.fa-step-forward:after {
  content: "\f051 10";
}

.fad.fa-stethoscope:after {
  content: "\f0f1 10";
}

.fad.fa-sticky-note:after {
  content: "\f249 10";
}

.fad.fa-stocking:after {
  content: "\f7d5 10";
}

.fad.fa-stomach:after {
  content: "\f623 10";
}

.fad.fa-stop:after {
  content: "\f04d";
  visibility: hidden;
}

.fad.fa-stop-circle:after {
  content: "\f28d 10";
}

.fad.fa-stopwatch:after {
  content: "\f2f2 10";
}

.fad.fa-store:after {
  content: "\f54e 10";
}

.fad.fa-store-alt:after {
  content: "\f54f 10";
}

.fad.fa-stream:after {
  content: "\f550 10";
}

.fad.fa-street-view:after {
  content: "\f21d 10";
}

.fad.fa-stretcher:after {
  content: "\f825 10";
}

.fad.fa-strikethrough:after {
  content: "\f0cc 10";
}

.fad.fa-stroopwafel:after {
  content: "\f551 10";
}

.fad.fa-subscript:after {
  content: "\f12c 10";
}

.fad.fa-subway:after {
  content: "\f239 10";
}

.fad.fa-suitcase:after {
  content: "\f0f2 10";
}

.fad.fa-suitcase-rolling:after {
  content: "\f5c1 10";
}

.fad.fa-sun:after {
  content: "\f185 10";
}

.fad.fa-sun-cloud:after {
  content: "\f763 10";
}

.fad.fa-sun-dust:after {
  content: "\f764 10";
}

.fad.fa-sun-haze:after {
  content: "\f765 10";
}

.fad.fa-sunglasses:after {
  content: "\f892 10";
}

.fad.fa-sunrise:after {
  content: "\f766 10";
}

.fad.fa-sunset:after {
  content: "\f767 10";
}

.fad.fa-superscript:after {
  content: "\f12b 10";
}

.fad.fa-surprise:after {
  content: "\f5c2 10";
}

.fad.fa-swatchbook:after {
  content: "\f5c3 10";
}

.fad.fa-swimmer:after {
  content: "\f5c4 10";
}

.fad.fa-swimming-pool:after {
  content: "\f5c5 10";
}

.fad.fa-sword:after {
  content: "\f71c 10";
}

.fad.fa-swords:after {
  content: "\f71d 10";
}

.fad.fa-synagogue:after {
  content: "\f69b 10";
}

.fad.fa-sync:after {
  content: "\f021 10";
}

.fad.fa-sync-alt:after {
  content: "\f2f1 10";
}

.fad.fa-syringe:after {
  content: "\f48e 10";
}

.fad.fa-table:after {
  content: "\f0ce 10";
}

.fad.fa-table-tennis:after {
  content: "\f45d 10";
}

.fad.fa-tablet:after {
  content: "\f10a";
  visibility: hidden;
}

.fad.fa-tablet-alt:after {
  content: "\f3fa 10";
}

.fad.fa-tablet-android:after {
  content: "\f3fb";
  visibility: hidden;
}

.fad.fa-tablet-android-alt:after {
  content: "\f3fc 10";
}

.fad.fa-tablet-rugged:after {
  content: "\f48f 10";
}

.fad.fa-tablets:after {
  content: "\f490 10";
}

.fad.fa-tachometer:after {
  content: "\f0e4 10";
}

.fad.fa-tachometer-alt:after {
  content: "\f3fd 10";
}

.fad.fa-tachometer-alt-average:after {
  content: "\f624 10";
}

.fad.fa-tachometer-alt-fast:after {
  content: "\f625 10";
}

.fad.fa-tachometer-alt-fastest:after {
  content: "\f626 10";
}

.fad.fa-tachometer-alt-slow:after {
  content: "\f627 10";
}

.fad.fa-tachometer-alt-slowest:after {
  content: "\f628 10";
}

.fad.fa-tachometer-average:after {
  content: "\f629 10";
}

.fad.fa-tachometer-fast:after {
  content: "\f62a 10";
}

.fad.fa-tachometer-fastest:after {
  content: "\f62b 10";
}

.fad.fa-tachometer-slow:after {
  content: "\f62c 10";
}

.fad.fa-tachometer-slowest:after {
  content: "\f62d 10";
}

.fad.fa-taco:after {
  content: "\f826 10";
}

.fad.fa-tag:after {
  content: "\f02b";
  visibility: hidden;
}

.fad.fa-tags:after {
  content: "\f02c 10";
}

.fad.fa-tally:after {
  content: "\f69c 10";
}

.fad.fa-tanakh:after {
  content: "\f827 10";
}

.fad.fa-tape:after {
  content: "\f4db 10";
}

.fad.fa-tasks:after {
  content: "\f0ae 10";
}

.fad.fa-tasks-alt:after {
  content: "\f828 10";
}

.fad.fa-taxi:after {
  content: "\f1ba 10";
}

.fad.fa-teeth:after {
  content: "\f62e 10";
}

.fad.fa-teeth-open:after {
  content: "\f62f 10";
}

.fad.fa-temperature-frigid:after {
  content: "\f768 10";
}

.fad.fa-temperature-high:after {
  content: "\f769 10";
}

.fad.fa-temperature-hot:after {
  content: "\f76a 10";
}

.fad.fa-temperature-low:after {
  content: "\f76b 10";
}

.fad.fa-tenge:after {
  content: "\f7d7";
  visibility: hidden;
}

.fad.fa-tennis-ball:after {
  content: "\f45e 10";
}

.fad.fa-terminal:after {
  content: "\f120 10";
}

.fad.fa-text:after {
  content: "\f893";
  visibility: hidden;
}

.fad.fa-text-height:after {
  content: "\f034 10";
}

.fad.fa-text-size:after {
  content: "\f894 10";
}

.fad.fa-text-width:after {
  content: "\f035 10";
}

.fad.fa-th:after {
  content: "\f00a 10";
}

.fad.fa-th-large:after {
  content: "\f009 10";
}

.fad.fa-th-list:after {
  content: "\f00b 10";
}

.fad.fa-theater-masks:after {
  content: "\f630 10";
}

.fad.fa-thermometer:after {
  content: "\f491 10";
}

.fad.fa-thermometer-empty:after {
  content: "\f2cb 10";
}

.fad.fa-thermometer-full:after {
  content: "\f2c7 10";
}

.fad.fa-thermometer-half:after {
  content: "\f2c9 10";
}

.fad.fa-thermometer-quarter:after {
  content: "\f2ca 10";
}

.fad.fa-thermometer-three-quarters:after {
  content: "\f2c8 10";
}

.fad.fa-theta:after {
  content: "\f69e";
  visibility: hidden;
}

.fad.fa-thumbs-down:after {
  content: "\f165 10";
}

.fad.fa-thumbs-up:after {
  content: "\f164 10";
}

.fad.fa-thumbtack:after {
  content: "\f08d 10";
}

.fad.fa-thunderstorm:after {
  content: "\f76c 10";
}

.fad.fa-thunderstorm-moon:after {
  content: "\f76d 10";
}

.fad.fa-thunderstorm-sun:after {
  content: "\f76e 10";
}

.fad.fa-ticket:after {
  content: "\f145";
  visibility: hidden;
}

.fad.fa-ticket-alt:after {
  content: "\f3ff 10";
}

.fad.fa-tilde:after {
  content: "\f69f";
  visibility: hidden;
}

.fad.fa-times:after {
  content: "\f00d";
  visibility: hidden;
}

.fad.fa-times-circle:after {
  content: "\f057 10";
}

.fad.fa-times-hexagon:after {
  content: "\f2ee 10";
}

.fad.fa-times-octagon:after {
  content: "\f2f0 10";
}

.fad.fa-times-square:after {
  content: "\f2d3 10";
}

.fad.fa-tint:after {
  content: "\f043 10";
}

.fad.fa-tint-slash:after {
  content: "\f5c7 10";
}

.fad.fa-tire:after {
  content: "\f631 10";
}

.fad.fa-tire-flat:after {
  content: "\f632 10";
}

.fad.fa-tire-pressure-warning:after {
  content: "\f633 10";
}

.fad.fa-tire-rugged:after {
  content: "\f634 10";
}

.fad.fa-tired:after {
  content: "\f5c8 10";
}

.fad.fa-toggle-off:after {
  content: "\f204 10";
}

.fad.fa-toggle-on:after {
  content: "\f205 10";
}

.fad.fa-toilet:after {
  content: "\f7d8 10";
}

.fad.fa-toilet-paper:after {
  content: "\f71e 10";
}

.fad.fa-toilet-paper-alt:after {
  content: "\f71f 10";
}

.fad.fa-tombstone:after {
  content: "\f720 10";
}

.fad.fa-tombstone-alt:after {
  content: "\f721 10";
}

.fad.fa-toolbox:after {
  content: "\f552 10";
}

.fad.fa-tools:after {
  content: "\f7d9 10";
}

.fad.fa-tooth:after {
  content: "\f5c9";
  visibility: hidden;
}

.fad.fa-toothbrush:after {
  content: "\f635 10";
}

.fad.fa-torah:after {
  content: "\f6a0 10";
}

.fad.fa-torii-gate:after {
  content: "\f6a1 10";
}

.fad.fa-tornado:after {
  content: "\f76f 10";
}

.fad.fa-tractor:after {
  content: "\f722 10";
}

.fad.fa-trademark:after {
  content: "\f25c";
  visibility: hidden;
}

.fad.fa-traffic-cone:after {
  content: "\f636 10";
}

.fad.fa-traffic-light:after {
  content: "\f637 10";
}

.fad.fa-traffic-light-go:after {
  content: "\f638 10";
}

.fad.fa-traffic-light-slow:after {
  content: "\f639 10";
}

.fad.fa-traffic-light-stop:after {
  content: "\f63a 10";
}

.fad.fa-train:after {
  content: "\f238 10";
}

.fad.fa-tram:after {
  content: "\f7da 10";
}

.fad.fa-transgender:after {
  content: "\f224";
  visibility: hidden;
}

.fad.fa-transgender-alt:after {
  content: "\f225";
  visibility: hidden;
}

.fad.fa-trash:after {
  content: "\f1f8 10";
}

.fad.fa-trash-alt:after {
  content: "\f2ed 10";
}

.fad.fa-trash-restore:after {
  content: "\f829 10";
}

.fad.fa-trash-restore-alt:after {
  content: "\f82a 10";
}

.fad.fa-trash-undo:after {
  content: "\f895 10";
}

.fad.fa-trash-undo-alt:after {
  content: "\f896 10";
}

.fad.fa-treasure-chest:after {
  content: "\f723 10";
}

.fad.fa-tree:after {
  content: "\f1bb 10";
}

.fad.fa-tree-alt:after {
  content: "\f400 10";
}

.fad.fa-tree-christmas:after {
  content: "\f7db 10";
}

.fad.fa-tree-decorated:after {
  content: "\f7dc 10";
}

.fad.fa-tree-large:after {
  content: "\f7dd";
  visibility: hidden;
}

.fad.fa-tree-palm:after {
  content: "\f82b 10";
}

.fad.fa-trees:after {
  content: "\f724 10";
}

.fad.fa-triangle:after {
  content: "\f2ec";
  visibility: hidden;
}

.fad.fa-trophy:after {
  content: "\f091";
  visibility: hidden;
}

.fad.fa-trophy-alt:after {
  content: "\f2eb 10";
}

.fad.fa-truck:after {
  content: "\f0d1 10";
}

.fad.fa-truck-container:after {
  content: "\f4dc 10";
}

.fad.fa-truck-couch:after {
  content: "\f4dd 10";
}

.fad.fa-truck-loading:after {
  content: "\f4de 10";
}

.fad.fa-truck-monster:after {
  content: "\f63b 10";
}

.fad.fa-truck-moving:after {
  content: "\f4df 10";
}

.fad.fa-truck-pickup:after {
  content: "\f63c 10";
}

.fad.fa-truck-plow:after {
  content: "\f7de 10";
}

.fad.fa-truck-ramp:after {
  content: "\f4e0 10";
}

.fad.fa-tshirt:after {
  content: "\f553";
  visibility: hidden;
}

.fad.fa-tty:after {
  content: "\f1e4 10";
}

.fad.fa-turkey:after {
  content: "\f725 10";
}

.fad.fa-turtle:after {
  content: "\f726 10";
}

.fad.fa-tv:after {
  content: "\f26c 10";
}

.fad.fa-tv-retro:after {
  content: "\f401 10";
}

.fad.fa-umbrella:after {
  content: "\f0e9 10";
}

.fad.fa-umbrella-beach:after {
  content: "\f5ca 10";
}

.fad.fa-underline:after {
  content: "\f0cd 10";
}

.fad.fa-undo:after {
  content: "\f0e2";
  visibility: hidden;
}

.fad.fa-undo-alt:after {
  content: "\f2ea";
  visibility: hidden;
}

.fad.fa-unicorn:after {
  content: "\f727 10";
}

.fad.fa-union:after {
  content: "\f6a2";
  visibility: hidden;
}

.fad.fa-universal-access:after {
  content: "\f29a 10";
}

.fad.fa-university:after {
  content: "\f19c 10";
}

.fad.fa-unlink:after {
  content: "\f127 10";
}

.fad.fa-unlock:after {
  content: "\f09c 10";
}

.fad.fa-unlock-alt:after {
  content: "\f13e 10";
}

.fad.fa-upload:after {
  content: "\f093 10";
}

.fad.fa-usd-circle:after {
  content: "\f2e8 10";
}

.fad.fa-usd-square:after {
  content: "\f2e9 10";
}

.fad.fa-user:after {
  content: "\f007";
  visibility: hidden;
}

.fad.fa-user-alt:after {
  content: "\f406";
  visibility: hidden;
}

.fad.fa-user-alt-slash:after {
  content: "\f4fa 10";
}

.fad.fa-user-astronaut:after {
  content: "\f4fb 10";
}

.fad.fa-user-chart:after {
  content: "\f6a3 10";
}

.fad.fa-user-check:after {
  content: "\f4fc 10";
}

.fad.fa-user-circle:after {
  content: "\f2bd 10";
}

.fad.fa-user-clock:after {
  content: "\f4fd 10";
}

.fad.fa-user-cog:after {
  content: "\f4fe 10";
}

.fad.fa-user-crown:after {
  content: "\f6a4 10";
}

.fad.fa-user-edit:after {
  content: "\f4ff 10";
}

.fad.fa-user-friends:after {
  content: "\f500 10";
}

.fad.fa-user-graduate:after {
  content: "\f501 10";
}

.fad.fa-user-hard-hat:after {
  content: "\f82c 10";
}

.fad.fa-user-headset:after {
  content: "\f82d 10";
}

.fad.fa-user-injured:after {
  content: "\f728 10";
}

.fad.fa-user-lock:after {
  content: "\f502 10";
}

.fad.fa-user-md:after {
  content: "\f0f0 10";
}

.fad.fa-user-md-chat:after {
  content: "\f82e 10";
}

.fad.fa-user-minus:after {
  content: "\f503 10";
}

.fad.fa-user-ninja:after {
  content: "\f504 10";
}

.fad.fa-user-nurse:after {
  content: "\f82f 10";
}

.fad.fa-user-plus:after {
  content: "\f234 10";
}

.fad.fa-user-secret:after {
  content: "\f21b 10";
}

.fad.fa-user-shield:after {
  content: "\f505 10";
}

.fad.fa-user-slash:after {
  content: "\f506 10";
}

.fad.fa-user-tag:after {
  content: "\f507 10";
}

.fad.fa-user-tie:after {
  content: "\f508 10";
}

.fad.fa-user-times:after {
  content: "\f235 10";
}

.fad.fa-users:after {
  content: "\f0c0 10";
}

.fad.fa-users-class:after {
  content: "\f63d 10";
}

.fad.fa-users-cog:after {
  content: "\f509 10";
}

.fad.fa-users-crown:after {
  content: "\f6a5 10";
}

.fad.fa-users-medical:after {
  content: "\f830 10";
}

.fad.fa-utensil-fork:after {
  content: "\f2e3";
  visibility: hidden;
}

.fad.fa-utensil-knife:after {
  content: "\f2e4";
  visibility: hidden;
}

.fad.fa-utensil-spoon:after {
  content: "\f2e5";
  visibility: hidden;
}

.fad.fa-utensils:after {
  content: "\f2e7 10";
}

.fad.fa-utensils-alt:after {
  content: "\f2e6 10";
}

.fad.fa-value-absolute:after {
  content: "\f6a6 10";
}

.fad.fa-vector-square:after {
  content: "\f5cb 10";
}

.fad.fa-venus:after {
  content: "\f221";
  visibility: hidden;
}

.fad.fa-venus-double:after {
  content: "\f226 10";
}

.fad.fa-venus-mars:after {
  content: "\f228 10";
}

.fad.fa-vial:after {
  content: "\f492 10";
}

.fad.fa-vials:after {
  content: "\f493 10";
}

.fad.fa-video:after {
  content: "\f03d 10";
}

.fad.fa-video-plus:after {
  content: "\f4e1 10";
}

.fad.fa-video-slash:after {
  content: "\f4e2 10";
}

.fad.fa-vihara:after {
  content: "\f6a7 10";
}

.fad.fa-voicemail:after {
  content: "\f897 10";
}

.fad.fa-volcano:after {
  content: "\f770 10";
}

.fad.fa-volleyball-ball:after {
  content: "\f45f";
  visibility: hidden;
}

.fad.fa-volume:after {
  content: "\f6a8 10";
}

.fad.fa-volume-down:after {
  content: "\f027 10";
}

.fad.fa-volume-mute:after {
  content: "\f6a9 10";
}

.fad.fa-volume-off:after {
  content: "\f026";
  visibility: hidden;
}

.fad.fa-volume-slash:after {
  content: "\f2e2 10";
}

.fad.fa-volume-up:after {
  content: "\f028 10";
}

.fad.fa-vote-nay:after {
  content: "\f771 10";
}

.fad.fa-vote-yea:after {
  content: "\f772 10";
}

.fad.fa-vr-cardboard:after {
  content: "\f729";
  visibility: hidden;
}

.fad.fa-walker:after {
  content: "\f831 10";
}

.fad.fa-walking:after {
  content: "\f554 10";
}

.fad.fa-wallet:after {
  content: "\f555 10";
}

.fad.fa-wand:after {
  content: "\f72a";
  visibility: hidden;
}

.fad.fa-wand-magic:after {
  content: "\f72b 10";
}

.fad.fa-warehouse:after {
  content: "\f494 10";
}

.fad.fa-warehouse-alt:after {
  content: "\f495 10";
}

.fad.fa-washer:after {
  content: "\f898 10";
}

.fad.fa-watch:after {
  content: "\f2e1 10";
}

.fad.fa-watch-fitness:after {
  content: "\f63e 10";
}

.fad.fa-water:after {
  content: "\f773 10";
}

.fad.fa-water-lower:after {
  content: "\f774 10";
}

.fad.fa-water-rise:after {
  content: "\f775 10";
}

.fad.fa-wave-sine:after {
  content: "\f899";
  visibility: hidden;
}

.fad.fa-wave-square:after {
  content: "\f83e";
  visibility: hidden;
}

.fad.fa-wave-triangle:after {
  content: "\f89a";
  visibility: hidden;
}

.fad.fa-webcam:after {
  content: "\f832 10";
}

.fad.fa-webcam-slash:after {
  content: "\f833 10";
}

.fad.fa-weight:after {
  content: "\f496 10";
}

.fad.fa-weight-hanging:after {
  content: "\f5cd 10";
}

.fad.fa-whale:after {
  content: "\f72c";
  visibility: hidden;
}

.fad.fa-wheat:after {
  content: "\f72d 10";
}

.fad.fa-wheelchair:after {
  content: "\f193 10";
}

.fad.fa-whistle:after {
  content: "\f460 10";
}

.fad.fa-wifi:after {
  content: "\f1eb 10";
}

.fad.fa-wifi-1:after {
  content: "\f6aa";
  visibility: hidden;
}

.fad.fa-wifi-2:after {
  content: "\f6ab 10";
}

.fad.fa-wifi-slash:after {
  content: "\f6ac 10";
}

.fad.fa-wind:after {
  content: "\f72e 10";
}

.fad.fa-wind-turbine:after {
  content: "\f89b 10";
}

.fad.fa-wind-warning:after {
  content: "\f776 10";
}

.fad.fa-window:after {
  content: "\f40e 10";
}

.fad.fa-window-alt:after {
  content: "\f40f 10";
}

.fad.fa-window-close:after {
  content: "\f410 10";
}

.fad.fa-window-maximize:after {
  content: "\f2d0 10";
}

.fad.fa-window-minimize:after {
  content: "\f2d1";
  visibility: hidden;
}

.fad.fa-window-restore:after {
  content: "\f2d2 10";
}

.fad.fa-windsock:after {
  content: "\f777 10";
}

.fad.fa-wine-bottle:after {
  content: "\f72f 10";
}

.fad.fa-wine-glass:after {
  content: "\f4e3";
  visibility: hidden;
}

.fad.fa-wine-glass-alt:after {
  content: "\f5ce 10";
}

.fad.fa-won-sign:after {
  content: "\f159";
  visibility: hidden;
}

.fad.fa-wreath:after {
  content: "\f7e2 10";
}

.fad.fa-wrench:after {
  content: "\f0ad";
  visibility: hidden;
}

.fad.fa-x-ray:after {
  content: "\f497 10";
}

.fad.fa-yen-sign:after {
  content: "\f157";
  visibility: hidden;
}

.fad.fa-yin-yang:after {
  content: "\f6ad 10";
}